import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Tokenomics() {
  return (
    <>
      <div className="container" style={{ color: "black" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-10">
            <h1>Tokenomics</h1>
            {/* <p className="lead"> */}
            <p>
              Capitron offers a utility token called CPRN which will be used as
              the native token of the Capitron platform. CPRN will provide users
              with various benefits, such as reduced trading fees and access to
              exclusive features. The total supply of CPRN tokens is
              60,402,479,004 and the token is based on Binance Smart Chain . The
              initial distribution of CPRN tokens will be as follows:
            </p>
            <div>
              <ul>
                <li>Founding team: 10%</li>
                <li>Investors: 25%</li>
                <li>Advisors: 5%</li>
                <li>Marketing : 5%</li>
                <li>Community: 35%</li>
                <li>Reserved for future development: 20%</li>
              </ul>
            </div>

            <div className="d-flex justify-content-end">
              <a
                className="text-black text-decoration-none"
                href="https://bscscan.com/token/0xba34debddd1dfd9adf1df65daf8364bd2926287a?a=0xE585DDA48821CC7878D6328b4036f910d8b468a3"
                target="_blank"
              >
                Capitron contract address :
                0xba34DeBDDD1DFD9AdF1Df65Daf8364BD2926287A
              </a>
            </div>
            <div className="row">
              <div className="card mt-3 mb-3 p-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <Link to="/docs/roadmap">
                        <AiOutlineArrowLeft />
                        &nbsp;Roadmap
                      </Link>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Link to="/docs/partners">
                        Next Page &nbsp;
                        <AiOutlineArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
