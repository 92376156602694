import React from "react";
import { Link } from "react-router-dom";
import pic1 from "../../assets/Read3/pic1.png";
import Logo from "../../assets/CAPITRON logoD (13) 1.png";

export default function Read3() {
  return (
    <div className="container-fluid">
      <div className="d-flex gap-3 flex-wrap align-items-center pt-5">
        <img src={Logo} style={{ width: "60px", height: "60px" }} alt="Logo" />
        <h2 className="swap-footer-logo">Capitron</h2>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-xl-10 col-md-10 col-lg-10 col-12 ">
          <div className="row  d-flex justify-content-center mt-5">
            <h2 className="text-center">
              {" "}
              How to use Cash in defiswap on Capitron ?
            </h2>
            <h5 className="mt-2">
              1. Head over to capitron.com/swap page, Click on cash and “Connect
              Wallet” on the top right corner. Select
              Ethereum/Polygon/BSC/Arbitrium/Fantom/Avalanche/Optimism network
              which you want on the drop-down menu and select the coin you want
              cash.
            </h5>
            <img src={pic1} className="img-fluid mt-2" />
            <h5>
              2. Enter the amount of coin you want to cash. Select Paypal or
              Bank Transfer and enter the details. .{" "}
            </h5>
            <h5>3. Confirm your swap.</h5>
            <h5>
              4. Voila! You have successfully swapped your coinVoila! You have
              successfully converted your coin to cash. You would receive the
              funds instantly depending on the country and payment method.In
              case of any issues contact support.
            </h5>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-xl-8 col-md-8 col-lg-8 col-12 ">
          <p>
            💬{" "}
            <i>
              Still have questions? Hit us up on{" "}
              <Link to="t.me/capitronsupport ">Telegram</Link> or{" "}
              <Link to="/https://www.instagram.com/capitronsupport/">
                Instagram
              </Link>{" "}
              &nbsp; Capitron is building a decentralized, comprehensive DeFi
              swap ecosystem complete with AMM, cross-chain capabilities. Follow
              our socials to keep up with our product launches and find out more
              on how you can make the most of your cryptocurrency assets with
              Capitrons secure and powerful DeFi swap tools!
            </i>
          </p>
        </div>
      </div>
    </div>
  );
}
