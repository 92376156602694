import React, { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ethers } from 'ethers'
import { walletAddressMetamask } from "../utils/commonConstant"
import { useWeb3Wallet } from '../utils/web3wallet.context'
import Web3 from 'web3';
import "./swap.css"
import ETHLogo from '../assets/eth-logo.png'
import SwapArrow from '../assets/Swap-arrow.png'
import axios from 'axios'
import API_URL from '../Api/apiUrl'
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { providerOptions } from '../utils/providerOptions';
export default function SwapForm({ chainName, coinBalance, componentRender }) {
   
    const { connectWallet, disconnect, account, chainId, provider } = useWeb3Wallet();
    const web3 = new Web3(provider);
    const [amount, setAmount] = useState();
    const [amount1, setAmount1] = useState();
    const [lists, setLists] = useState([])

    const [coinName, setCoinName] = useState()
    const [coinImage, setCoinImage] = useState()


    const [coinName1, setCoinName1] = useState();
    const [coinImage1, setCoinImage1] = useState();
    const [platform , setPlatform] = useState()
    const [token , setToken] = useState()
    const [tokenName , setTokenName]  = useState()
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState(coinBalance);
    const [search, setSearch] = useState();
    const [filteredTokenList, setFilteredTokenList] = useState();
    const [filteredTokenLower, setFilteredTokenLower] = useState();

    const [coin1DollarBalace, setCoin1DollarBalace] = useState(0);
    const [coin2DollarBalace, setCoin2DollarBalace] = useState(0);


    const chainShow = () => {
        if (chainName == "Ethereum") {
            setCoinName("ETH")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png")


            setCoinName1("GLDX")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/11318.png")

        } else if (chainName == "BNB") {
            setCoinName("BNB")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")

            setCoinName1("BUSD")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png")

        } else if (chainName == "Polygon") {
            setCoinName("MATIC")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png")


            setCoinName1("CROWD")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/22117.png")

        } else if (chainName == "Arbitrum") {
            setCoinName("ARB")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png")


            setCoinName1("CKING")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/23944.png")

        } else if (chainName == "Optimism") {
            setCoinName("OP")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png")


            setCoinName1("OPXVEVELO")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/22801.png")

        } else if (chainName == "Avalanche") {
            setCoinName("AVAX")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png")


            setCoinName1("YYAVAX")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/21775.png")

        } else if (chainName == "Fantom") {
            setCoinName("FTM")
            setCoinImage("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")


            setCoinName1("IB")
            setCoinImage1("https://s2.coinmarketcap.com/static/img/coins/64x64/17568.png")

        }
    }

    const tokenNumber = () => {
        axios.get(`${API_URL.getTokenCount}?chainName=${chainName}`)
            .then((res) => {
             

                if (res.data.data) {
                    allTokens(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const allTokens = () => {
        axios.get(`${API_URL.getTokenList}?chainName=${chainName}&no_of_docs_each_page=${100}&current_page_number=${0}`)
            .then((res) => {
           
                setLists(res.data.data)
            })
            .catch((res) => {
                console.log(res)
            })
    }

  
    const getAbi = async () => {
        let res = await axios.get(`${API_URL.getTokenABI}?symbol=${String(coinName).toUpperCase()}&tokenName=${tokenName}&token_address=${token}&platform=${platform}`)

     
        return res.data[0]
    }

    const exchangeRate = () => {



        axios.get(`${API_URL.exchange}?amount=${amount}&convert=${coinName1}&symbol=${coinName}`)
            .then((res) => {
           
                setAmount1(res.data.data?.convert?.amount)
                setCoin1DollarBalace(res.data.data?.symbol?.amountInDollar);
                setCoin2DollarBalace(res.data.data?.convert?.amountInDollar);
            })
            .catch((err) => {
                console.log(err)
                setAmount1(0)
                setCoin1DollarBalace(0);
                setCoin2DollarBalace(0);
                
            })
    }

    const getBalance = async () => {

        if (coinName == "BNB" || coinName == "ETH" || coinName == "AVAX" || coinName == "MATIC" || coinName == "FTM") {
           
           var account1 = web3.utils.toChecksumAddress(account)
            var accountsBalance = await web3.eth.getBalance(account1)
            setBalance((accountsBalance / 1000000000000000000).toFixed(4))

        } else if (account) {
            try {
                const provider1 = new ethers.providers.Web3Provider(provider);

                const signer = provider1.getSigner();

                let res = await getAbi();
            

                let Contract = new ethers.Contract(res?.contractAddress, JSON.parse(res?.abi), signer);
                let transaction = await Contract.balanceOf(account);
             
                setBalance(parseInt(transaction._hex) / 1000000000000000000)
               
            } catch (error) {
                console.log(error)
                setBalance(0)
            }
        }
    }


    useEffect(() => {
        if(account){
        getBalance()
    }
    }, [coinName, chainName, account, disconnect, coinBalance])

    //   Switching network 
    const checkingChainId = async () => {
        setBalance(0);
        if (account) {
            if (chainName == "Ethereum") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0x1' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            } else if (chainName == "BNB") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0x38' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            } else if (chainName == "Optimism") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0xA' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            } else if (chainName == "Polygon") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0x89' }],

                    });
                } catch (err) {
                    console.log(err);
                }

            } else if (chainName == "Avalanche") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0xA86A' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            } else if (chainName == "Arbitrum") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0xA4B1' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            }
            else if (chainName == "Fantom") {
                try {

                    await await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: '0xFA' }],
                    });
                } catch (err) {
                    console.log(err);
                }

            }
            else {
                alert(`Please switch to ${chainName} network`)
            }
        }
    }

    useEffect(() => {
        checkingChainId()
    }, [chainName, chainId, account])


    useEffect(() => {
        // getAbi()
        // exchangeRate()
    }, [coinName])

    useEffect(() => {
        tokenNumber()
    }, [chainName])


    useEffect(() => {
        chainShow()
    }, [chainName])

    useEffect(() => {
        if (coinName && coinName1) {
            exchangeRate()
        }
    }, [coinName, coinName1, chainName])


    const transferToken = async () => {

        setLoading(true)

       

        if (account && coinName == "BNB" || coinName == "ETH" || coinName == "AVAX" || coinName == "MATIC" || coinName == "FTM") {
            try {
                const listingPriceETH = ethers.utils.parseUnits(
                    amount.toString(),
                    "ether"
                );
                const transactionParameters = {
                    to: walletAddressMetamask, // Required except during contract publications.
                    from: account, // must match user's active address.
                    value: listingPriceETH._hex, // Only required to send ether to the recipient from the initiating external account.
                };
                const txHash = await provider.request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],

                });
               
                setLoading(false)
                NotificationManager.success("Transaction Successfull !");
            }

            catch (error) {
                console.log("error:", error);
                NotificationManager.error(error.message);
                setLoading(false)
            }




        } else {

            try {
                const provider1 = new ethers.providers.Web3Provider(provider);
                const signer = provider1.getSigner();

                let res = await getAbi();
           

                let Contract = new ethers.Contract(res?.contractAddress, JSON.parse(res?.abi), signer);
                const amount1 = ethers.utils.parseEther(amount)
                let transaction = await Contract.transfer(walletAddressMetamask, amount1, {
                    gasLimit: 100000,

                });
                await transaction.wait()
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
    }

    const handleChange = (e) => {
        setSearch(e.target.value)



        axios.get(`${API_URL.searchTokenByChain}?chainName=${chainName}&token=${e.target.value}`)
            .then((res) => {
             
                setFilteredTokenList(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        setFilteredTokenList(lists)
    }, [lists])
    const handleChangeLower = (e) => {
        setSearch(e.target.value)



        axios.get(`${API_URL.searchTokenByChain}?chainName=${chainName}&token=${e.target.value}`)
            .then((res) => {
               
                setFilteredTokenLower(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        setFilteredTokenLower(lists)
    }, [lists])

    useEffect(() => {
        exchangeRate()
    }, [amount])

    return (
        <>
            <div className="swap-tab-container p-3 d-flex flex-column">

                <div className="input-container p-4 d-flex flex-column gap-3 active">

                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                        <input
                            value={amount}
                            className="input-field w-50" type="text"
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="00" />

                        <div className="dropdown d-flex align-items-center">
                            <img src={coinImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>
                                {coinName}
                            </span>
                            <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop">&#xf107;</i>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${coin1DollarBalace} </p>
                        <p className="input-balance p-0 m-0">Balance: {balance ? balance : 0}</p>
                    </div>
                </div>

                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                </div>

                <div className="input-container p-4 d-flex flex-column gap-3">

                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                        <input onChange={() => {
                            setAmount1(amount1);
                        }} value={amount1} disabled={true} className="input-field w-50" type="text" placeholder="00" />

                        <div className="dropdown d-flex align-items-center">
                            <div className="dropdown d-flex align-items-center w-25">
                                <img src={coinImage1} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{coinName1}</span>
                                <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">&#xf107;</i>

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${coin2DollarBalace} </p>
                        <p className="input-balance p-0 m-0">Balance: 0</p>
                    </div>
                </div>

                <p className='token-value ps-4 py-2 mt-4'>
                    {amount}{coinName} = {amount1}{coinName1}
                    <span>
                        {/* (~$1 418.77 ) */}
                    </span>
                </p>

                {/* <div className='d-flex justify-content-between align-items-center swap-button-container m-auto'> */}
                <div className='w-100 swap-button-container m-auto'>
                    {account ?



                        <>
                            <button className='px-5 py-1 w-100' data-bs-toggle="modal" data-bs-target={(+amount <= +balance) ? "#exampleModal" : ""}
                            >
                                {(+amount > +balance) ? "Amount Exceed" :
                                    <>
                                        {
                                            loading ? <i
                                                className="fa fa-circle-o-notch fa-spin"
                                                style={{ fontSize: 16 }}
                                            /> : " SWAP"}
                                    </>
                                }

                            </button>
                        </>



                        :

                        <button className='px-5 py-1 w-100' onClick={connectWallet}>CONNECT WALLET</button>
                    }
                </div>
            </div>
            {/* <!-- Modal currency 1 --> */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" className="mb-5 " placeholder='Search Token' onChange={handleChange} />
                            </div>
                            {filteredTokenList?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                      
                                        setCoinName(lists.symbol);
                                        setCoinImage(lists.imageURL);
                                        setPlatform(lists.platform)
                                        setToken(lists.token_address)
                                        setTokenName(lists.name)
                                    }} className='d-flex mt-4' data-bs-dismiss="modal">
                                        <div >
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "40px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal currency 2 */}
            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" className="mb-5 " placeholder='Search Token' onChange={handleChangeLower} />
                            </div>
                            {filteredTokenLower?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                      
                                        setCoinName1(lists.symbol);
                                        setCoinImage1(lists.imageURL);
                                      
                                    }} className='d-flex mt-4' data-bs-dismiss="modal" >
                                        <div>
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "30px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>



            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Swap</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="swap-tab-container p-3 d-flex flex-column">

                                <div className="input-container p-4 d-flex flex-column gap-3 active">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {amount}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={coinImage} style={{ height: "20px", borderRadius: "50%" }} alt="" />
                                            {coinName}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                                </div>

                                <div className="input-container p-4 d-flex flex-column gap-3">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {amount1}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={coinImage1} style={{ height: "20px", borderRadius: "50%" }} alt="" />
                                            {coinName1}
                                        </div>
                                    </div>
                                </div>

                                <p className='token-value ps-4 py-2 mt-4'>
                                    {amount}{coinName} = {amount1}{coinName1}
                                    <span>
                                        {/* (~$1 418.77 ) */}
                                    </span>
                                </p>

                                <div className='d-flex justify-content-between align-items-center swap-button-container m-auto'>


                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-primary confirm-swap w-100" data-bs-dismiss="modal" onClick={transferToken}>CONFIRM</button>

                        </div>
                        {/* data-bs-dismiss="modal" */}
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    )
}
