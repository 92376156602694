import React, { useEffect, useState } from "react";
import Blog from "./Component/sidebar/Blog";
import { NotificationContainer, NotificationManager } from 'react-notifications';
// import Read from "./Component/sidebar/Read";
import Sidebar from "./Component/sidebar/Sidebar";
import TronNavbar1 from "./common_Qr/TronNavbar/TronNavbar";
import TronNavbar from "./Common_swap/TronNavbar/TronNavbar";
import TronFooter from "./Common_swap/TronFooter/TronFooter";
import axios from "axios";
import API_URL from "./Api/apiUrl";
import Section3 from "./Component/Section3/Section3";
import Navbar from "./Component/Navbar/Navbar";
import Product from "./Component/capitron Product/Product";
import Footer from "./Component/Footer/footer";
import Section2 from "./Component/section2/section2";
import { useRoutes } from "react-router-dom";
import SwapNavbar from "./common/SwapNavbar/SwapNavbar";
import { Web3WalletProvider } from "./utils/web3wallet.context";
import "./App.css";
import SwapFooter from "./common/SwapFooter/SwapFooter";
import Swap from "./swap/Swap";
import Tron from "./Tron Integration/Tron";
import Capfusion from "./Component/capitron fusion/capfusion";
import Liquidity from "./Component/Liquidity/Liquidity";
import QrFile from "./QR_Integration/QrFile";
import ContactForm from "./Component/Contact Form/contactForm";
import "react-notifications/lib/notifications.css";
import Routing from "./Component/sidebar/Routing";
import Content from "./Component/sidebar/Content";
import AboutUs from "./Component/sidebar/AboutUs";
import CapitronProduct from "./Component/sidebar/CapitronProduct";
import Terminologies from "./Component/sidebar/Terminologies";
import Roadmap from "./Component/sidebar/Roadmap";
import Tokenomics from "./Component/sidebar/Tokenomics";
import ProgressBar from "./hooks/use-nprogress";
import Read1 from "./Component/sidebar/Read1";
import Partners from "./Component/sidebar/Partners";
import Read2 from "./Component/sidebar/Read2";
import Read3 from "./Component/sidebar/Read3";
import Read4 from "./Component/sidebar/Read4";
import Read5 from "./Component/sidebar/Read5";
import Read6 from "./Component/sidebar/Read6";
import Read7 from "./Component/sidebar/Read7";
import Read8 from "./Component/sidebar/Read8";
import LiquidityExchange from "./Component/sidebar/LiquidityExchange";
import Section4 from "./Component/Hero/Section4/Section4";
import PartnersStackholder from "./Component/Hero/PartnerStackHolder/Partners";

export default function App() {
  const [chainLists, setchainLists] = useState();
  const [chainName, setChainName] = useState("BNB");
  const [chainImage, setChainImage] = useState(
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
  );
  const [nativeNet, setNativeNet] = useState();

  // Tron wallet connection
  const selectNetwork = (network) => {
    setNativeNet(network);
  
  };

  const disconnectNetwork = () => {
    setNativeNet();
  };

  const handleChainName = (chName, chImage) => {
    setChainName(chName);
    setChainImage(chImage);
  };
  const allchainList = () => {
    axios
      .get(API_URL.getchainList)
      .then((res) => {
       
        setchainLists(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(err.message);
      });
  };

  useEffect(() => {
    allchainList();
  }, []);


 

  const routes = useRoutes([
    {
      path: "/",
      element: (
        <div>
          <Navbar />
          <Section2 />
          <Section3 />
          {/* <Section4/> */}
          <Liquidity />
          <Capfusion />
          <Product />
          {/* <PartnersStackholder/> */}
          <Footer />
        </div>
      ),
    },
    {
      path: "swap",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <SwapNavbar
            chainLists={chainLists}
            handleChainName={handleChainName}
            chainName={chainName}
            chainImage={chainImage}
          />
          <Swap chainLists={chainLists} chainName={chainName} />
          <SwapFooter />
        </div>
      ),
      children: [
        {
          path: "",
          element: <div>Games Index</div>,
        },
        {
          path: ":id",
          element: <div>Game Details</div>,
        },
      ],
    },
    {
      path: "/blog",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Blog />
        </div>
      ),
      children: [
        {
          path: ":id",
          // element: <Read/>
        },
      ],
    },

    {
      path: "/read",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read1 />
        </div>
      ),
     
    },
    {
      path: "/read1",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read1 />
        </div>
      ),
    },
    {
      path: "/read2",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read2 />
        </div>
      ),
    },
    {
      path: "/read3",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read3 />
        </div>
      ),
    },
    {
      path: "/read4",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read4 />
        </div>
      ),
    },
    {
      path: "/read5",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read5 />
        </div>
      ),
    },
    {
      path: "/read6",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read6 />
        </div>
      ),
    },
    {
      path: "/read7",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read7 />
        </div>
      ),
    },
    {
      path: "/read8",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <Read8 />
        </div>
      ),
    },
    {
      path: "docs",
      element: (
        <div className="swap-background">
          <Routing />
        </div>
      ),
      children: [
        {
          path: "",
          element: <Content />,
        },
        {
          path: "content",
          element: <Content />,
        },
        {
          path: "aboutus",
          element: <AboutUs />,
        },
        {
          path: "capitronProducts",
          element: <CapitronProduct />,
        },
        {
          path: "terminologies",
          element: <Terminologies />,
        },
        {
          path: "roadmap",
          element: <Roadmap />,
        },
        {
          path: "tokenomics",
          element: <Tokenomics />,
        },
        {
          path: "partners",
          element: <Partners />,
        },
        {
          path: "liquidityexchange",
          element: <LiquidityExchange />,
        },
      ],
    },
    {
      path: "nativeswap",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <TronNavbar
            nativeNet={nativeNet}
            disconnectNetwork={disconnectNetwork}
          />
          <Tron Networks={selectNetwork} />
          <TronFooter />
        </div>
      ),
      children: [
        {
          path: "",
          element: <div>Games Index</div>,
        },
        {
          path: ":id",
          element: <div>Game Details</div>,
        },
      ],
    },
    {
      path: "hybridswap",
      element: (
        <div className="swap-background px-1 px-md-5 px-lg-5">
          <TronNavbar1 />
          <QrFile />
          <TronFooter />
        </div>
      ),
      children: [
        {
          path: "",
          element: <div>Games Index</div>,
        },
        {
          path: ":id",
          element: <div>Game Details</div>,
        },
      ],
    },
  ]);
  return (
    <>
      <Web3WalletProvider>
        <ProgressBar />
        {routes}
      </Web3WalletProvider>
      <NotificationContainer />
    </>
  );
}
