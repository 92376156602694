import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function Roadmap() {
  return (
    <>
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-10">
            <h1>Roadmap</h1>
            <p>
              Capitron's vision is to become a leading decentralized swap
              exchange platform, providing traders with a seamless and secure
              trading experience. In the future, Capitron plans to add support
              for new blockchain networks and integrate with other DeFi
              protocols to expand its capabilities. The platform will also
              continue to improve its performance and security features,
              ensuring that users can trade with confidence.
            </p>
            <div className="phase1">
              <h5>Phase 1: Development and Launch </h5>
              <ul>
                <li>Research and gather requirements for Capitron</li>
                <li>
                  Design and develop the Capitron platform, including the UI/UX,
                  backend infrastructure, and security features
                </li>
                <li>
                  Develop and launch the Defi Swap, Native Swap and Hybrid Swap.
                </li>
                <li>
                  Develop and launch the Cash platform with support for
                  converting crypto to cash
                </li>
                <li>Perform extensive testing and security audits</li>
                <li>Launch the Capitron platform to the public</li>
                <li>
                  Develop and launch Capitron's own native token for governance
                  and utility purposes
                </li>
              </ul>
            </div>
            <div className="phase2">
              <h5>Phase 2: Expansion and Growth</h5>
              <ul>
                <li>Expand the range of tokens supported by Capitron</li>
                <li>
                  Improve the website efficiency. CPRN token sale and coin
                  listing on exchanges.
                </li>
                <li>
                  Expand the supported native cryptocurrencies to include
                  popular altcoins and stablecoins
                </li>
                <li>
                  Implement new features and tools for advanced trading
                  strategies
                </li>
                <li>
                  Launch a referral program to incentivize user growth and
                  adoption
                </li>
                <li>
                  Implement additional security measures, such as insurance for
                  user funds.
                </li>
                <li>Develop and launch mobile apps for Capitron</li>
              </ul>
              <div className="phase3">
                <h5>Phase 3: Scaling and Optimization </h5>
                <ul>
                  <li>
                    Implement scaling solutions to increase the capacity and
                    performance of the platform
                  </li>
                  <li>
                    Improve user experience through interface and performance
                    optimizations
                  </li>
                  <li>
                    Expand partnerships with other DeFi protocols, exchanges,
                    and payment providers
                  </li>
                  <li>
                    Develop and launch Capitron's own liquidity mining program
                  </li>
                  <li>
                    Explore opportunities for integrating with other blockchain
                    networks and ecosystems
                  </li>
                  <li>
                    Explore the integration of non-fungible tokens (NFTs) into
                    the Capitron ecosystem.
                  </li>
                  <li>
                    Continue to research and implement innovative technologies
                    to improve the user experience and security.
                  </li>
                </ul>
              </div>
              <div className="phase4">
                <h5>Phase 4: Global Expansion </h5>
                <ul>
                  <li>
                    Expand the platform to support additional languages and
                    regions
                  </li>
                  <li>
                    Develop and launch localized marketing campaigns and
                    outreach efforts
                  </li>
                  <li>
                    Expand Capitron's community through social media, forums,
                    and events
                  </li>
                  <li>
                    Pursue partnerships with major financial institutions and
                    corporations
                  </li>
                </ul>
                <p>
                  This is just one possible roadmap and can be adjusted based on
                  the specific goals and priorities of the Capitron team.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="card mt-3 mb-3 p-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <Link to="/docs/terminologies">
                        <AiOutlineArrowLeft />
                        &nbsp;Terminologies
                      </Link>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Link to="/docs/tokenomics">
                        Next Page &nbsp;
                        <AiOutlineArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
