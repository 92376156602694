import React from "react";

import Logo from "../../assets/CAPITRON logoD (13) 1.png";
import "./TronFooter.css";

export default function Tronfooter() {
  return (
    <div className="mt-5 pt-3 pb-2 swap-footer-container">
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="col-md-4">
          <p className="swap-footer-text">
            By using this website you agree to the token terms, privacy policy
            and website terms, and swap license agreement.
          </p>
        </div>

        <div className="d-flex gap-3 flex-wrap align-items-center">
          <img
            className="foot-logo"
            // style={{ width: "80px", height: "80px" }}
            src={Logo}
            alt="Logo"
          />
          <h2 className="swap-footer-logo">Capitron</h2>
        </div>
      </div>
    </div>
  );
}
