import React, { useState, useRef, useEffect } from 'react'
import Trx from "../assets/trx.png"
import DogeCoin from "../assets/coinlogo/dogecoin.png"
import BitCoin from "../assets/coinlogo/bitcoin (1).png"
import { useWeb3Wallet } from '../utils/web3wallet.context'
import {  walletAddressTron , walletAddressDoge  , walletAddressBtc }  from "../utils/commonConstant"
import { ethers } from 'ethers'
import API_URL from '../Api/apiUrl'
import axios from 'axios'
import "./Tron.css"
import SwapArrow from '../assets/Swap-arrow.png'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function CashTron({ Networks }) {



    const { connectTron, disConnectTron, tronAccount, xdefiDoge, xdefiBtc, btcWallets, dogeWallets } = useWeb3Wallet();
    const [emailValid, setEmailValid] = useState()
    const [emailValidEuro, setEmailValidEuro] = useState()
    const [country, setCountry] = useState("usa")
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("")
    const [bankName, setBankName] = useState("");
    const [postalCode, setPostalCode] = useState("")
    const [accountHolderName, setAccountHolderName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [zipcode, setZipCode] = useState()
    const [iban, setIban] = useState()
    const [paypalEmail, setPyapalEmail] = useState("")
    const [paypalNumber, setPaypalNumber] = useState("")
    const [amountInDollar , setAmountInDollar] = useState("")
    const [coin1DollarBalace, setCoin1DollarBalace] = useState()
    const [swiftCode, setSwiftCode] = useState()
    const [curramount, setCurramount] = useState();

    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState([]);

    const [nativeImage, setNativeImage] = useState(Trx)
    const [nativeNetwork, setNativeNetwork] = useState("TRON")
    const [nativeCurrency, setNativeCurrency] = useState()
    const [nativeCurrencyImage, setNativeCurrencyImage] = useState()

    const modalOpen = useRef()


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const chainUpper = () => {
        if (nativeNetwork == "TRON") {
            setNativeCurrency("TRX")
            setNativeCurrencyImage(Trx)
        } else if (nativeNetwork == "Bitcoin") {
            setNativeCurrency("BTC")
            setNativeCurrencyImage(BitCoin)
        } else if (nativeNetwork == "Dogecoin") {
            setNativeCurrency("DOGE")
            setNativeCurrencyImage(DogeCoin)
        }
    }

    useEffect(() => {
        chainUpper()
    }, [nativeNetwork])


    const exchangeRate = () => {
         
        let coin = "LINK"

        axios.get(`${API_URL.exchange}?amount=${curramount}&convert=${coin}&symbol=${nativeCurrency}`)
            .then((res) => {
             
              
                setCoin1DollarBalace(res.data.data?.symbol?.amountInDollar);
                setAmountInDollar(res.data.data?.symbol?.amountInDollar)
               
            })
            .catch((err) => {
                // console.log(err)
                setCoin1DollarBalace(0);
                setAmountInDollar(0)
            })
    }

    useEffect(() => {
        exchangeRate()
    }, [nativeCurrency , curramount])



    const bankTransfer = (e) => {
        e.preventDefault()


        if (country == "usa" || country == "others") {
            axios.post(API_URL.saveTransaction, {
                "type": country,
                "accountNumber": Number(accountNumber),
                "swiftCode" : swiftCode,
                 "country" : country,
                "routingNumber": Number(routingNumber),
                "bankName": bankName,
                "postalCode": postalCode,
                "accountHolderName": accountHolderName,
                "email": email,
                "contactNumber": Number(contactNumber)
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then(async (res) => {

                 
                    handleClose1()
                    handleShow()


                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            axios.post(API_URL.saveTransaction, {
                "type": country,
                "IBAN": Number(iban),
                "swiftCode" : swiftCode,
                "country" : country,
                "accountHolderName": accountHolderName,
                "ZIPCode": Number(zipcode),
                "email": email,
                "contactNumber": Number(contactNumber)
            })
                .then((res) => {
                  
                    handleClose1()
                    handleShow()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const paypalTransfer = () => {

        axios.post(API_URL.savePaypalTransaction, {
            "email": paypalEmail,
            "contactNumber": paypalNumber
        })
            .then((res) => {
            
                handleClose2()
                handleShow()
            })
            .catch((err) => {
                console.log(err)
                handleClose2()
                NotificationManager.error(err.message)
            })


    }

    
    const transferToken = async (e) => {
        e.preventDefault()
        handleClose()
        if (nativeCurrency == "TRX") {
            try {
                if (window.tronWeb && window.tronWeb.defaultAddress.base58) {

                    let tronWeb = window.tronWeb;
                    const isAddressValid = tronWeb.isAddress(
                        walletAddressTron
                    );
                    if (!isAddressValid) {
                        console.error("Invalid recipient address");
                        return;
                    }
                    const value = curramount * 1000000
                    const tradeobj = await tronWeb.transactionBuilder.sendTrx(
                        walletAddressTron,
                        value
                    );
                    const signedtxn = await tronWeb.trx.sign(tradeobj);
                    let receipt = await tronWeb.trx.sendRawTransaction(signedtxn);
                 

                    NotificationManager.success("Transaction Successfull !");
                }
            } catch (error) {
                console.log(error)
                if(error = "class org.tron.core.exception.ContractValidateException : Validate TransferContract error, no OwnerAccount."){
                    NotificationManager.error("Not Enough Balance");
                }else {
                    NotificationManager.error(error);
                }
               
            }
        } else if (nativeCurrency == "BTC") {
            const feeRate = 1; // replace with desired fee rate
            const from = btcWallets ; // replace with actual address
            const recipient = walletAddressBtc; // replace with actual address
        
            const amount = {
                amount: curramount*100000000, /* 1 BTC = 100,000,000 Satoshis*/
                decimals: 8
            }; // amount to transfer in BTC
            const memo = "Example Bitcoin transfer"; // replace with actual memo

            window.xfi.bitcoin.request(
                {
                    method: "transfer",
                    params: [
                        {
                            feeRate,
                            from,
                            recipient,
                            amount,
                            memo
                        }
                    ]
                },
                (error, result) => {
                    console.log(error, result);
                    if(error = {} ){
                    NotificationManager.error("Not Enough Balance");
                }else{
                    NotificationManager.error(error);
                }
                }
            );
        } else if (nativeCurrency == "DOGE") {
            const { asset, from, recipient, amount, memo } = {
                asset: {
                    chain: "DOGE",
                    symbol: "DOGE",
                    ticker: "DOGE"
                },
                from: dogeWallets, // replace with actual address
                recipient: walletAddressDoge,// replace with actual address
                // equivalent to 5.0 DOGE   1 DOGE = 100000000

                amount: {
                    amount: curramount*100000000,
                    decimals: 8
                },
                memo: "Transfering Coins .." // optional
            };
            window.xfi.dogecoin.request(
                {
                    method: "transfer",
                    params: [
                        {
                            asset,
                            from,
                            recipient,
                            amount,
                            memo
                        }
                    ]
                },
                (error, result) => {
                    console.log(error, result);
                    if(error = {} ){
                        NotificationManager.error("Not Enough Balance");
                    }else{
                        NotificationManager.error(error);
                    }
                 
                }
            );
        }


    }


    return (
        <>
            <div className="swap-tab-container p-3 d-flex flex-column">

                <div className="input-container p-4 d-flex flex-column gap-3 active">

                    <div className="d-flex justify-content-between flex-wrap">

                        <div className='d-flex flex-column w-75'>
                            <div className="dropdown d-flex align-items-center">
                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={nativeImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {nativeNetwork}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%" ,cursor: "pointer"}}>
                                    <p onClick={() => { setNativeImage(Trx); setNativeNetwork("TRON") ; Networks("TRON") ; connectTron()}}><img src={Trx} style={{ height: "25px", borderRadius: "50%" }} />TRON</p>
                                        <p onClick={() => { setNativeImage(BitCoin); setNativeNetwork("Bitcoin") ; Networks("Bitcoin") ; xdefiBtc() }}><img src={BitCoin} style={{ height: "25px", borderRadius: "50%" }} />Bitcoin</p>
                                        <p onClick={() => { setNativeImage(DogeCoin); setNativeNetwork("Dogecoin") ; Networks("Dogecoin") ; xdefiDoge()}}><img src={DogeCoin} style={{ height: "25px", borderRadius: "50%" }} />Dogecoin</p>
                                    </ul>
                                </div>
                            </div>

                            <input className="input-field mt-2" type="text" placeholder="1" onChange={(e) => setCurramount(e.target.value)} />
                        </div>

                        <div className="dropdown d-flex align-items-center w-25">
                            <img src={nativeCurrencyImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{nativeCurrency}</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${amountInDollar}</p>
                        {/* <p className="input-balance p-0 m-0">Balance : {balance ? balance : "0"}</p> */}
                    </div>
                </div>

                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                </div>

                <div className="input-container p-4 d-flex">
                    <div className='w-100 d-flex flex-wrap justify-content-between px-2 px-md-4 px-lg-5'>
                        <button className="cash-button px-4 py-2" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={handleShow2}>PayPal</button>
                        <button onClick={handleShow1} className="cash-button px-4 py-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">Bank Transfer</button>
                        <NotificationContainer />
                    </div>
                </div>

                {/* <p className='token-value ps-4 py-2 mt-4'>
                    1 ETH = 1527.768099 DAI
                    <span>
                        (~$1 418.77 )
                    </span>
                </p> */}
                <div className='w-100 swap-button-container m-auto mt-3'>
                    {tronAccount || dogeWallets || btcWallets ?

                        ((+curramount > +balance) ?
                            <>
                                <button className='px-5 py-1 w-100' data-bs-toggle="modal" data-bs-target="#exampleModal_xswap" >
                                    {loading ? <i
                                        className="fa fa-circle-o-notch fa-spin"
                                        style={{ fontSize: 16 }}
                                    /> : " SWAP"}

                                </button>
                            </> :
                            <>
                                <button className='px-5 py-1 w-100'>
                                  Enter Amount

                                </button>
                            </>
                        )

                        :

                        (nativeNetwork == "TRON") ?
                            <button className='px-5 py-1 w-100' onClick={connectTron}>CONNECT WALLET</button> :

                            (nativeNetwork == "Bitcoin") ?
                                <button className='px-5 py-1 w-100' onClick={xdefiBtc}>CONNECT WALLET</button> :
                                <button className='px-5 py-1 w-100' onClick={xdefiDoge}>CONNECT WALLET</button>


                    }
                </div>
                {/* <div className='d-flex justify-content-betwenn align-items-center swap-button-container m-auto'>
                    <button className='px-5 py-1'>
                        Pay
                    </button>
                </div> */}
            </div>
            {/* <!-- Button trigger modal --> */}
            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">
  Launch demo modal
</button> */}

            {/* <!-- Modal PAYPAL--> */}

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">PayPal</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">

                        <form>
                            <div className='row'>
                                <div className='col-4'><p className='paypal-text'>Email Address &nbsp; :</p></div>
                                <div className='col-6'>
                                    <input type="text" className='input-paypal' onChange={(e) => {

                                        var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                        setEmailValid(numberRegex.test(e.target.value))
                                      
                                        if (numberRegex.test(e.target.value)) {
                                            setPyapalEmail(e.target.value)
                                        }



                                    }} />
                                    <p>{(emailValid == false) ? "Enter Valid Mail Address" : ""}</p>
                                </div>

                            </div>
                            <div className='row mt-2'>
                                <div className='col-4'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                <div className='col-6'>
                                    <input type="text" value={paypalNumber} className='input-paypal' onChange={(e) => {

                                        var numberRegex = /^[0-9]*$/


                                        if (numberRegex.test(e.target.value)) {
                                            setPaypalNumber(e.target.value)
                                        }


                                    }} />
                                </div>

                            </div>
                        </form>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                    <Button className="btn btn-secondary confirm-swap w-100" variant="primary" onClick={paypalTransfer}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>



            {/* form Model */}

            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Bank Tranfer</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                                <div className='col-5'>
                                    <label htmlFor="cars"  className='paypal-text'>Choose a country:</label>
                                </div>
                                <div className='col-5'>
                                    <select name="cars" id="cars"  className='input-paypal' onClick={(e) => setCountry(e.target.value)}>
                                        <option value="usa">USA</option>
                                        <option value="Europe">Europe</option>
                                        <option value="others">Others</option>

                                    </select>
                                </div>
                            </div>
                        </form>
                        {
                            (country == "usa" || country == "others") ?
                                <>
                                    <form>
                                        <div className='row mt-3'>
                                            <div className='col-5'><p className='paypal-text'>Account Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input value={accountNumber} type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setAccountNumber(e.target.value)
                                                    }

                                                }} />
                                            </div>

                                        </div>
                                     
                                            <div className='row mt-3'>
                                                <div className='col-5'><p className='paypal-text'>Swift Code &nbsp; :</p></div>
                                                <div className='col-5'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                        // var numberRegex = /^[0-9]*$/


                                                        // if (numberRegex.test(e.target.value)) {
                                                            setSwiftCode(e.target.value)
                                                        // }

                                                    }} />
                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-5'><p className='paypal-text'>Country &nbsp; :</p></div>
                                                <div className='col-5'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                        // var numberRegex = /^[0-9]*$/


                                                        // if (numberRegex.test(e.target.value)) {
                                                            setCountry(e.target.value)
                                                        // }

                                                    }} />
                                                </div>

                                            </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Routing Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" value={routingNumber} className='input-paypal' onChange={(e) =>




                                                    setRoutingNumber(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Bank Name &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) =>

                                                    setBankName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Postal Code &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) =>




                                                    setPostalCode(e.target.value)

                                                } />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Holder Name &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => setAccountHolderName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Email &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => {
                                                    var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                                    setEmailValid(numberRegex.test(e.target.value))
                                                  
                                                    if (numberRegex.test(e.target.value)) {
                                                        setEmail(e.target.value)
                                                    }


                                                }} />

                                                <p>{(emailValid == false) ? "Enter Valid Mail Address" : ""}</p>
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" value={contactNumber} className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setContactNumber(e.target.value)
                                                    }
                                                }} />


                                            </div>

                                        </div>
                                    </form>
                                </> :
                                <>
                                    <form>
                                        <div className='row mt-3'>
                                            <div className='col-4'><p className='paypal-text'>IBAN &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" value={iban} className='input-paypal' onChange={(e) => {

                                                    var numberRegex = / \b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1, 2})?\b/
                                                  

                                                    if (numberRegex.test(e.target.value)) {
                                                        setIban(e.target.value)
                                                    }
                                                }} />
                                            </div>

                                        </div>
                                        <div className='row mt-3'>
                                                <div className='col-4'><p className='paypal-text'>Swift Code &nbsp; :</p></div>
                                                <div className='col-6'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                        // var numberRegex = /^[0-9]*$/


                                                        // if (numberRegex.test(e.target.value)) {
                                                            setSwiftCode(e.target.value)
                                                        // }

                                                    }} />
                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-4'><p className='paypal-text'>Country &nbsp; :</p></div>
                                                <div className='col-6'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                        // var numberRegex = /^[0-9]*$/


                                                        // if (numberRegex.test(e.target.value)) {
                                                            setCountry(e.target.value)
                                                        // }

                                                    }} />
                                                </div>

                                            </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Holder Name &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => setAccountHolderName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Email &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                                    setEmailValidEuro(numberRegex.test(e.target.value))

                                                    if (numberRegex.test(e.target.value)) {
                                                        setEmail(e.target.value)
                                                    }


                                                }} />

                                                <p>{(emailValidEuro == false) ? "Enter Valid Mail Address" : ""}</p>
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Postal Code &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) =>





                                                    setZipCode(e.target.value)




                                                } />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" value={contactNumber} className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setContactNumber(e.target.value)
                                                    }

                                                }} />
                                            </div>

                                        </div>

                                    </form>
                                </>
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>

                    <Button className="btn btn-secondary  confirm-swap w-100" variant="primary" onClick={bankTransfer}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Swap</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="swap-tab-container p-3 d-flex flex-column">

                        <div className="input-container p-4 d-flex flex-column gap-3 active">

                            <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                {curramount}
                                <div className="dropdown d-flex align-items-center w-25">
                                    <img src={nativeCurrencyImage} style={{ height: "20px", borderRadius: "50%" }} />
                                    {nativeCurrency}
                                </div>
                            </div>
                        </div>



                        <div className='d-flex justify-content-between align-items-center swap-button-container m-auto'>


                        </div>
                        <p className='token-value ps-4 py-2 mt-4'>
                                <p> {curramount} &nbsp; {nativeCurrency} = {coin1DollarBalace} &nbsp; USD</p>
                            </p>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-primary confirm-swap w-100" variant="primary" data-bs-dismiss="modal" onClick={transferToken}>
                        CONFIRM
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* currency Modal  */}

          
        </>
    )
}
