import React, { useState, useEffect, useRef } from 'react'
import { useWeb3Wallet } from '../utils/web3wallet.context'
import Web3 from 'web3';
import "./swap.css"
import SwapForm from './Swap_Form'
import XSwapForm from "./XSwap_Form"
import CashForm from "./Cash_Form"
import { useLocation, useParams } from 'react-router-dom';
export default function Swap({ chainLists, chainName }) {
    const { account , chainId , disconnect , provider } = useWeb3Wallet();
   
   

    const swapref = useRef()
    const xswapref = useRef()
    const cashref = useRef()

    const location = useLocation()
  

useEffect(()=>{
    if(location.state?.tab == 'swap'){
        swapref.current.click()
    } else if(location.state?.tab == 'xswap'){
        xswapref.current.click()
    } else if(location.state?.tab == 'cash'){
        cashref.current.click()
    }
}, [])

    const [componentRender, setComponentRender] = useState(0);
    const [coinBalance, setCoinBalance] = useState()
    const metamaskBalance = async () => {
        if (account) {
            const web3 = new Web3(provider);
            var accountsBalance = await web3.eth.getBalance(account)
            setCoinBalance((accountsBalance / 1000000000000000000).toFixed(4))
        
        }
    }

    useEffect(() => {
        metamaskBalance()

    }, [account, chainName , chainId , disconnect])

    return (
        <>
            <div className='container-fluid pt-4 d-flex justify-content-center' style={{ minHeight: "100vh" }}>
              
                <div className='col-sm-12 col-md-8 col-lg-5 swap-form-container'>
                <p className='extra-text'>Zero fees on swapping and earn up to 2% Capitron coin(CPRN) on every trade.</p>
                    <ul style={{ border: 'none' }} className="nav nav-tabs" id="myTab" role="tablist">
                        <li style={{ zIndex: 3 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(0)} ref={swapref} className="nav-link px-4 active" id="swap-tab" data-bs-toggle="tab" data-bs-target="#swap" type="button" role="tab" aria-controls="swap" aria-selected="true">Swap</button>
                        </li>
                        <li style={{ zIndex: 2 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(1)} ref={xswapref} className="nav-link px-4" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">XSwap</button>
                        </li>
                        <li style={{ zIndex: 1 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(2)} ref={cashref} className="nav-link px-4" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Cash</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="swap" role="tabpanel" aria-labelledby="swap-tab">
                            {
                                componentRender === 0 && <SwapForm chainLists={chainLists} chainName={chainName} coinBalance={coinBalance} componentRender={componentRender} />
                            }
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {
                                componentRender === 1 && <XSwapForm chainLists={chainLists} chainName={chainName}  componentRender={componentRender} />
                            }
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            {
                                componentRender === 2 && <CashForm chainLists={chainLists} coinBalance={coinBalance}  componentRender={componentRender} />
                            }
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-center mt-2'>
            <p className='extra-text'>Cant find your wallet connect option? go to  <a href='/hybridswap'>Hybrid swap</a></p>
            </div>
                </div>
            </div>
        
        </>
    )
}
