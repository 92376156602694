import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import "./section2.css"

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>
                <div className="container-fluid sec2-bg sec2-web">
                    <div className="row d-flex justify-content-center">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                            <div className="row">
                                <Slider {...settings}>

                                    <div className="slide-bg">
                                        <div className="card w-50 capcard">
                                            <div className="card-body">
                                                <p className="text-center slide-heading">Swap 10000+ coins to cash instantly..</p>
                                                <p className="sli-text">Instantly convert over 10,000+ coins to cash through our cash swap feature without any registration.Capitron offers even more efficient and secure swaps with the default Fusion upgrade, bringing together liquidity from the entire crypto market into a single, unified platform.</p>
                                                <a href='https://presale.capitron.exchange/ ' target="_blank" className="slide-button" style={{textDecoration:"none"}}>Buy Capitron  </a>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="slide2-bg">
                                        <div className="card  capcard2">
                                            <div className="card-body">
                                                <p className="text-center slide-heading">BUY CAPITRON</p>
                                                <p className="sli-text">Capitron is built on the Binance Smart chain and utilizes smart contracts to enable secure trading. The platform is designed to support the swapping of cryptocurrencies with the highest level of privacy and security.</p>
                                                <a href='https://presale.capitron.exchange/ ' target="_blank" className="slide2-button"  style={{textDecoration:"none"}}>Buy Capitron  </a>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mobile-sec sec2-bg">
                    <div className="row d-flex justify-content-center">
                        <div className="col-10 ">
                            <div className="card sec2-card">
                                <div className="card-body sec2-body">
                                    <p className="text-center" style={{ paddingTop: "227px" }}>BUY CAPITRON</p>
                                    <p className="text-center sli-text">Capitron is built on the Binance Smart chain and utilizes smart contracts to enable secure trading. The platform is designed to support the swapping of cryptocurrencies with the highest level of privacy and security.</p>
                                    <div className="row ">
                                        <div className="col-12 d-flex justify-content-center">
                                            <a href='https://presale.capitron.exchange/ ' target="_blank" className="slide-mobilebutton"  style={{textDecoration:"none"}}>Buy Capitron </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
