import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Web3 from 'web3';
import { Link } from 'react-router-dom'
import $ from "jquery";
import API_URL from '../../Api/apiUrl';
import { useWeb3Wallet } from '../../utils/web3wallet.context';
import Logo from "../../assets/CAPITRON logoD (13) 1.png"
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineDisconnect } from "react-icons/ai";
import './SwapNavbar.css';
import axios from 'axios';

export default function SwapNavbar({ chainLists, handleChainName, chainName, chainImage }) {
    const { connectWallet, disconnect, account, chainId, provider } = useWeb3Wallet();
    
    const [dropdownHeight, setDropdownHeight] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [walletBalance, setwalletBalance] = useState()
    const [explorerUrl, setExplorerUrl] = useState("")


    const expourl = () => {
        if (chainId == "56") {
            setExplorerUrl("https://bscscan.com/address")
        } else if (chainId == "1") {
            setExplorerUrl("https://etherscan.io/address")
        } else if (chainId == "137") {
            setExplorerUrl("https://polygonscan.com/address")
        } else if (chainId == "10") {
            setExplorerUrl("https://optimistic.etherscan.io/address")
        } else if (chainId == "142161") {
            setExplorerUrl("https://arbiscan.io/address")
        } else if (chainId == "250") {
            setExplorerUrl("https://ftmscan.com/address")
        } else if (chainId == "43114") {
            setExplorerUrl("https://snowtrace.io/address")
        }
    }

    useEffect(() => {
        expourl()
    }, [chainId])

    $('.icon-up').click(function () {
        $(this).toggleClass('rotate')
    })

    // copied message
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    const metamaskBalance = async () => {
        // debugger
        if (account) {
            // console.log("account:::",account);
            const web3 = new Web3(provider);
            var accountsBalance = await web3.eth.getBalance(account)
            setwalletBalance((accountsBalance / 1000000000000000000).toFixed(4))

        }
    }

    useEffect(() => {
        metamaskBalance()

    }, [account, chainId, chainName, disconnect])

    const notiFunction = () => {
        document.getElementById("notibar").style.display = "none"
    }
    return (
        <>
            <div className='p-0 noti-bar' id="notibar" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <div><span>Contact support if you are facing any issues</span></div>
                <div><span><RxCrossCircled onClick={notiFunction} /></span></div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light swap-navbar-container mb-3">

                <div className="container-fluid">
                    <img src={Logo} style={{ width: '60px', height: '60px' }} alt="Logo" />
                    <h2 className='swap-navbar-logo'>Capitron</h2>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse swap-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-5 gap-5">
                            <li className="nav-item swap-nav">
                                <Link to="/" className="nav-link active" aria-current="page" >Home</Link>
                            </li>
                            <li className="nav-item swap-nav">
                                <div className="dropdown">
                                    <a className=" dropdown-toggle nav-link  active " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", paddingTop: "8px", color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600" }}>
                                        Products
                                    </a>

                                    <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink" style={{ background: "#01020E", border: "1px solid #6100FF" }}>
                                        <li ><a className="dropdown-item prod-list" href="/swap">Defi Swap </a></li>
                                        <li ><a className="dropdown-item prod-list" href="/nativeswap">Native Swap </a></li>
                                        <li ><a className="dropdown-item prod-list" href="/hybridswap">Hybrid Swap </a></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item swap-nav">
                                <Link to="/blog" className="nav-link  active" aria-current="page">Blog</Link>
                            </li>
                            <li className="nav-item swap-nav">
                                <Link to="/docs/terminologies" target='_blank' className="nav-link  active" aria-current="page">Terminologies</Link>
                            </li>

                        </ul>


                        <div className='d-flex gap-3'>
                            <div className=' pe-4 '>
                                <div className="dropdown">
                                    <button className="network-button  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={chainImage} style={{ height: "25px", borderRadius: "50%" }} />  {(chainName == "BNB") ? "BSC" : chainName}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%", cursor: "pointer" }}>
                                        {chainLists?.map((lists, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    handleChainName(lists.name, lists.imageURL)

                                                }} className='d-flex mt-2' >
                                                    <div>
                                                        <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "30px", borderRadius: "50%" }} /></span>
                                                    </div>
                                                    <div style={{ marginLeft: "5px" }}>
                                                        <p>{(lists.name == "BNB") ? "BSC" : lists.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </ul>
                                </div>

                            </div>
                            {account ? (
                                <>
                                    <button className="swap-connect-button px-4 " onClick={(e) => {
                                        // disconnect(e);
                                        setDropdownHeight(!dropdownHeight)
                                    }}>
                                        {account
                                            ? (account.slice(0, 5) + ".." + account.substr(38))
                                            : "CONNECT WALLET"}
                                        <i className="fa fa-angle-down  icon-up" style={{ fontSize: "24px" }}></i>
                                    </button>

                                    <div style={dropdownHeight ? { height: 'auto' } : { height: '0px', padding: "0px", border: "0", display: "none" }} className='my-dropdown px-4 mt-5 flex flex-col gap-8 p-4'>
                                        <div className='my-dropdown-first flex justify-between gap-3'>

                                            <div className='my-dropdown-header text-sm font-semibold flex items-center gap-1.5 text-gray-700 dark:text-slate-200'>
                                                {account
                                                    ? (account.slice(0, 5) + ".." + account.substr(38))
                                                    : "CONNECT WALLET"}

                                            </div>
                                            <div className='my-dropdown-icon'>
                                                <CopyToClipboard text={account} onCopy={onCopyText}>
                                                    <div className="copy-area">
                                                        <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>

                                                    </div>
                                                </CopyToClipboard>



                                                <a href={`${explorerUrl}/${account}`} target="_blank"> <i className="fa fa-link" style={{ fontSize: "22px" }} title='View on Explorer'></i></a>
                                                <span onClick={disconnect}><AiOutlineDisconnect title='Disconnect Wallet' style={{ fontSize: "25px" }} /></span>


                                            </div>

                                        </div>
                                        <div className='my-dropdown-second'>

                                            <div className='my-dropdown-network'>
                                                {/* {console.log("wallet balance:::",walletBalance)} */}
                                                {walletBalance} {chainId == "1" || chainId == "10" || chainId == "42161" ? "ETH" : (chainId == "56" ? "BNB" : (chainId == "137" ? "MATIC" : (chainId == "250" ? "FTM" : (chainId == "43114" ? "AVAX" : ""))))}
                                            </div>
                                            <div className='my-dropdown-doller'>
                                                {/* $0.00 */}
                                            </div>

                                        </div>


                                    </div>




                                </>
                            ) : (
                                <button
                                    className="swap-connect-button px-4 " onClick={connectWallet}>
                                    CONNECT WALLET
                                    <i className="fa fa-angle-down icon-up" style={{ fontSize: "24px" }}></i>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
