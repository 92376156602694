import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";

import { NavLink } from "react-router-dom";
import './sidebar.css';

const Sidebar = () => {
  return (
    <>
    <div className="d-block Sidebar"
       style={{ display: "flex", height: "100%", overflow: "scroll initial", position:"fixed" }}
     >
      <CDBSidebar breakpoint={1100} textColor="#fff" backgroundColor="#ff3333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <h4
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            Capitron
          </h4>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/docs/content" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns" style={{color : "black"}}>
                Project Overview
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/docs/aboutus" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table" style={{color : "black"}}>About Us</CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/docs/capitronProducts"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="user" style={{color : "black"}}>
                Capitron Products
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/docs/terminologies" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line" style={{color : "black"}}>
                Terminologies
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/docs/roadmap" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="credit-card" style={{color : "black"}}>
                Roadmap
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/docs/tokenomics" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle" style={{color : "black"}}>
                Tokenomics
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/docs/partners" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user" style={{color : "black"}}>
                Partners
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/docs/liquidityexchange" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user" style={{color : "black"}}>
                Liquidity Exchange
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
    </>
  );
};

export default Sidebar;


