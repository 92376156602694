import React, { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';

import {  walletAddressTron , walletAddressDoge  , walletAddressBtc }  from "../utils/commonConstant"
import Trx from "../assets/trx.png"
import DogeCoin from "../assets/coinlogo/dogecoin.png"
import BitCoin from "../assets/coinlogo/bitcoin (1).png"
import { walletAddress } from "../utils/commonConstant"
import axios from 'axios'
import { ethers } from 'ethers'
import API_URL from '../Api/apiUrl'
import { useWeb3Wallet } from '../utils/web3wallet.context'
import "./Tron.css"
import SwapArrow from '../assets/Swap-arrow.png'


export default function XSwapTron({Networks}) {
    const { connectTron, disConnectTron, tronAccount, xdefiDoge, xdefiBtc, btcWallets, dogeWallets } = useWeb3Wallet();
 


    const [curramount, setCurramount] = useState();
    const [amount1, setAmount1] = useState()
    const [lowerLists, setLowerLists] = useState([]);
    const [coinName1_xswap, setCoinName1_xswap] = useState()
    const [coinImage1_xswap, setCoinImage1_xswap] = useState()
    const [amountInDollar, setAmountInDollar] = useState()
    const [amountInDollar2, setAmountInDollar2] = useState()
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState([]);
    const [search, setSearch] = useState();

    const [chain_xswaplower, setChain_xswaplower] = useState("Fantom")
    const [chain_xswapImagelower, setChain_xswapImagelower] = useState("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")
  
    const [setFilteredLowerList, setSetFilteredLowerList] = useState([]);
    const [chainLists_cash, setChainLists_cash] = useState([]);
    const [count_xswap, setCount_xswap] = useState()
    const [nativeImage, setNativeImage] = useState(Trx)
    const [nativeNetwork, setNativeNetwork] = useState("TRON")
    const [nativeCurrency, setNativeCurrency] = useState()
    const [nativeCurrencyImage, setNativeCurrencyImage] = useState()


    const chainShowlower = () => {
        if (chain_xswaplower == "Ethereum") {
            setCoinName1_xswap("ETH")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png")
        
        } else if (chain_xswaplower == "BSC") {
            setCoinName1_xswap("BNB")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")
          
        } else if (chain_xswaplower == "Polygon") {
            setCoinName1_xswap("MATIC")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png")
        } else if (chain_xswaplower == "Arbitrum") {
            setCoinName1_xswap("ARB")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png")
        } else if (chain_xswaplower == "Optimism") {
            setCoinName1_xswap("OP")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png")
           
        } else if (chain_xswaplower == "Avalanche") {
            setCoinName1_xswap("AVAX")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png")
          
           
        } else if (chain_xswaplower == "Fantom") {
            setCoinName1_xswap("FTM")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")
          
        }
    }
   

    const chainUpper = () => {
        if (nativeNetwork == "TRON") {
            setNativeCurrency("TRX")
            setNativeCurrencyImage(Trx)
        } else if (nativeNetwork == "Bitcoin") {
            setNativeCurrency("BTC")
            setNativeCurrencyImage(BitCoin)
        } else if (nativeNetwork == "Dogecoin") {
            setNativeCurrency("DOGE")
            setNativeCurrencyImage(DogeCoin)
        }
    }

    useEffect(() => {
        chainUpper()
    }, [nativeNetwork])


    const tokenNumberLower = () => {
        axios.get(`${API_URL.getTokenCount}?chainName=${chain_xswaplower}`)
            .then((res) => {
             
                allTokensLower(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const allTokensLower = (count) => {
        axios.get(`${API_URL.getTokenList}?chainName=${chain_xswaplower}&no_of_docs_each_page=${count}&current_page_number=${0}`)
            .then((res) => {
              
                setLowerLists(res.data.data)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    const allchainList_cash = () => {
        axios.get(API_URL.getchainList)
            .then((res) => {
              
                setChainLists_cash(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        allchainList_cash()
    }, [])

    const exchangeRate = () => {
        axios.get(`${API_URL.exchange}?amount=${curramount}&convert=${coinName1_xswap}&symbol=${nativeCurrency}`)
            .then((res) => {
               
                setAmount1(res.data.data?.convert?.amount)
                setAmountInDollar(res.data.data?.symbol?.amountInDollar)
                setAmountInDollar2(res.data.data?.convert?.amountInDollar);
            })
            .catch((err) => {
                console.log(err)
                setAmount1(0)
                setAmountInDollar(0)
                setAmountInDollar2(0);
            })
    }

    useEffect(() => {
        exchangeRate()
    }, [nativeCurrency, coinName1_xswap, curramount])

    

    useEffect(() => {
        tokenNumberLower()
    }, [chain_xswaplower])

    

    useEffect(() => {

        chainShowlower()
    }, [chain_xswaplower])

  const trxBalance = async() => {
    let tronWeb = window.tronWeb;
    let balance = await tronWeb.trx.getBalance(tronAccount);
   
  }

  useEffect(() => {
    if(tronAccount){
    trxBalance()
}
  }, [tronAccount])
    const transferToken = async (e) => {
        e.preventDefault()

        if (nativeCurrency == "TRX") {
            try {
                if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
                  
                    let tronWeb = window.tronWeb;
                    const isAddressValid = tronWeb.isAddress(
                        walletAddressTron
                    );
                    if (!isAddressValid) {
                        console.error("Invalid recipient address");
                        return;
                    }
                    const value = curramount * 1000000
                    const tradeobj = await tronWeb.transactionBuilder.sendTrx(
                        walletAddressTron,
                        value
                    );
                    const signedtxn = await tronWeb.trx.sign(tradeobj);
                    let receipt = await tronWeb.trx.sendRawTransaction(signedtxn);
                  

                    NotificationManager.success("Transaction Successfull !");
                }
            } catch (error) {
                console.log(error)
                NotificationManager.error(error);
            }
        } else if (nativeCurrency == "BTC") {
            const feeRate = 1; // replace with desired fee rate
            const from = btcWallets; // replace with actual address
            const recipient = walletAddressBtc; // replace with actual address
        
            const amount = {
                amount: curramount*100000000, /* 1 BTC = 100,000,000 Satoshis*/
                decimals: 8
            }; // amount to transfer in BTC
            const memo = "Example Bitcoin transfer"; // replace with actual memo

            window.xfi.bitcoin.request(
                {
                    method: "transfer",
                    params: [
                        {
                            feeRate,
                            from,
                            recipient,
                            amount,
                            memo
                        }
                    ]
                },
                (error, result) => {
                    console.log(error, result);
                    // NotificationManager.success(result)
                    // NotificationManager.error(error);
                }
            );
        } else if (nativeCurrency == "DOGE") {
            const { asset, from, recipient, amount, memo } = {
                asset: {
                    chain: "DOGE",
                    symbol: "DOGE",
                    ticker: "DOGE"
                },
                from: dogeWallets, // replace with actual address
                recipient: walletAddressDoge,// replace with actual address
                // equivalent to 5.0 DOGE   1 DOGE = 100000000

                amount: {
                    amount: curramount*100000000,
                    decimals: 8
                },
                memo: "Transfering Coins .." // optional
            };
          
            window.xfi.dogecoin.request(
                {
                    method: "transfer",
                    params: [
                        {
                            asset,
                            from,
                            recipient,
                            amount,
                            memo
                        }
                    ]
                },
                (error, result) => {
                    console.log(error, result);
                  
                }
            );
        }


    }


    

    const handleChangeLower = (e) => {
        setSearch(e.target.value)
        let filteredArray = lowerLists.filter((token) => {
            return token.symbol.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setSetFilteredLowerList(filteredArray)

    }

    

    useEffect(() => {
        setSetFilteredLowerList(lowerLists)
    }, [lowerLists])




    return (
        <>
            <div className="swap-tab-container p-3 d-flex flex-column">

                <div className="input-container p-4 d-flex flex-column gap-3 active">



                    <div className="d-flex justify-content-between flex-wrap ">

                        <div className='d-flex flex-column'>
                            <div className="dropdown d-flex align-items-center ">


                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={nativeImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {nativeNetwork}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%" , cursor: "pointer"}}>
                                      
                                    {/* oprional value */}

                                    <p onClick={() => { setNativeImage(Trx); setNativeNetwork("TRON") ; Networks("TRON") ; connectTron()}}><img src={Trx} style={{ height: "25px", borderRadius: "50%" }} />TRON</p>
                                        <p onClick={() => { setNativeImage(BitCoin); setNativeNetwork("Bitcoin") ; Networks("Bitcoin") ; xdefiBtc() }}><img src={BitCoin} style={{ height: "25px", borderRadius: "50%" }} />Bitcoin</p>
                                        <p onClick={() => { setNativeImage(DogeCoin); setNativeNetwork("Dogecoin") ; Networks("Dogecoin") ; xdefiDoge()}}><img src={DogeCoin} style={{ height: "25px", borderRadius: "50%" }} />Dogecoin</p>
                                   
                                    </ul>

                                </div>
                            </div>

                            <input className="input-field mt-2 " type="text" placeholder="00"
                                onChange={(e) => setCurramount(e.target.value)} />
                        </div>

                        <div className="dropdown d-flex align-items-center">

                            <img src={nativeCurrencyImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{nativeCurrency}</span>
                          
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${amountInDollar}</p>
                        {/* <p className="input-balance p-0 m-0">Balance: {balance ? balance : "0"}</p> */}
                    </div>
                </div>

                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                </div>

                <div className="input-container p-4 d-flex flex-column gap-3">

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <div className='d-flex flex-column'>
                            <div className="dropdown d-flex align-items-center">
                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={chain_xswapImagelower} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {(chain_xswaplower == "BNB") ? "BSC" : chain_xswaplower}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%" }}>
                                        {chainLists_cash?.map((lists, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    setChain_xswaplower((lists.name == "BNB") ? "BSC" : lists.name)
                                                    setChain_xswapImagelower(lists.imageURL)
                                                  
                                                }} className='d-flex mt-2' >
                                                    <div>
                                                        <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "25px", borderRadius: "50%" }} /></span>
                                                    </div>
                                                    <div style={{ marginLeft: "5px" }}>
                                                        <p>{(lists.name == "BNB") ? "BSC" : lists.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </div>
                            <input onChange={() => {
                                setAmount1(amount1);
                            }} value={amount1} disabled={true} className="input-field" type="text" placeholder="00" />
                            {/* <input className="input-field mt-2" type="text" placeholder="1527.768099" /> */}
                        </div>

                        <div className="dropdown d-flex align-items-center">
                            <img src={coinImage1_xswap} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{coinName1_xswap}</span>
                            <i style={{ fontSize: "24px" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop1_xswap">&#xf107;</i>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${amountInDollar2}</p>
                        <p className="input-balance p-0 m-0">Balance: 0</p>
                    </div>
                </div>

                <p className='token-value ps-4 py-2 mt-4'>
                    {curramount}&nbsp;{nativeCurrency} = {amount1}&nbsp;{coinName1_xswap}
                    <span>

                    </span>
                </p>

                <div className='w-100 swap-button-container m-auto'>
                    {tronAccount || dogeWallets || btcWallets ?

                        ((+curramount > +balance) ?
                            <>
                                <button className='px-5 py-1 w-100' data-bs-toggle="modal" data-bs-target="#exampleModal_xswap" >
                                    {loading ? <i
                                        className="fa fa-circle-o-notch fa-spin"
                                        style={{ fontSize: 16 }}
                                    /> : " SWAP"}

                                </button>
                            </> :
                            <>
                                <button className='px-5 py-1 w-100'>
                                   Enter Amount

                                </button>
                            </>
                        )

                        :

                        (nativeNetwork == "TRON") ?
                            <button className='px-5 py-1 w-100' onClick={connectTron}>CONNECT WALLET</button> :

                            (nativeNetwork == "Bitcoin") ?
                                <button className='px-5 py-1 w-100' onClick={xdefiBtc}>CONNECT WALLET</button> :
                                <button className='px-5 py-1 w-100' onClick={xdefiDoge}>CONNECT WALLET</button>


                    }
                </div>
            </div>


            {/* Modal currency 2 */}
            <div className="modal fade" id="staticBackdrop1_xswap" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" value={search} className="mb-5 " placeholder='Search Token'
                                    onChange={handleChangeLower} />
                            </div>
                            {setFilteredLowerList?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        setCoinName1_xswap(lists.symbol);
                                        setCoinImage1_xswap(lists.imageURL)
                                    }} className='d-flex mt-4' data-bs-dismiss="modal" >
                                        <div>
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "30px", borderRadius: "50%" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>



            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal_xswap" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Swap</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="swap-tab-container p-3 d-flex flex-column">

                                <div className="input-container p-4 d-flex flex-column gap-3 active">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {curramount}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={nativeCurrencyImage} style={{ height: "20px", borderRadius: "50%" }} />
                                          {nativeCurrency}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                                </div>

                                <div className="input-container p-4 d-flex flex-column gap-3">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {amount1}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={coinImage1_xswap} style={{ height: "20px", borderRadius: "50%" }} />
                                            {coinName1_xswap}
                                        </div>
                                    </div>
                                </div>

                                <p className='token-value ps-4 py-2 mt-4'>
                                    {curramount}&nbsp;{nativeCurrency} = {amount1}&nbsp;{coinName1_xswap}
                                    <span>
                                        {/* (~$1 418.77 ) */}
                                    </span>
                                </p>

                                <div className='d-flex justify-content-between align-items-center swap-button-container m-auto'>


                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn btn-primary confirm-swap w-100" data-bs-dismiss="modal" onClick={transferToken}>CONFIRM</button>

                        </div>
                        {/*  */}
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    )
}
