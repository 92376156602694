import React from 'react'
import './blog.css'


export default function Read() {
  return (
    <>
        <div className='container d-flex align-items-center' style={{minHeight:"100vh"}}>
            <div className='row d-flex justify-content-center align-content-center'>
                <div className='col-md-12 d-flex justify-content-center'>
                <div className='read p-5 w-50 '>
                    <h1>Sushi Concentrated Liquidity v3 Is Live!</h1>
                    <p>Become a crypto chef with SushiSwap! Sushi is one of the most used and reliable decentralized exchanges, deployed across 10+ blockchains and supporting thousands of tokens. You can trade, earn, stack yields and safely move assets across chains, all on one decentralized, community-driven platform.</p>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}
