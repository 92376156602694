import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Logo from "../../assets/CAPITRON logoD (13) 1.png";

import "./blog.css";

// import Back from "./Back";
import Read from "./Read";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <>
      <div
        className="row"
        style={{ paddingTop: "50px", paddingRight: "50px", fontWeight: 500 }}
      >
        <div className=" col-11 d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 flex-wrap align-items-center">
            <img src={Logo} style={{ width: '60px', height: '60px' }} alt="Logo" />
            <h2 className="swap-footer-logo">Capitron</h2>
          </div>
          <div className="d-flex ">
            <div
              className="dropdown"
              style={{ marginRight: "35px", fontSize: "23px" }}
            >
              <a
                className=" dropdown-toggle pr-5"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ textDecoration: "none" }}
              >
                Products
              </a>

              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/swap">
                    Defi Swap
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/nativeswap">
                    Native Swap
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/hybridswap">
                    Hybrid Swap
                  </Link>
                </li>
              </ul>
            </div>
            <Link to="/" style={{ textDecoration: "none", fontSize: "23px" }}>
              Home
            </Link>
          </div>
        </div>
      </div>

      <div className="container -fluid">
        <div className="row d-flex justify-content-center pt-5">
          <div className="col-md-10 col-lg-10 col-xl-10 col-sm-10 col-10 text-center">
            <h3>Update and Announcements</h3>
            <h6>
              On this page we publish the latest news of our company: product
              updates, achievements, collaborations, events and much more. Stay
              in touch with us!{" "}
            </h6>
          </div>
        </div>

        <hr />

        <section className="card-section" style={{ padding: "0 7.2vw" }}>
          <article
            style={{
              display: "flex",
              gap: "6vw",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to swap your cryptocurrencies on capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Swap your cryptocurrencies on capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read1">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use cross chain swap(xswap) on Capitron?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Cross chain swap(xswap) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read2">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Cash in defiswap on Capitron?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Cash in defiswap on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read3">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Hybrid(HSwap) on Capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Hybrid(HSwap) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read4">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Hybrid cross chain swap(HNSwap) on Capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Hybrid cross chain swap(HNSwap) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read5">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Hybrid Cash(HCash) on Capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Hybrid Cash(HCash) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read6">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Native cross chain swap(NXSwap) on Capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Native cross chain swap(NXSwap) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read7">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>

            <main style={{ backgroundColor: "#dfe5e7", width: "285px" }}>
              <h5
                style={{
                  backgroundColor: "#66668d",
                  padding: "30px 10px 60px 20px",
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
              >
                How to use Native Cash(NCash) on Capitron ?
              </h5>
              <div style={{ padding: "15px 10px 20px 20px" }}>
                <p>Native Cash(NCash) on Capitron</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>16 May, 2023</p>
                  <Link to="/read8">
                    Read More <AiOutlineArrowRight />
                  </Link>
                </div>
              </div>
            </main>
          </article>
        </section>
      </div>
    </>
  );
}
