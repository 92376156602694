import React from 'react'
import Fusion from "../../assets/FUSION IMAGE (2).png"
import "./capfusion.css"
import { Link } from 'react-router-dom'

export default function capfusion() {
  return (
    <div className='container-fluid cap-bg'>
      <div className='row d-flex justify-content-center'>
        <div className='col-xl-10 col-lg-10 col-md-11 col-sm-12'>
          <div className="card cap-card">
            <div className="card-body">
              <div className='row '>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 '>
                  <p className='text-center fusion-heading' >Swap free, Trade happy</p>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-xl-10'>
                      <p className='fusion-text text-white-50' >Comprehensive exchange for swapping 10,000+ coins, ensuring transparency and trustworthiness in crypto trading. Our platform is dedicated to making trading simple and accessible, which is why we have removed all swap fees. Experience the freedom of swapping your crypto coins without any additional charges .</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-10 d-flex justify-content-center'>
                      <Link to = "/hybridswap" className='cap-button'>Learn More <i className='fas fa-arrow-right'></i></Link>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center'>
                  <img src={Fusion} className="img-fluid" style={{ height: "300px", width: "300px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
