import React from 'react'
import Bitcoin from "../../../assets/network icons/Bitcoin.png"
import DogeCoin from "../../../assets/network icons/Dogecoin.png"
import TronCoin from "../../../assets/network icons/Tron.png"
import "./Section4.css"

export default function Section4() {
    return (
        <div className='container-fluid pb-4'>
            {/* <div className='row d-flex justify-content-center'>
        <div className='col-xl-9 col-lg-9 col-md-8 col-sm-8'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-8 col-sm-8 p-0 d-flex justify-content-end'>
              <div className='sec3-box mt-3'>
                <span className='d-flex justify-content-center sec3-text'>CAPITRON IS A </span>
                <span className='d-flex justify-content-center sec3-text'>GROWING MARKET</span>
                <span className='d-flex justify-content-center'>Capitron is a Growing Network</span>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-4 col-sm-4 p-0 mt-4'>
              <div className='row d-flex justify-content-center'>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>345</span>
                    <span className='d-flex justify-content-center'>Liquidity Sources</span>
                  </div>

                </div>
              </div>
              <div className="row mt-2">
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>$281B+</span>
                    <span className='d-flex justify-content-center'>Total Volume</span>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>4.4M+</span>
                    <span className='d-flex justify-content-center'>Total Wallets</span>
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center mt-2'>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>28.7M+</span>
                    <span className='d-flex justify-content-center'>Total Trades</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-11 col-10'>
                    <span className='d-flex justify-content-center sec3-_text3'>Supported Native Chains </span>

                    {/* <span className='d-flex justify-content-center sec3-text3'>Optimize your Trades Across </span>
                    <span className='d-flex justify-content-center sec3-_text3'> Hundreds of DEXes on Multiple Networks</span> */}
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Bitcoin} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={DogeCoin} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={TronCoin} />
                        </div>
                        {/* <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Optimism} />
            </div> */}

                        {/* <div className='col-xl-2  col-lg-2 col-md-4 col-sm-3 col-6'>
              <img src={Gnosis} />
            </div> */}
                    </div>
                    {/* <div className='row  d-flex justify-content-center mt-5'>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Avalanche} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Arbitrum} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Fantom} />
                        </div>

                    </div> */}
                </div>
            </div>
        </div>
    )
}
