import React from "react";
import Sidebar from "../sidebar/Sidebar";
import "./sidebar.css";
import Content from "../sidebar/Content";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import AboutUs from "./AboutUs";
import CapitronProduct from "./CapitronProduct";
import Terminologies from "./Terminologies";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";

export default function Routing() {
  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ color: "white", fontFamily: "Signika" }}>
          <div className="col-xl-2 col-2 p-0">
            <Sidebar />
          </div>

          <div className="col-xl-10 col-10  mt-5 ">
            <div className="row ">
              <div className=" col-10 d-flex justify-content-end ">
                <div
                  className="dropdown"
                  style={{ marginRight: "35px", fontSize: "23px" }}
                >
                  <a
                    className=" dropdown-toggle pr-5"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ textDecoration: "none" }}
                  >
                    Products
                  </a>

                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/swap">
                        Defi Swap
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/nativeswap">
                        Native Swap
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/hybridswap">
                        Hybrid Swap
                      </Link>
                    </li>
                  </ul>
                </div>
                <Link
                  to="/"
                  style={{ textDecoration: "none", fontSize: "23px" }}
                >
                  Home
                </Link>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
