import React from "react";

const LiquidityExchange = () => {

  return (
    <>
      <section>

      <h2
          className="container-fluid col-md-10 col-xl-10 col-sm-10 col-10"
          style={{
            color: "grey",
            fontWeight: 700,
            fontSize: "40px",
            marginBottom: "40px",
          }}
        >
         Liquidity Exchange
        </h2>
      <div className="integrated-protocols text-black" style={{paddingLeft:"80px"}}>
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab">Ethereum Network</h3>
            <div id="ETH-content" className="tab-content d-flex flex-wrap gap-4" >
                        <a href="https://sakeswap.finance/#/" className="circle" target="_blank" title="Sake Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sakeswap.svg" alt="Sake Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                       
                        <a href="https://synthetix.io/" className="circle" target="_blank" title="Syntethix">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synthetix.svg" alt="Syntethix" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_self" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://mstable.org/" className="circle" target="_blank" title="Mstable">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/mstable.svg" alt="Mstable" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/uniswap/" className="circle" target="_self" title="Uniswap v2">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/uniswap.svg" alt="Uniswap v2" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://chai.money/" className="circle" target="_blank" title="Chai">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/chai.svg" alt="Chai" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://blackholeswap.com" className="circle" target="_blank" title="Blackholeswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/blackholeswap.svg" alt="Blackholeswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/balancer/" className="circle" target="_self" title="Balancer">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/balancer.svg" alt="Balancer" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://oasisprotocol.org/" className="circle" target="_blank" title="Oasis">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/oasis.svg" alt="Oasis" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://s.finance/" className="circle" target="_blank" title="sFinance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sfinance.svg" alt="sFinance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://smoothy.finance/" className="circle" target="_blank" title="Smoothy Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/smoothy.svg" alt="Smoothy Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://powerindex.io/#/mainnet/" className="circle" target="_blank" title="Powerindex">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/powerindex.svg" alt="Powerindex" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/bancor/" className="circle" target="_self" title="Bancor">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/bancor.svg" alt="Bancor" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/aave/" className="circle" target="_self" title="Aave">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/aave.svg" alt="Aave" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/dodo/" className="circle" target="_self" title="DODO">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dodo.svg" alt="DODO" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://kyber.network" className="circle" target="_blank" title="Kyber">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.shellprotocol.io" className="circle" target="_blank" title="Shell">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/shell.svg" alt="Shell" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://yearn.finance/" className="circle" target="_blank" title="yearn">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/yearn.svg" alt="yearn" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://valueliquid.io/#/swap" className="circle" target="_blank" title="Value Liquid">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/valueliquid.svg" alt="Value Liquid" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/compound/" className="circle" target="_self" title="Compound">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/compound.svg" alt="Compound" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://xsigma.fi/" className="circle" target="_blank" title="xSigma">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/xsigma.svg" alt="xSigma" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_self" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/maker-dao/" className="circle" target="_self" title="PSM">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/maker.svg" alt="PSM" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://linkswap.app/" className="circle" target="_blank" title="Linkswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/linkswap.svg" alt="Linkswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.miniswap.org/" className="circle" target="_blank" title="Mini Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/miniswap.svg" alt="Mini Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://luaswap.org/#/" className="circle" target="_blank" title="Lua">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/luaswap.svg" alt="Lua" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/limit-order-protocol/" className="circle" target="_blank" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://crypto.com/defi/swap" className="circle" target="_blank" title="Crypto Defi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/defiswap.svg" alt="Crypto Defi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swerve.fi/" className="circle" target="_blank" title="SWere">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swerve.svg" alt="SWere" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://clippercoin.com/" className="circle" target="_blank" title="Clipper">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/clipper.svg" alt="Clipper" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://conv.finance/" className="circle" target="_blank" title="Convergence X">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/convergence.svg" alt="Convergence X" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://dfx.finance/" className="circle" target="_blank" title="DFX Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dfx.svg" alt="DFX Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://mooniswap.exchange/#/swap" className="circle" target="_blank" title="Mooniswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/mooniswap.svg" alt="Mooniswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://saddle.finance/" className="circle" target="_blank" title="Saddle Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/saddle.svg" alt="Saddle Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://shibaswap.com/#/" className="circle" target="_blank" title="ShibaSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/shiba.svg" alt="ShibaSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swapr.eth.link/#/swap?chainId=1" className="circle" target="_blank" title="Swapr">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swapr.svg" alt="Swapr" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.unifiprotocol.com/" className="circle" target="_blank" title="Unifi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/unifi.svg" alt="Unifi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://synapseprotocol.com/landing" className="circle" target="_blank" title="Synapse">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synapse.svg" alt="Synapse" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://lido.fi/" className="circle" target="_blank" title="lido.fi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/steth.svg" alt="lido.fi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.hashflow.com/" className="circle" target="_blank" title="Hashflow">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/hashflow.svg" alt="Hashflow" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://defiplaza.net/" className="circle" target="_blank" title="DeFi Plaza">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/defiplaza.svg" alt="DeFi Plaza" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://pooltogether.com/" className="circle" target="_blank" title="Pooltogether">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/pooltogether.svg" alt="Pooltogether" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elasticswap.org/" className="circle" target="_blank" title="ElasticSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elastic_swap.svg" alt="ElasticSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://frax.finance/" className="circle" target="_blank" title="Frax swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/frax_swap.svg" alt="Frax swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.radioshack.org/" className="circle" target="_blank" title="RadioShack">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/radioshack.svg" alt="RadioShack" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.0x.org/" className="circle" target="_blank" title="0x">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/0x.svg" alt="0x" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/pancakeswap/" className="circle" target="_self" title="Pancake swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/pancakeswap.svg" alt="Pancake swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.anglefinance.com.au/" className="circle" target="_blank" title="Angle Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/angle.svg" alt="Angle Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://rocketpool.net/#header" className="circle" target="_blank" title="Rocketpool">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/rocketpool.svg" alt="Rocketpool" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://integral.link/" className="circle" target="_blank" title="Integral">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/integral.svg" alt="Integral" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://solidly.exchange/" className="circle" target="_blank" title="Solidly">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/solidly.svg" alt="Solidly" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://nomiswap.io/" className="circle" target="_blank" title="Nomiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/nomiswap.svg" alt="Nomiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.mav.xyz/" className="circle" target="_blank" title="Maverick">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/maverick.svg" alt="Maverick" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">BNB Chain</h3>
            <div id="BSC-content" className="tab-content d-flex flex-wrap gap-4">
                    <a href="/aggregation-protocol/pancakeswap/" className="circle" target="_self" title="Pancake swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/pancakeswap.svg" alt="Pancake swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://venus.io/" className="circle" target="_blank" title="Venus">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/venus.svg" alt="Venus" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="/aggregation-protocol/bakeryswap/" className="circle" target="_self" title="BakerySwap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/bakeryswap.svg" alt="BakerySwap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://julswap.com/" className="circle" target="_blank" title="JulSwap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/julswap.svg" alt="JulSwap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="/aggregation-protocol/dodo/" className="circle" target="_self" title="DODO">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/dodo.svg" alt="DODO" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://apeswap.finance/" className="circle" target="_blank" title="ApeSwap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/apeswap.svg" alt="ApeSwap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://app.acryptos.com/" className="circle" target="_blank" title="Acryptos">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/acryptos.svg" alt="Acryptos" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://spartanprotocol.org/" className="circle" target="_blank" title="Spartan">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/spartan.svg" alt="Spartan" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://hyperjump.fi/" className="circle" target="_blank" title="HyperJump">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/hyperjump.svg" alt="HyperJump" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://ellipsis.finance/" className="circle" target="_blank" title="Ellipsis Finance">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/ellipsisfinance.svg" alt="Ellipsis Finance" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://nerve.fi/" className="circle" target="_blank" title="Nerve Finance">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/nerve.svg" alt="Nerve Finance" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://smoothy.finance/" className="circle" target="_blank" title="Smoothy Finance">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/smoothy.svg" alt="Smoothy Finance" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://swipe.org/" className="circle" target="_blank" title="Swipe swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/swapswipe.svg" alt="Swipe swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://bsc.valuedefi.io/" className="circle" target="_blank" title="Value Liquid">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/valueliquid.svg" alt="Value Liquid" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.unifiprotocol.com/" className="circle" target="_blank" title="Unifi">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/unifi.svg" alt="Unifi" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://cheeseswap.app/#/swap" className="circle" target="_blank" title="Cheese Swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/cheeseswap.svg" alt="Cheese Swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://gambit.financial/" className="circle" target="_blank" title="Gambit Finance">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/gambit.svg" alt="Gambit Finance" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://jetswap.finance/" className="circle" target="_blank" title="JetSwap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/jetswap.svg" alt="JetSwap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://mdex.co/#/" className="circle" target="_blank" title="MDEX">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/mdex.svg" alt="MDEX" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.wardenswap.finance/#/best-rate-swap" className="circle" target="_blank" title="Warden Swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/warden.svg" alt="Warden Swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="/limit-order-protocol/" className="circle" target="_self" title="1inch Limit Order Protocol">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://swap.wault.finance/bsc/index.html#/swap" className="circle" target="_blank" title="Wault Swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/waultswap.svg" alt="Wault Swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://synapseprotocol.com/landing" className="circle" target="_blank" title="Synapse">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/synapse.svg" alt="Synapse" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://dmm.exchange/#/swap" className="circle" target="_blank" title="Kyber DMM">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber DMM" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://dopple.finance/" className="circle" target="_blank" title="Dopple">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/dopple.svg" alt="Dopple" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://home.babyswap.finance/" className="circle" target="_blank" title="BabySwap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/babyswap.svg" alt="BabySwap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://autoshark.finance/" className="circle" target="_blank" title="Autoshark">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/autoshark.svg" alt="Autoshark" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://cafeswap.finance/" className="circle" target="_blank" title="Cafe Swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/cafe_swap.svg" alt="Cafe Swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.hashflow.com/" className="circle" target="_blank" title="Hashflow">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/hashflow.svg" alt="Hashflow" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://biswap.org/" className="circle" target="_blank" title="biswap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/biswap.svg" alt="biswap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://app.planet.finance/" className="circle" target="_blank" title="Planet Finance">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/planetfinance.svg" alt="Planet Finance" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.annex.finance/" className="circle" target="_blank" title="Annex">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/annex.svg" alt="Annex" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.radioshack.org/" className="circle" target="_blank" title="RadioShack">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/radioshack.svg" alt="RadioShack" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://fstswap.finance/#/swap" className="circle" target="_blank" title="Fstswap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/fstswap.svg" alt="Fstswap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://nomiswap.io/" className="circle" target="_blank" title="Nomiswap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/nomiswap.svg" alt="Nomiswap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.wombat.exchange/" className="circle" target="_blank" title="Wombat">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/wombat.svg" alt="Wombat" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://www.cone.exchange/" className="circle" target="_blank" title="Cano">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/cano.svg" alt="Cano" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://babydoge.com/" className="circle" target="_blank" title="Baby Doge Swap">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/babydoge.svg" alt="Baby Doge Swap" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                    <a href="https://thena.fi/" className="circle" target="_blank" title="Thena">
                        <img src=" https://1inch.io/img/aggregationProtocol/icons/thena.svg" alt="Thena" loading="lazy"/>
                        <div className="circle-border"></div>
                        <div className="circle-border-2"></div>
                    </a>
                <div className="bg-gradient"></div>
            </div>
        
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">Polygon Network</h3>
            <div id="polygon-content" className="tab-content d-flex flex-wrap gap-4">
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_self" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_self" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://quickswap.exchange/#/swap" className="circle" target="_blank" title="quickSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/quickswap.svg" alt="quickSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/aave/" className="circle" target="_self" title="Aave">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/aave.svg" alt="Aave" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swap.cometh.io/#/swap" className="circle" target="_blank" title="Cometh">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/cometh.svg" alt="Cometh" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.dfyn.network/" className="circle" target="_blank" title="Dfyn">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dfyn.svg" alt="Dfyn" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/balancer/" className="circle" target="_self" title="Balancer">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/balancer.svg" alt="Balancer" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://jetswap.finance/" className="circle" target="_blank" title="JetSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/jetswap.svg" alt="JetSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://app.firebird.finance/" className="circle" target="_blank" title="Firebird Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/firebird.svg" alt="Firebird Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://iron.finance/" className="circle" target="_blank" title="IronSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/ironswap.svg" alt="IronSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://dmm.exchange/#/swap" className="circle" target="_blank" title="Kyber DMM">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber DMM" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.polydex.fi/?referral=ECYXKN4Fm9vnbjeHnPtv61rUYsw" className="circle" target="_blank" title="Polydex">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/polydex.svg" alt="Polydex" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swap.wault.finance/polygon/#/swap" className="circle" target="_blank" title="Wault Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/waultswap.svg" alt="Wault Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://mstable.org/" className="circle" target="_blank" title="Mstable">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/mstable.svg" alt="Mstable" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/dodo/" className="circle" target="_self" title="DODO">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dodo.svg" alt="DODO" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://dfx.finance/" className="circle" target="_blank" title="DFX Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dfx.svg" alt="DFX Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/uniswap/" className="circle" target="_self" title="Uniswap v3">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/uniswap.svg" alt="Uniswap v3" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://synapseprotocol.com/landing" className="circle" target="_blank" title="Synapse">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synapse.svg" alt="Synapse" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/limit-order-protocol/" className="circle" target="_self" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.unifiprotocol.com/" className="circle" target="_blank" title="Unifi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/unifi.svg" alt="Unifi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://clippercoin.com/" className="circle" target="_blank" title="Clipper">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/clipper.svg" alt="Clipper" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://apeswap.finance/" className="circle" target="_blank" title="ApeSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/apeswap.svg" alt="ApeSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://polycat.finance/" className="circle" target="_blank" title="PolycatFinance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/polycat.svg" alt="PolycatFinance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.hashflow.com/" className="circle" target="_blank" title="Hashflow">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/hashflow.svg" alt="Hashflow" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://gravityfinance.io/home" className="circle" target="_blank" title="Gravity">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/gravity.svg" alt="Gravity" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.safeswap.online/" className="circle" target="_blank" title="safeSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/safeswap.svg" alt="safeSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://nerve.fi/" className="circle" target="_blank" title="Nerve Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/nerve.svg" alt="Nerve Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.radioshack.org/" className="circle" target="_blank" title="RadioShack">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/radioshack.svg" alt="RadioShack" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.dystopia.exchange/" className="circle" target="_blank" title="Dystopia">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dystopia.svg" alt="Dystopia" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://meshswap.fi/" className="circle" target="_blank" title="Meshswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/meshswap.svg" alt="Meshswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.mav.xyz/" className="circle" target="_blank" title="Maverick">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/maverick.svg" alt="Maverick" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.swaap.finance/" className="circle" target="_blank" title="Swaap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swaap.svg" alt="Swaap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://mm.finance/" className="circle" target="_blank" title="mmfinance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/mmfinance.svg" alt="mmfinance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.zkbob.com/" className="circle" target="_blank" title="zkBob">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/zkbob.svg" alt="zkBob" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">Optimism Network</h3>
            <div id="optimism-content" className="tab-content d-flex flex-wrap gap-4">
                        <a href="/limit-order-protocol/" className="circle" target="_blank" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://synthetix.io/" className="circle" target="_blank" title="Syntethix">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synthetix.svg" alt="Syntethix" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/uniswap/" className="circle" target="_blank" title="Uniswap v3">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/uniswap.svg" alt="Uniswap v3" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_blank" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://clippercoin.com/" className="circle" target="_blank" title="Clipper">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/clipper.svg" alt="Clipper" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/balancer/" className="circle" target="_blank" title="Balancer">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/balancer.svg" alt="Balancer" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://app.velodrome.finance/" className="circle" target="_blank" title="Velodrome">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/velodrome.svg" alt="Velodrome" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://kyber.network" className="circle" target="_blank" title="Kyber">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/aave/" className="circle" target="_blank" title="aave">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/aave.svg" alt="aave" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_blank" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">Arbitrum</h3>
            <div id="arbitrum-content" className="tab-content d-flex flex-wrap gap-4">
                        <a href="/limit-order-protocol/" className="circle" target="_self" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/uniswap/" className="circle" target="_self" title="Uniswap v3">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/uniswap.svg" alt="Uniswap v3" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/balancer/" className="circle" target="_self" title="Balancer">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/balancer.svg" alt="Balancer" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/dodo/" className="circle" target="_self" title="DODO">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dodo.svg" alt="DODO" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_self" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swapr.eth.link/#/swap?chainId=1" className="circle" target="_blank" title="Swapr">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swapr.svg" alt="Swapr" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_self" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://gambit.financial/" className="circle" target="_blank" title="Gambit Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/gambit.svg" alt="Gambit Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://synapseprotocol.com/landing" className="circle" target="_blank" title="Synapse">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synapse.svg" alt="Synapse" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://saddle.finance/" className="circle" target="_blank" title="Saddle Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/saddle.svg" alt="Saddle Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.hashflow.com/" className="circle" target="_blank" title="Hashflow">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/hashflow.svg" alt="Hashflow" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://kyber.network" className="circle" target="_blank" title="Kyber">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/aave/" className="circle" target="_self" title="aave">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/aave.svg" alt="aave" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://excalibur.exchange/#" className="circle" target="_blank" title="Camelot">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/camelot.svg" alt="Camelot" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://traderjoexyz.com/#/home" className="circle" target="_blank" title="TraderJoe">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/traderjoe.svg" alt="TraderJoe" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://swapfish.fi/" className="circle" target="_blank" title="SwapFish">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swapfish.svg" alt="SwapFish" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://clippercoin.com/" className="circle" target="_blank" title="Clipper">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/clipper.svg" alt="Clipper" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
           
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">Avalanche</h3>
            <div id="avalanche-content" className="tab-content d-flex flex-wrap gap-4">
                        <a href="/limit-order-protocol/" className="circle" target="_self" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/aave/" className="circle" target="_self" title="Aave">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/aave.svg" alt="Aave" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://baguette.exchange/" className="circle" target="_blank" title="Baguette">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/baguette.svg" alt="Baguette" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://canary.exchange/" className="circle" target="_blank" title="Canary">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/canary.svg" alt="Canary" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_self" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://dmm.exchange/#/swap" className="circle" target="_blank" title="Kyber DMM">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber DMM" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.lydia.finance/" className="circle" target="_blank" title="Lydia">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/lydia.svg" alt="Lydia" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://avax.olive.cash/" className="circle" target="_blank" title="OliveSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/oliveswap.svg" alt="OliveSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://pangolin.exchange/" className="circle" target="_blank" title="Pangolin">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/pangolin.svg" alt="Pangolin" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_self" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://traderjoexyz.com/#/home" className="circle" target="_blank" title="TraderJoe">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/traderjoe.svg" alt="TraderJoe" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.yetiswap.app/" className="circle" target="_blank" title="Yetti">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/yetti.svg" alt="Yetti" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://gambit.financial/" className="circle" target="_blank" title="Gambit Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/gambit.svg" alt="Gambit Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://thorus.fi/" className="circle" target="_blank" title="Thorus">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/thorus.svg" alt="Thorus" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://hakuswap.com/" className="circle" target="_blank" title="Hakuswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/hakuswap.svg" alt="Hakuswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <div className="circle">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/platypus.svg" alt="Platypus" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </div>
                        <a href="https://www.hashflow.com/" className="circle" target="_blank" title="Hashflow">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/hashflow.svg" alt="Hashflow" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.axial.exchange/ " className="circle" target="_blank" title="Axial">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/axial.svg" alt="Axial" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elasticswap.org" className="circle" target="_blank" title="ElasticSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elastic_swap.svg" alt="ElasticSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://nerve.fi/" className="circle" target="_blank" title="Nerve Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/nerve.svg" alt="Nerve Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.radioshack.org/" className="circle" target="_blank" title="RadioShack">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/radioshack.svg" alt="RadioShack" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.swapsicle.io/ru" className="circle" target="_blank" title="swapsicle">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/swapsicle.svg" alt="swapsicle" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/dodo/" className="circle" target="_self" title="DODO">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/dodo.svg" alt="DODO" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
            <h3 style={{marginBottom:"30px", marginTop:"30px"}} className="tab ">Fantom</h3>
            <div id="fantom-content" className="tab-content d-flex flex-wrap gap-4">
                        <a href="/limit-order-protocol/" className="circle" target="_self" title="1inch Limit Order Protocol">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/1inch.svg" alt="1inch Limit Order Protocol" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.spiritswap.finance/" className="circle" target="_blank" title="SpiritSwap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/spiritswap.svg" alt="SpiritSwap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://protofi.app/" className="circle" target="_blank" title="ProtoFi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/protofi.svg" alt="ProtoFi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://beets.fi/#/" className="circle" target="_blank" title="Beethovenx">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/beets.svg" alt="Beethovenx" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://www.morpheusswap.app/" className="circle" target="_blank" title="Morpheus Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/morpheusswap.svg" alt="Morpheus Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://paintswap.finance/" className="circle" target="_blank" title="Paint Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/paintswap.svg" alt="Paint Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://solidly.exchange/" className="circle" target="_blank" title="Solidly">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/solidly.svg" alt="Solidly" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://geist.finance/" className="circle" target="_blank" title="Geist Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/geist_finance.svg" alt="Geist Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://scream.sh/" className="circle" target="_blank" title="Scream">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/scream.svg" alt="Scream" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://spookyswap.finance/" className="circle" target="_blank" title="Spooky Swap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/spookyswap.svg" alt="Spooky Swap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/curve/" className="circle" target="_self" title="Curve">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/curve.svg" alt="Curve" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://elk.finance/" className="circle" target="_blank" title="ELK">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/elk.svg" alt="ELK" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://saddle.finance/" className="circle" target="_blank" title="Saddle Finance">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/saddle.svg" alt="Saddle Finance" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="/aggregation-protocol/sushiswap/" className="circle" target="_self" title="Sushiswap">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/sushiswap.svg" alt="Sushiswap" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://synapseprotocol.com/landing" className="circle" target="_blank" title="Synapse">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/synapse.svg" alt="Synapse" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://fi.woo.org/" className="circle" target="_blank" title="Woofi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/woofi.svg" alt="Woofi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://yoshi.exchange/" className="circle" target="_blank" title="Yoshi">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/yoshi.svg" alt="Yoshi" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://kyber.network" className="circle" target="_blank" title="Kyber">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/kyber.svg" alt="Kyber" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                        <a href="https://equalizer.finance/" className="circle" target="_blank" title="Equalizer">
                            <img src=" https://1inch.io/img/aggregationProtocol/icons/equalizer.svg" alt="Equalizer" loading="lazy"/>
                            <div className="circle-border"></div>
                            <div className="circle-border-2"></div>
                        </a>
                <div className="bg-gradient"></div>
            </div>
           
          
           
        </div>
      </section>
    </>
  );
};

export default LiquidityExchange;
