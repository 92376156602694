import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Content() {
  return (
    <div className="container-fluid" style={{ color: "black" }}>
      <div
        className="row d-flex justify-content-center "
        style={{ paddingLeft: "15px" }}
      >
        <div className="col-md-10 col-xl-10 col-sm-10 col-lg-10">
          <h1>Capitron project Overview</h1>
          <p className="lead">
            <i>Overview of the crypto market</i>
          </p>
          <p>
            Decentralized finance, or DeFi, has emerged as a rapidly growing
            sector within the cryptocurrency ecosystem. DeFi platforms are built
            on top of decentralized networks such as Ethereum and offer a range
            of financial services such as lending, borrowing, and trading.
            However, the existing DeFi exchanges suffer from high transaction
            fees, slow performance, and limited trading pairs. Capitron aims to
            address these issues by providing a decentralized exchange platform
            with zero transaction fees, fast performance, and support for a wide
            range of cryptocurrencies.
          </p>
          <div className="row">
            <div className="card mt-5 p-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <a href="/">
                      <AiOutlineArrowLeft />
                      &nbsp;Home Page
                    </a>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <Link to="/docs/aboutus">
                      Next Page &nbsp;
                      <AiOutlineArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
