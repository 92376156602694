import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Terminologies() {
  return (
    <>
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-10">
            <h1>Terminologies</h1>
            <div>
              <ul>
                <li>
                  Swap: Exchange crypto currencies within the same blockchain.
                </li>
                <li>XSwap: Swap crypto currencies across multiple blockchains.</li>
                <li>Cash: Convert crypto currencies to cash.</li>
                <li>
                  NSwap: Swap native currencies between different blockchains.
                </li>
                <li>
                  NCash: Convert native crypto currencies such as BTC to cash.
                </li>
                <li>
                  HSwap: Exchange crypto currencies across different blockchains
                  from any wallet.
                </li>
                <li>
                  HNSwap: Swap native currencies across different blockchains from
                  any wallet.
                </li>
                <li>HCash: Convert crypto currencies to cash from any wallet.</li>
              </ul>
            </div>
            <div className='row'>

              <div className="card mt-3 mb-3 p-0">
                <div className="card-body">
                  <div className='row'>
                    <div className='col-6'>
                      <Link to="/docs/capitronProducts"><AiOutlineArrowLeft />&nbsp;Capitron Products</Link>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                      <Link to="/docs/roadmap">Next Page &nbsp;<AiOutlineArrowRight /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
