import React, { useState , useEffect, useRef } from 'react'
import { useWeb3Wallet } from '../utils/web3wallet.context'
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import "../swap/swap.css"
import Qrnswap from "../QR_Integration/Qr_Nswap"
import Qrxswap from "../QR_Integration/Qr_Xswap"
import Qr_cash from "../QR_Integration/Qr_cash"

export default function Tron({ chainLists, chainName , Networks , provider}) {
    const {  account } = useWeb3Wallet();
    const web3 = new Web3(provider);
    const [componentRender, setComponentRender] = useState(0);
    const [coinBalance , setCoinBalance] = useState()

    const hswapref = useRef()
    const hxswapref = useRef()
    const hcashref = useRef()

    

    const location = useLocation()
  

    useEffect(()=>{
        if(location.state?.tab == 'hswap'){
            hswapref.current.click()
        } else if(location.state?.tab == 'hxswap'){
            hxswapref.current.click()
        } else if(location.state?.tab == 'hcash'){
            hcashref.current.click()
        }
    }, [])
   
  

    return (
        <>
            <div className='container-fluid pt-4 d-flex justify-content-center' style={{minHeight :"100vh"}}>

               
                <div className='col-sm-12 col-md-8 col-lg-5 swap-form-container'>
                <p className='extra-text'>Zero fees on swapping and earn up to 2% Capitron coin(CPRN) on every trade.</p>
                    <ul style={{ border: 'none' }} className="nav nav-tabs" id="myTab" role="tablist">
                        <li style={{ zIndex: 3 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(0)} ref={hswapref} className="nav-link px-4 active" id="swap-tab" data-bs-toggle="tab" data-bs-target="#swap" type="button" role="tab" aria-controls="swap" aria-selected="true">HSwap</button>
                        </li>
                        <li style={{ zIndex: 2 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(1)} ref={hxswapref} className="nav-link px-4" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">HNswap</button>
                        </li>
                        <li style={{ zIndex: 1 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(2)} ref={hcashref} className="nav-link px-4" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">HCash</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="swap" role="tabpanel" aria-labelledby="swap-tab">
                            {
                                componentRender === 0 && <Qrxswap/>
                            }
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {
                                componentRender === 1 && <Qrnswap  />
                            }
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            {
                                componentRender === 2 && <Qr_cash />
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
