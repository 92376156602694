import React from 'react'
import Liquid from "../../assets/LIQUIDIY CEMTRE IMAGE (1).png"
import "./Liquidity.css"
import { Link } from 'react-router-dom'

export default function Liquidity() {
  return (
    <>
      <div className='container-fluid liquidity-bg sec2-web'>
        <div className='row d-flex justify-content-center'>
          <div className='col-xl-9 col-lg-10 col-md-10 col-sm-10'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <div className="card  liq-card">
                  <div className="card-body">
                    <p className='liq-heading'>Never ending <br /> <span className='liq'>liquidity </span></p>
                    <p className='text-white-50 '>Capitron instantly analyzes thousands of quotes and fees across multiple DEXes to provide users with the best rates.</p>
                    <Link to = "/swap" className='liq-button'>Swap  <i className='fas fa-arrow-right'></i></Link>
                  </div>
                </div>

              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <img src={Liquid} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid liquidity-bg mobile-sec'>
        <div className='row d-flex justify-content-center'>
          <div className='col-xl-9 col-lg-10 col-md-10 col-sm-10'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <img src={Liquid} className="img-fluid" />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center'>
                <div className="card  liq-card">
                  <div className="card-body">
                    <p className='liq-heading'>Never ending <br /> <span className='liq'>liquidity </span></p>
                    <p className='text-white-50 '>Capitron's sophisticated algorithms rapidly compare thousands of quotes and fees across multiple DEXes, delivering the best rates instantly.</p>
                    <button className='liq-button'>Swap  <i className='fas fa-arrow-right'></i></button>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
