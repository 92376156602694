import React from 'react'
import TrustWalletIcon from "../../../assets/trustwallet1.png"
import MetaMaskIcon from "../../../assets/metamask1.png"
import TronLinkIcon from "../../../assets/tronlink1.png"
import "./Partners.css"

export default function PartnersStackholder() {
    return (
        <div className='container-fluid sec3'>
         
            <div className='row d-flex justify-content-center mt-0'>
                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-11 col-10'>
                    <span className='d-flex justify-content-center sec3-text3'>Partners and StackHolders</span>
                    {/* <span className='d-flex justify-content-center sec3-_text3'></span> */}
                </div>
            </div>
            <div className=' row d-flex justify-content-center mt-5'>
                <div className='col-xl-8 col-lg-8 col-md-10 col-sm-6 col-12'>
                    <div className=' d-flex flex-column flex-md-row justify-content-between'>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6'>
                            <img src={TrustWalletIcon} alt="trustWallet"/>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6 '>
                            <img src={MetaMaskIcon} alt="metamask"/>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-2 col-6'>
                            <img src={TronLinkIcon} alt="tronlink"/>
                        </div>
                      
                    </div>
                    {/* <div className='row  d-flex justify-content-center mt-5'>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Avalanche} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Arbitrum} />
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
                            <img src={Fantom} />
                        </div>

                    </div> */}
                </div>
            </div>
        </div>
    )
}
