import React from 'react'
import "./contactForm.css"
import Address from "../../assets/Contact_ic/ic_Address.png";
import Email from "../../assets/Contact_ic/ic_email.png";
import Phone from "../../assets/Contact_ic/ic_phone call.png";


export default function contactForm() {

    return (
        <>

            {/* <!-- Button trigger modal --> */}
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button>

            {/* <!-- Modal --> */}
            <div className="modal fade  " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content capitron-contact w-100">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Contact Us</h5>
                            <button data-bs-dismiss="modal" style={{ border: "none", background: "transparent" }}><i className="fa fa-close" style={{ color: "white" }}></i></button>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> close</button> */}
                        </div>
                        <div className="modal-body pt-5">
                            <div className='container-fluid'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 '>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10'>
                                            <form>
                                                <div className="form-input">
                                                    <div className="input-form">

                                                        <input
                                                            className="input-form-text w-100"
                                                            type="text"
                                                            id="fname"
                                                            name="fname"
                                                            placeholder="Your Name"
                                                        />
                                                    </div>
                                                    <br />
                                                    <div className="input-form">

                                                        <input className="input-form-text w-100" type="email" id="lname" name="lname" placeholder="Your Email " />
                                                    </div>
                                                    <br />
                                                    <div className="input-form">

                                                        <input className="input-form-text w-100" type="number" id="lname" name="lname" placeholder='Your Contact Number' />
                                                    </div>
                                                    <br />
                                                    <div className="input-form">

                                                        <textarea className="input-form-text w-100" rows="2" placeholder='Write Your Message'></textarea><br />
                                                    </div>
                                                    <br />
                                                    <div className="input-form1 d-flex justify-content-center">
                                                        <input className="submit-button " type="submit" value="Submit" />
                                                    </div>
                                                </div>
                                            </form>
                                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}
