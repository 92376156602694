import React from 'react'
import Arbitrum from "../../assets/network icons/Arbitrum.png"
import Avalanche from "../../assets/network icons/Avalanche.png"
import BNBChain from "../../assets/network icons/BNB Chain.png"
import ethereum from "../../assets/network icons/ethereum.png"
import Fantom1 from "../../assets/network icons/Fantom (1).png"
import Fantom from "../../assets/network icons/Fantom.png"
import Gnosis1 from "../../assets/network icons/Gnosis (1).png"
import Gnosis from "../../assets/network icons/Gnosis.png"
import Optimism from "../../assets/network icons/Optimism.png"
import Polygon from "../../assets/network icons/Polygon.png"
import "./Section3.css"
import Section4 from '../Hero/Section4/Section4'

export default function Section3() {
  return (
    <div className='container-fluid sec3'>
      {/* <div className='row d-flex justify-content-center'>
        <div className='col-xl-9 col-lg-9 col-md-8 col-sm-8'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-8 col-sm-8 p-0 d-flex justify-content-end'>
              <div className='sec3-box mt-3'>
                <span className='d-flex justify-content-center sec3-text'>CAPITRON IS A </span>
                <span className='d-flex justify-content-center sec3-text'>GROWING MARKET</span>
                <span className='d-flex justify-content-center'>Capitron is a Growing Network</span>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-4 col-sm-4 p-0 mt-4'>
              <div className='row d-flex justify-content-center'>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>345</span>
                    <span className='d-flex justify-content-center'>Liquidity Sources</span>
                  </div>

                </div>
              </div>
              <div className="row mt-2">
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>$281B+</span>
                    <span className='d-flex justify-content-center'>Total Volume</span>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>4.4M+</span>
                    <span className='d-flex justify-content-center'>Total Wallets</span>
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center mt-2'>
                <div className='col-xl-6'>
                  <div className='box2'>
                    <span className='d-flex justify-content-center sec3-text2'>28.7M+</span>
                    <span className='d-flex justify-content-center'>Total Trades</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='row d-flex justify-content-center mt-5'>
        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-11 col-10'>
          <span className='d-flex justify-content-center sec3-text3'>Optimize your trades across </span>
          <span className='d-flex justify-content-center sec3-_text3'> hundreds of DEXes on multiple networks</span>
        </div>
      </div>
      <div className='row d-flex justify-content-center mt-5'>
        <div className='col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12'>
          <div className='row d-flex justify-content-center'>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={ethereum} />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={BNBChain} />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Polygon} />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Optimism} />
            </div>
           
            {/* <div className='col-xl-2  col-lg-2 col-md-4 col-sm-3 col-6'>
              <img src={Gnosis} />
            </div> */}
          </div>
          <div className='row  d-flex justify-content-center mt-5'>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Avalanche} />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Arbitrum} />
            </div>
            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 d-flex justify-content-center'>
              <img src={Fantom} />
            </div>
            {/* <div className='col-xl-2 col-lg-2 col-md-4 col-sm-3 col-6'>
              <img src={Fantom} />
            </div> */}
            {/* <div className='col-xl-2 col-lg-2 col-md-4 col-sm-3 col-6'>
              <img src={Fantom1} />
            </div> */}
            {/* <div className='col-xl-2 col-lg-2 col-md-4 col-sm-3 col-6'>
              <img src={Gnosis1} />
            </div> */}
          </div>
        </div>
      </div>
      <Section4/>
    </div>
  )
}
