import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";

const option = new WalletConnectProvider({
  rpc: {
    56 : 'https://endpoints.omniatech.io/v1/bsc/mainnet/public', // BSC
    1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_IPFS}` , //Etherium
    10 : `https://optimism-mainnet.infura.io/v3/${process.env.REACT_APP_IPFS}`,  //Optimism
    43114 : `https://avalanche-mainnet.infura.io/v3/${process.env.REACT_APP_IPFS}`,  //Avalanche Network C-Chain
    250: "https://rpc.ftm.tools/",  //Fantom Opera
    137: `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_IPFS}`,  //Polygon Mainnet
    42161 : `https://arbitrum-mainnet.infura.io/v3/${process.env.REACT_APP_IPFS}`  //Arbitrum One
  },
  infuraId: process.env.REACT_APP_IPFS,
});
export const providerOptions = {
 
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    
    options: {
      appName: "Web 3 Modal Demo", // Required
     
      infuraId: process.env.REACT_APP_IPFS
      
    }
    
  },
  walletconnect: {
    package: WalletConnect, // required
    options: option
  },
  
};

