import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Trx from "../../assets/trx.png";
import Tronlink from "../../assets/New folder/tronlink (2) (1).png"
import Xdefi from "../../assets/New folder/xdefi (1).png"
import Web3 from 'web3';
import { Link } from 'react-router-dom'
import $ from "jquery";
import API_URL from '../../Api/apiUrl';
import { useWeb3Wallet } from '../../utils/web3wallet.context';
import Logo from "../../assets/CAPITRON logoD (13) 1.png"
import { RxCrossCircled } from "react-icons/rx";
import './TronNavbar.css';
import axios from 'axios';

export default function TronNavbar() {
    const notiFunction = () => {
        document.getElementById("notibar").style.display = "none"
     }
    return (
        <>
            <div className='p-0 noti-bar' id="notibar" style={{display:'flex', justifyContent:'space-between'}}>
                <div></div>
                <div><span>Contact support if you are facing any issues</span></div>
                <div><span><RxCrossCircled onClick={notiFunction} /></span></div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light swap-navbar-container mb-3">
                <div className="container-fluid">
             
                    <img src={Logo} style={{ width: '60px', height: '60px' }} alt="Logo" />
                    <h2 className='swap-navbar-logo'>Capitron</h2>
                    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse swap-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-5 gap-5">
                            <li className="nav-item swap-nav">
                                <Link to = "/" className="nav-link active" aria-current="page" >Home</Link>
                            </li>
                            <li className="nav-item swap-nav">
                        <div className="dropdown">
                <a className=" dropdown-toggle nav-link  active " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", paddingTop: "8px", color: "black", textDecoration: "none",fontSize:"18px" ,fontWeight:"600" }}>
                  Products
                </a>

                <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink" style={{background : "#01020E" , border : "1px solid #6100FF" }}>
                  <li ><a className="dropdown-item prod-list" href="/swap">Defi Swap </a></li>
                  <li ><a className="dropdown-item prod-list" href="/nativeswap">Native Swap </a></li>
                  <li ><a className="dropdown-item prod-list" href="/hybridswap">Hybrid Swap </a></li>
                </ul>
                        </div>
                        </li>
                            <li className="nav-item swap-nav">
                                <Link to = "/blog" className="nav-link  active" aria-current="page">Blog</Link>
                            </li>
                            <li className="nav-item swap-nav">
                                <Link to="/docs/terminologies" target='_blank' className="nav-link  active" aria-current="page">Terminologies</Link>
                            </li>
                        </ul>


                       
                    </div>
                </div>
            </nav>



           
         
        </>
    )
}
