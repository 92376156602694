import { useEffect, useState } from 'react';
import nProgress from 'nprogress';
import { useLocation } from 'react-router-dom'; // not needed for nextjs
import useMounted from './use-mounted';

// export function useNprogress() {
//   const isMounted = useMounted();
//   const pathname = usePathname();
//   const [visible, setVisible] = useState(false);

//   useEffect(() => {
//     if (!visible) {
//       nProgress.start();
//       setVisible(true);
//     }

//     if (visible) {
//       nProgress.done();
//       setVisible(false);
//     }

//     if (!visible && isMounted()) {
//       setVisible(false);
//       nProgress.done();
//     }

//     return () => {
//       nProgress.done();
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname, isMounted]);
// }



const ProgressBar = (props) => {
  props = {
    color: 'red',
    height: '2px',
    spinner: '20px',
    ...props
  };

  const mounted = useMounted();
  const { pathname } = useLocation(); // assumes react router v6
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      nProgress.start();
      setVisible(true);
    }
    if (visible) {
      nProgress.done();
      setVisible(false);
    }
    if (!visible && mounted) {
      setVisible(false);
      nProgress.done();
    }
    return () => {
      nProgress.done();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, mounted]);


  const styles = `
      #nprogress {
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 100
      }
     #nprogress .bar {
        background: ${props.color};
        height: ${props.height};
     }
     #nprogress .peg {
        box-shadow: 0 0 10px ${props.color}, 0 0 5px ${props.color};
     }
     #nprogress .spinner-icon {
        width: ${props.spinner};
        height: ${props.spinner};
        border-top-color: ${props.color};
        border-left-color: ${props.color};
     }
  `;

  return (
    <>
      <style>{styles}</style>
    </>
  );
};
export default ProgressBar;