import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <>
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-10">
            <h1>About us</h1>
            <div>
              <p className="text-wrap">
                Capitron is a decentralized exchange platform that supports the
                seamless swap of cryptocurrencies with zero transaction fees.
                Capitron offers a user-friendly and secure environment for
                traders to exchange their cryptocurrencies with fast and
                reliable performance. In addition to traditional
                crypto-to-crypto Capitron Exchange also provide swaps, multi-chain swaps, cash swap, Native
                swap and Hybrid Swap.
              </p>
            </div>
            <div className="row">
              <div className="card mt-3 mb-3 p-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <Link to="/docs/content">
                        <AiOutlineArrowLeft />
                        &nbsp;Project Overview
                      </Link>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Link to="/docs/capitronProducts">
                        Next Page &nbsp;
                        <AiOutlineArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
