import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function CapitronProduct() {
  return (
    <>
      <div className="container-fluid" style={{ color: "black" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 col-10">

            <h1>Capitron products</h1>
            <h3>Swap</h3>
            <p>
              Capitron's same-chain swap protocol allows users to swap
              cryptocurrencies within the same blockchain network. For example,
              users can swap ETH for DAI within the Ethereum network. This
              protocol offers a fast and efficient way to exchange
              cryptocurrencies without having to leave the blockchain network.
            </p>
            <h3>XSwap</h3>
            <p>
              Capitron's cross-chain swap protocol, XSwap, enables users to swap
              cryptocurrencies between different blockchain networks. For example,
              users can swap BTC for ETH or vice versa. This protocol eliminates
              the need for centralized exchanges and provides users with more
              options for trading cryptocurrencies.
            </p>
            <h3>Cash</h3>
            <p>
              Capitron's swap-to-cash protocol, Cash protocol, allows users to
              easily convert their cryptocurrencies to cash. This protocol is
              ideal for users who want to quickly and easily liquidate their
              cryptocurrency holdings. Cash protocol provides a safe and secure
              way to convert cryptocurrencies to cash, with low fees and fast
              transaction times.
            </p>
            <h3>NSwap</h3>
            <p>
              Capitron's native-crypto swap protocol, NSwap, allows users to swap
              native cryptocurrencies like BTC or TRX within their respective
              networks. This protocol is designed for users who want to trade
              cryptocurrencies directly within their native blockchain networks,
              without having to go through centralized exchanges.
            </p>
            <h3>HSwap</h3>
            <p>
              Capitrons Hybrid swap allows users to swap their crypto coins using
              any crypto currency wallet they prefer, ensuring maximum convenience
              and ease of use.
            </p>
            <div className='row'>

              <div className="card mt-3 mb-3 p-0">
                <div className="card-body">
                  <div className='row'>
                    <div className='col-6'>
                      <Link to ="/docs/aboutus"><AiOutlineArrowLeft />&nbsp;About Us</Link>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                      <Link to ="/docs/terminologies">Next Page &nbsp;<AiOutlineArrowRight /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
