import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import Trx from "../../assets/trx.png";
import Tronlink from "../../assets/New folder/tronlink (2) (1).png"
import Xdefi from "../../assets/New folder/xdefi (1).png"
import Web3 from 'web3';
import { Link } from 'react-router-dom'
import $ from "jquery";
import API_URL from '../../Api/apiUrl';
import { useWeb3Wallet } from '../../utils/web3wallet.context';
import Logo from "../../assets/CAPITRON logoD (13) 1.png"
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineDisconnect } from "react-icons/ai";
import './TronNavbar.css';
import axios from 'axios';

export default function TronNavbar({ nativeNet, disconnectNetwork }) {
    const { connectTron, disConnectTron, tronAccount, xdefiDoge, xdefiBtc, btcWallets, dogeWallets, disConnectbtc,
        disConnectdoge } = useWeb3Wallet();

    const [dropdownHeight, setDropdownHeight] = useState(false);
    const [isCopied, setIsCopied] = useState(false);








    $('.icon-up').click(function () {
        $(this).toggleClass('rotate')
    })

    // copied message
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };


    const notiFunction = () => {
        document.getElementById("notibar").style.display = "none"
    }

    useEffect(() => {
        console.log( "native token names:::::::::::::::::",nativeNet);
        console.log( "tron account names:::::::::::::::::",tronAccount);
    }, [tronAccount, nativeNet])

    return (
        <>
            <div className='p-0 noti-bar' id="notibar" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <div><span>Contact support if you are facing any issues</span></div>
                <div><span><RxCrossCircled onClick={notiFunction} /></span></div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light swap-navbar-container mb-3">
                <div className="container-fluid">
                    <img src={Logo} style={{ width: '60px', height: '60px' }} alt="Logo" />
                    <h2 className='swap-navbar-logo'>Capitron</h2>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse swap-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-5 gap-5">
                            <li className="nav-item swap-nav">
                                <Link to="/" className="nav-link active" aria-current="page" >Home</Link>
                            </li>
                            <li className="nav-item swap-nav">
                                <div className="dropdown">
                                    <a className=" dropdown-toggle nav-link  active " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: "none", border: "none", paddingTop: "8px", color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600" }}>
                                        Products
                                    </a>

                                    <ul className="dropdown-menu " aria-labelledby="dropdownMenuLink" style={{ background: "#01020E", border: "1px solid #6100FF" }}>
                                        <li ><a className="dropdown-item prod-list" href="/swap">Defi Swap </a></li>
                                        <li ><a className="dropdown-item prod-list" href="/nativeswap">Native Swap </a></li>
                                        <li ><a className="dropdown-item prod-list" href="/hybridswap">Hybrid Swap </a></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item swap-nav">
                                <Link to="/blog" className="nav-link  active" aria-current="page">Blogs</Link>
                            </li>
                            <li className="nav-item swap-nav">
                                <Link to="/docs/terminologies" target='_blank' className="nav-link  active" aria-current="page">Terminologies</Link>
                            </li>

                        </ul>


                        <div className='d-flex gap-3'>
                            <div className=' pe-4 '>


                            </div>
                            {(tronAccount != undefined && tronAccount != null && tronAccount != "") ? (
                                <>
                                    <button className="swap-connect-button px-4 " style={{ height: "50px" }} onClick={(e) => {
                                        // disconnect(e);
                                        setDropdownHeight(!dropdownHeight)
                                    }}>
                                        {tronAccount
                                            ? (tronAccount.slice(0, 4) + "...." + tronAccount.substr(30))
                                            : "CONNECT WALLET"}
                                        <i className="fa fa-angle-down  icon-up" style={{ fontSize: "24px" }}></i>
                                    </button>

                                    <div style={dropdownHeight ? { height: 'auto' } : { height: '0px', padding: "0px", border: "0", display: "none" }} className='my-dropdown px-4 mt-5 flex flex-col gap-8 p-4'>
                                        <div className='my-dropdown-first flex justify-between gap-3'>

                                            <div className='my-dropdown-header text-sm font-semibold flex items-center gap-1.5 text-gray-700 dark:text-slate-200'>
                                                {tronAccount
                                                    ? (tronAccount.slice(0, 4) + ".." + tronAccount.substr(30))
                                                    : "CONNECT WALLET"}

                                            </div>
                                            <div className='my-dropdown-icon'>
                                                <CopyToClipboard text={tronAccount} onCopy={onCopyText}>
                                                    <div className="copy-area">
                                                        <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>

                                                    </div>
                                                </CopyToClipboard>


                                                {/* <a href = {`${explorerUrl}/${tronAccount}`}  target="_blank"> <i className="fa fa-link" style={{fontSize:"22px"}} title='View on Explorer'></i></a> */}
                                                <span onClick={() => { disConnectTron(); disconnectNetwork() }}><AiOutlineDisconnect title='Disconnect Wallet' style={{ fontSize: "25px" }} /></span>


                                            </div>

                                        </div>
                                        <div className='my-dropdown-second'>

                                            <div className='my-dropdown-network'>
                                                {/* <p>1235455....562</p> */}
                                            </div>
                                            <div className='my-dropdown-doller'>
                                                {/* $0.00 */}
                                            </div>

                                        </div>


                                    </div>
                                </>
                            ) : (tronAccount != undefined && tronAccount != null && tronAccount != "") ?

                                <>
                                    <button className="swap-connect-button px-4 " style={{ height: "50px" }} onClick={(e) => {
                                        // disconnect(e);
                                        setDropdownHeight(!dropdownHeight)
                                    }}>
                                        {btcWallets
                                            ? (btcWallets?.slice(0, 4) + "...." + btcWallets?.substr(35))
                                            : "CONNECT WALLET"}
                                        <i className="fa fa-angle-down  icon-up" style={{ fontSize: "24px" }}></i>
                                    </button>

                                    <div style={dropdownHeight ? { height: 'auto' } : { height: '0px', padding: "0px", border: "0", display: "none" }} className='my-dropdown px-4 mt-5 flex flex-col gap-8 p-4'>
                                        <div className='my-dropdown-first flex justify-between gap-3'>

                                            <div className='my-dropdown-header text-sm font-semibold flex items-center gap-1.5 text-gray-700 dark:text-slate-200'>
                                                {btcWallets
                                                    ? (btcWallets.slice(0, 4) + ".." + btcWallets.substr(35))
                                                    : "CONNECT WALLET"}

                                            </div>
                                            <div className='my-dropdown-icon'>
                                                <CopyToClipboard text={btcWallets} onCopy={onCopyText}>
                                                    <div className="copy-area">
                                                        <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>

                                                    </div>
                                                </CopyToClipboard>


                                                {/* <a href = {`${explorerUrl}/${tronAccount}`}  target="_blank"> <i className="fa fa-link" style={{fontSize:"22px"}} title='View on Explorer'></i></a> */}
                                                <span onClick={() => { disConnectbtc(); disconnectNetwork() }}><AiOutlineDisconnect title='Disconnect Wallet' style={{ fontSize: "25px" }} /></span>


                                            </div>

                                        </div>
                                        <div className='my-dropdown-second'>

                                            <div className='my-dropdown-network'>

                                            </div>
                                            <div className='my-dropdown-doller'>
                                                {/* $0.00 */}
                                            </div>

                                        </div>


                                    </div>
                                </> :

                                (tronAccount != undefined && tronAccount != null && tronAccount != "") ?
                                    <>
                                        <button className="swap-connect-button px-4 " style={{ height: "50px" }} onClick={(e) => {
                                            // disconnect(e);
                                            setDropdownHeight(!dropdownHeight)
                                        }}>
                                            {dogeWallets
                                                ? (dogeWallets.slice(0, 4) + "...." + dogeWallets.substr(30))
                                                : "CONNECT WALLET"}
                                            <i className="fa fa-angle-down  icon-up" style={{ fontSize: "24px" }}></i>
                                        </button>

                                        <div style={dropdownHeight ? { height: 'auto' } : { height: '0px', padding: "0px", border: "0", display: "none" }} className='my-dropdown px-4 mt-5 flex flex-col gap-8 p-4'>
                                            <div className='my-dropdown-first flex justify-between gap-3'>

                                                <div className='my-dropdown-header text-sm font-semibold flex items-center gap-1.5 text-gray-700 dark:text-slate-200'>
                                                    {dogeWallets
                                                        ? (dogeWallets.slice(0, 4) + ".." + dogeWallets.substr(30))
                                                        : "CONNECT WALLET"}

                                                </div>
                                                <div className='my-dropdown-icon'>
                                                    <CopyToClipboard text={dogeWallets} onCopy={onCopyText}>
                                                        <div className="copy-area">
                                                            <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>

                                                        </div>
                                                    </CopyToClipboard>



                                                    <span onClick={() => { disConnectdoge(); disconnectNetwork() }}><AiOutlineDisconnect title='Disconnect Wallet' style={{ fontSize: "25px" }} /></span>


                                                </div>

                                            </div>
                                            <div className='my-dropdown-second'>

                                                <div className='my-dropdown-network'>

                                                </div>
                                                <div className='my-dropdown-doller'>
                                                    {/* $0.00 */}
                                                </div>

                                            </div>


                                        </div>
                                    </>
                                    :

                                    <button
                                        className="swap-connect-button px-4 " data-bs-target="#exampleModal_navbar" data-bs-toggle="modal" style={{ height: "50px" }}>
                                        CONNECT WALLET
                                        <i className="fa fa-angle-down icon-up" style={{ fontSize: "24px" }}></i>
                                    </button>
                            }


                        </div>
                    </div>
                </div>
            </nav>



            {/* <!-- Modal --> */}
            <div >
                <div className="modal fade " id="exampleModal_navbar" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content w-75 d-flex justify-content-center">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Select Wallet</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex justify-content-center'>
                                    <button className='tron-button' data-bs-dismiss="modal" onClick={connectTron}> <img src={Tronlink} style={{ borderRadius: "10px" }} />TronLink Wallet</button>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className='xdefi-button' data-bs-dismiss="modal" onClick={xdefiBtc}> <img src={Xdefi} style={{ borderRadius: "10px" }} /> Xdefi BTC</button>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className='xdefi-button' data-bs-dismiss="modal" onClick={xdefiDoge}> <img src={Xdefi} style={{ borderRadius: "10px" }} /> Xdefi Doge</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
