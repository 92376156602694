import React from "react";
import PartnerData from "./PartnerData";

export default function Partners() {
  const data = PartnerData;
  return (
    <>
      <section>
        <h2
          className="container-fluid col-md-10 col-xl-10 col-sm-10 col-10"
          style={{
            color: "grey",
            fontWeight: 700,
            fontSize: "40px",
            marginBottom: "40px",
          }}
        >
          Our partners
        </h2>
        <article
          className=""
          style={{
            color: "black",
            display: "flex",
            flexWrap: "wrap",
            gap: "5vw",
            justifyContent: "center",
            paddingLeft: "30px",
          }}
        >
          {data.map((value) => {
            return (
              <main className="card" style={{ width: "300px" }}>
                <div>
                  <img style={{ width: "150px" }} src={value.link} />
                </div>
                <div className="card-body">{value.content}</div>
              </main>
            );
          })}
        </article>
      </section>
    </>
  );
}
