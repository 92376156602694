// import { useCallback, useEffect, useRef } from 'react';

// export const useMounted = () => {
//   const isMounted = useRef(false);

//   useEffect(() => {
//     isMounted.current = true;

//     return () => {
//       isMounted.current = false;
//     };
//   }, []);

//   return useCallback(() => isMounted.current, []);
// };


import { useEffect, useRef } from 'react';
import { useUpdate } from 'react-use';

export default function useMounted() {
  const mounted = useRef(false);
  const update = useUpdate();
  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true;
      update();
    }
  }, [update]);
  return mounted.current;
}