import React from "react";
import Hero from "../Hero/Hero";
import "./Navbar.css";
import Logo from "../../assets/CAPITRON logoD (13) 1.png";
import { Link } from "react-router-dom";
import Banner from "../../assets/banner (6) 2.png";

export default function Navbar() {
  return (
    <div className="section1">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid  nav-text mt-2">
          <a className="footer-text1" href="/">
            {" "}
            <img
              src={Logo}
              style={{ height: "40px", width: "40px", textDecoration: "none" }}
            />
            <span style={{ marginLeft: "5px" }}>CAPITRON</span>
          </a>
          {/* <p className='footer-text'>  <img src={Logo} style={{ height: "40px", width: "40px" }} />&nbsp; CAPITRON</p> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ backgroundColor: "#6100ffa8", borderRadius: "16px" }}
            ></span>
          </button>
          <div
            className="collapse navbar-collapse cap-nav"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <div className="dropdown" style={{ paddingTop: "8px" }}>
                <a
                  className=" dropdown-toggle  cap-product"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    background: "none",
                    border: "none",
                    paddingTop: "8px",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Products
                </a>

                <ul
                  className="dropdown-menu cap-menu"
                  aria-labelledby="dropdownMenuLink"
                  style={{ background: "#01020E", border: "1px solid #6100FF" }}
                >
                  <li>
                    <a className="dropdown-item prod-list" href="/hybridswap">
                      Hybrid Swap{" "}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item prod-list" href="/swap">
                      Defi Swap{" "}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item prod-list" href="/nativeswap">
                      Native Swap{" "}
                    </a>
                  </li>
                </ul>
              </div>

              {/* <li className="nav-item cap-nav">
                <a className="nav-link text-white  active" aria-current="page" href="https://capitron-exchange.gitbook.io/capitron">About</a>
              </li> */}
              <li className="nav-item cap-nav">
                <a
                  className="nav-link text-white  active"
                  aria-current="page"
                  href="https://capitron-exchange.gitbook.io/capitron/app-guide/how-to-swap"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li className="nav-item cap-nav">
                <a className="nav-link text-white  active" aria-current="page" href="https://capitron-exchange.gitbook.io/capitron">About Us</a>
              </li>
              <li className="nav-item cap-nav">
                <a className="nav-link text-white  active" aria-current="page" href="https://airdrop.capitron.exchange/">Airdrop</a>
              </li>
            </ul>

            <a
              href="https://presale.capitron.exchange/ "
              target="_blank"
            >
              <button className="buy-button">Presale</button>
            </a>
          </div>
        </div>
      </nav>
      <Hero />
    </div>
  );
}
