import React, { useState } from "react";
import "./footer.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Logo from "../../assets/CAPITRON logoD (13) 1.png";
import { BsInstagram } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsReddit } from "react-icons/bs";
import Telegram from "../../assets/footer Icons/telegram 1.png";
import API_URL from "../../Api/apiUrl";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";

export default function Footer() {
  const [loading, setLoading] = useState(false);
  const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personNumber, setPersonNumber] = useState("");
  const [query, setQuery] = useState("");

  const contactUs = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(API_URL.contactUs, {
        name: personName,
        email: personEmail,
        contactNo: Number(personNumber),
        message: query,
      })
      .then((res) => {
        // console.log(res.data.message);
        setLoading(false);
        NotificationManager.success(
          "Thank you for reaching out. Your inquiry has been successfully received, and we will be in contact with you shortly."
        );
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container-fluid f-bg ">
        <div className="row d-flex justify-content-center footer-border pt-3">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
            <div className="row mt-5">
              <div className="col-xl-3  col-lg-3 col-md-6 col-sm-6 col-12">
                <p className="footer-text">
                  {" "}
                  <img src={Logo} style={{ height: "40px", width: "40px" }} />
                  &nbsp; CAPITRON
                </p>
                <p className="text-white">
                  One-stop access
                  <br />
                  to decentralized finance!
                </p>
                <div className="col-xl-8  col-lg-10 col-md-10 col-sm-12 col-12 d-flex justify-content-between">
                  <a
                    href="https://www.instagram.com/capitronsupport/"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <BsInstagram style={{ fontSize: "30px", color: "blue" }}   />
                  </a>

                  <a
                    href="https://t.me/deficapitron"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <BsTelegram style={{ fontSize: "30px", color: "blue" }} />
                  </a>

                  <a
                    href="https://twitter.com/capitrondefi"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    {" "}
                    <BsTwitter style={{ fontSize: "30px", color: "blue" }} />
                  </a>

                  {/* <a
                    href="https://www.reddit.com/"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    {" "}
                    <BsReddit style={{ fontSize: "30px", color: "blue" }} />
                  </a> */}
                </div>
                {/* <span >
                                    <img src = {Telegram} />
                                    <img src = {Link} />
                                    <img src = {Twitter} />
                                    <img src = {Facebook} />
                                </span> */}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 foo-tab">
                <p className="footer-text">PRODUCTS</p>
                <p>
                  <a
                    className="text-white"
                    href="/swap"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Defi Swap
                  </a>{" "}
                </p>
                <p>
                  <a
                    className="text-white"
                    href="/nativeswap"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Native Swap
                  </a>{" "}
                </p>
                <p>
                  <a
                    className="text-white"
                    href="/hybridswap"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Hybrid swap{" "}
                  </a>
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <p className="footer-text">More</p>
                <p className="text-white">
                  <a
                    className="text-white"
                    href="https://capitron-exchange.gitbook.io/capitron"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Whitepaper
                  </a>
                </p>
                <p>
                  <a
                    className="text-white"
                    href="https://coinmarketcap.com/dexscan/bsc/0x082246604a62f13c675d5c77cac63021be8eb1f2/"
                    target="_blank"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    CoinMarketCap{" "}
                  </a>
                </p>
                {/* <p className='text-white'>Capitron</p> */}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <p className="footer-text"> SUPPORT</p>
                <p className="text-white">Help Centre</p>
                {/* <p>
                  {" "}
                  <a
                    href
                    className="text-white"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Press Room
                  </a>
                </p> */}
                <button
                  type="button"
                  className="contact-button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Contacts Us
                </button>

                {/* <p className='text-white'></p> */}
              </div>
            </div>
            <hr style={{ height: "2px", color: "#6100FF" }} />
            <div className="d-flex justify-content-between">
              <div>
                <p className="text-white">support@capitron.exchange</p>
                <p className="text-white">
                  All Rights Reserved © 2024 Capitron
                </p>
              </div>
              {/* <div>
                <a
                  className="text-white text-decoration-none d-none d-sm-block"
                  href="https://bscscan.com/token/0xba34debddd1dfd9adf1df65daf8364bd2926287a?a=0xE585DDA48821CC7878D6328b4036f910d8b468a3"
                  target="_blank"
                >
                  Capitron contract address :
                  0xba34DeBDDD1DFD9AdF1Df65Daf8364BD2926287A
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content capitron-contact">
            <div className="position-relative">
              <div
                className="position-absolute "
                style={{
                  top: "-125px",
                  right: "40px",
                }}
              >
                <RxCross1  data-bs-dismiss="modal"/>
              </div>
              <div className="w-100 modal-heading">
                <h5
                  className="modal-title d-flex justify-content-center w-100 fw-bolder fs-3"
                  id="staticBackdropLabel"
                >
                  Contact Us
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: "none", background: "transparent" }}
                ></button>
              </div>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"> close</button> */}
            </div>
            <div className="modal-body pt-5">
              <div className="container-fluid">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                  <div className="row d-flex justify-content-center">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                      <form onSubmit={contactUs}>
                        <div className="form-input">
                          <div className="input-form">
                            <input
                              className="input-form-text w-100"
                              type="text"
                              onChange={(e) => setPersonName(e.target.value)}
                              placeholder="Your Name"
                            />
                          </div>
                          <br />
                          <div className="input-form">
                            <input
                              className="input-form-text w-100"
                              type="email"
                              onChange={(e) => setPersonEmail(e.target.value)}
                              placeholder="Your Email "
                            />
                          </div>
                          <br />
                          <div className="input-form">
                            <input
                              className="input-form-text w-100"
                              type="number"
                              onChange={(e) => setPersonNumber(e.target.value)}
                              placeholder="Your Contact Number"
                            />
                          </div>
                          <br />
                          <div className="input-form">
                            <textarea
                              className="input-form-text w-100"
                              style={{ resize: "none" }}
                              rows="2"
                              onChange={(e) => setQuery(e.target.value)}
                              placeholder="Write Your Message"
                            ></textarea>
                            <br />
                          </div>
                          <br />
                          <div className="input-form1 d-flex justify-content-center">
                            <input
                              className="submit-button "
                              style={{
                                position: "absolute",
                                marginTop: "45px",
                              }}
                              type="submit"
                              value="Submit"
                            />
                          </div>
                        </div>
                      </form>
                      {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}
