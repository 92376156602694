import React, { useState, useRef, useEffect } from 'react'

import { walletAddressMetamask , walletAddressTron , walletAddressDoge  , walletAddressBtc }  from "../utils/commonConstant"
import { useWeb3Wallet } from '../utils/web3wallet.context'

// import { successPopup } from '../utils/PopUpMessage'
import { CopyToClipboard } from "react-copy-to-clipboard";
import MetmaskQr from "../assets/Qr code/Metmask.jpeg"
import { ethers } from 'ethers'
import API_URL from '../Api/apiUrl'
import axios from 'axios'
import Web3 from 'web3';
import "../swap/swap.css"
import ETHLogo from '../assets/eth-logo.png'
import EthIcon from "../assets/network icons/ethereum.png"
import SwapArrow from '../assets/Swap-arrow.png'
import ArbithumIcon from "../assets/network icons/Arbitrum.png"
import KlayIcon from "../assets/network icons/Fantom (1).png"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BTCqr from "../assets/Qr code/btcnew.jpeg"
import Trxqr from "../assets/Qr code/Tron.jpeg"
import Dogeqr from "../assets/Qr code/Dogecoin.jpeg"
import PaymentStatus from "../assets/payment-processing.gif"
import PaymentWaiting from "../assets/payementWaiting.gif"

export default function CashForm() {

    const { connectWallet, disconnect, account } = useWeb3Wallet();
    const [emailValid, setEmailValid] = useState()
    const [emailValidPay, setEmailValidPay] = useState()
    const [emailValidEuro, setEmailValidEuro] = useState()
    const [country, setCountry] = useState("usa")
    const [swiftCode, setSwiftCode] = useState()
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("")
    const [bankName, setBankName] = useState("");
    const [postalCode, setPostalCode] = useState("")
    const [accountHolderName, setAccountHolderName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [zipcode, setZipCode] = useState()
    const [iban, setIban] = useState()
    const [paypalEmail, setPyapalEmail] = useState("")
    const [paypalNumber, setPaypalNumber] = useState("")

    const [nativeCurrency, setNativeCurrency] = useState([])

    const [amount, setAmount] = useState();
    const [tokenAddr, setTokenAddr] = useState("0x8ab7ef0eb25aad36dff0661f81fb81b144af5b87");
    const [coin1DollarBalace, setCoin1DollarBalace] = useState()
    const [abi, setAbi] = useState()
    const [lists, setLists] = useState([])
    const [coinName_cash, setCoinName_cash] = useState()
    const [coinImage_cash, setCoinImage_cash] = useState()
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState([]);
    const [search, setSearch] = useState();
    const [filteredTokenList, setFilteredTokenList] = useState();
    const [chain_xswap, setChain_xswap] = useState("BNB")
    const [chain_xswapImage, setChain_xswapImage] = useState("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")
    const [chainLists_cash, setChainLists_cash] = useState()
    const [isCopied, setIsCopied] = useState(false);
    const [sentWalletAddress, setSentWalletAddress] = useState()
    const modalOpen = useRef()

    const [afterPaymentModal, setAfterPaymentModal] = useState(false)

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false)
    const [show5, setShow5] = useState(false)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleShow3 = () => setShow3(false)

    const chainShow = () => {
        if (chain_xswap == "Ethereum") {
            setCoinName_cash("ETH")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png")

        } else if (chain_xswap == "BNB") {
            setCoinName_cash("BNB")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")


        } else if (chain_xswap == "Polygon") {
            setCoinName_cash("MATIC")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png")

        } else if (chain_xswap == "Arbitrum") {
            setCoinName_cash("ARB")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png")

        } else if (chain_xswap == "Fantom") {


            setCoinName_cash("FTM")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")

        } else if (chain_xswap == "Avalanche") {


            setCoinName_cash("AVAX")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png")


        } else if (chain_xswap == "Optimism") {
            setCoinName_cash("OP")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png")

        } else if (chain_xswap == "TRON") {
            setCoinName_cash("TRX")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png")
        } else if (chain_xswap == "Bitcoin") {
            setCoinName_cash("BTC")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/1.png")
        } else if (chain_xswap == "Dogecoin") {
            setCoinName_cash("DOGE")
            setCoinImage_cash("https://s2.coinmarketcap.com/static/img/coins/64x64/74.png")
        }
    }

    const tokenNumber = () => {
        axios.get(`${API_URL.getTokenCount}?chainName=${chain_xswap}`)
            .then((res) => {
            
                if (res.data.data) {
                    allTokens(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const allTokens = (chainCoin) => {
        axios.get(`${API_URL.getTokenList}?chainName=${chain_xswap}&no_of_docs_each_page=${100}&current_page_number=${0}`)
            .then((res) => {
                
                setLists(res.data.data)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    const allchainList_cash = () => {
        axios.get(API_URL.getchainList)
            .then((res) => {
               
                setChainLists_cash(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //    native chains list
    const nativeChainList = () => {
        axios.get(API_URL.dogetrxbtcChain)
            .then((res) => {
              

                setNativeCurrency(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        nativeChainList()
    }, [])

    // get Doge and Tron Status

    const dogeStatus = () => {
        setShow3(false)
        setShow5(true)

    }

    // get time status
    const callTime = async () => {


        const Web3 = require('web3');
     
        const url = process.env.REACT_APP_QUICK_NODE;
        const provider = new Web3.providers.HttpProvider(url);
        const web3 = new Web3(provider);
        web3.eth.getBlock('latest', (err, block) => {
            if (err) {
                console.error(err);
            } else {
             
                sendTransaction(block.timestamp);
            }
        });

    }

    // After Payment Modal



    const afterPay = () => {
        if (chain_xswap != "TRON" && chain_xswap != "Dogecoin" && chain_xswap != "Bitcoin") {
            setTimeout(() => {

                setShow3(false)
                setAfterPaymentModal(true)
            }, 200000)
        }
    }

    // sendTransaction details 


    const WAIT_TIME = 5000
    const sendTransaction = (timeParameter) => {
        setLoading(true)
        const id = setInterval(() => {
            axios.post(API_URL.saveAllChainQRtransaction, {

                walletAddress: "0x",
                time: timeParameter,
                amount: amount,
                chain: chain_xswap
            })
                .then((res) => {
              

                    if (res.data.data === "transactionSuccess") {
                        clearInterval(id);
                        NotificationManager.success("Payment Successful !")
                        setAfterPaymentModal(false)
                        setLoading(false)
                        setShow4(true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setAfterPaymentModal(false)
                    clearInterval(id);
                    setLoading(false)
                })
        }, WAIT_TIME)
     
    }
    useEffect(() => {
        tokenNumber()
    }, [coinName_cash])
    const exchangeRate = () => {

        let coinName1 = "LINK"

        axios.get(`${API_URL.exchange}?amount=${amount}&convert=${coinName1}&symbol=${coinName_cash}`)
            .then((res) => {
           
                setCoin1DollarBalace(res.data.data?.symbol?.amountInDollar);
             
            })
            .catch((err) => {
                console.log(err)
                setCoin1DollarBalace(0);
            })
    }

    useEffect(() => {
        if (coinName_cash) {
            exchangeRate()
        }
    }, [coinName_cash, chain_xswap])

    useEffect(() => {
        exchangeRate()
    }, [amount])

    useEffect(() => {
        allchainList_cash()
    }, [])



    useEffect(() => {
        allTokens()

    }, [chain_xswap, amount, coinName_cash, coinImage_cash, tokenAddr, abi, account])


    useEffect(() => {
        chainShow()
    }, [chain_xswap])

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };


    const handleChange = (e) => {
        setSearch(e.target.value)
        axios.get(`${API_URL.searchTokenByChain}?chainName=${chain_xswap}&token=${e.target.value}`)
            .then((res) => {
             
                setFilteredTokenList(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }
    useEffect(() => {
        setFilteredTokenList(lists)
    }, [lists])



    const bankTransfer = (e) => {
        e.preventDefault()


        if (country == "usa" || country == "others") {
            axios.post(API_URL.saveTransaction, {
                "type": country,
                "accountNumber": Number(accountNumber),
                "routingNumber": Number(routingNumber),
                "swiftCode" : swiftCode,
                "country" : country,
                "bankName": bankName,
                "postalCode": postalCode,
                "accountHolderName": accountHolderName,
                "email": email,
                "contactNumber": Number(contactNumber)
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then(async (res) => {

                 
                    handleClose1()
                    handleShow()


                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            axios.post(API_URL.saveTransaction, {
                "type": country,
                "IBAN": Number(iban),
                "swiftCode" : swiftCode,
                    "country" : country,
                "accountHolderName": accountHolderName,
                "ZIPCode": Number(zipcode),
                "email": email,
                "contactNumber": Number(contactNumber)
            })
                .then((res) => {
                  
                    handleClose1()
                    handleShow()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const paypalTransfer = () => {

        axios.post(API_URL.savePaypalTransaction, {
            "email": paypalEmail,
            "contactNumber": paypalNumber
        })
            .then((res) => {
             
                handleClose2()
                handleShow()
            })
            .catch((err) => {
                console.log(err)
                handleClose2()
                NotificationManager.error(err.message)
            })


    }

    return (
        <>
            <div className="swap-tab-container p-3 d-flex flex-column">

                <div className="input-container p-4 d-flex flex-column gap-3 active">

                    <div className="d-flex justify-content-between flex-wrap">

                        <div className='d-flex flex-column w-75'>
                            <div className="dropdown d-flex align-items-center">
                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={chain_xswapImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {(chain_xswap == "BNB" ? "BSC" : chain_xswap)}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%", cursor: "pointer" }}>
                                        {chainLists_cash?.map((lists, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    setChain_xswap(lists.name)
                                                    setChain_xswapImage(lists.imageURL)
                                                  
                                                }} className='d-flex mt-2' >
                                                    <div>
                                                        <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "25px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                                    </div>
                                                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                                        <p>{(lists.name == "BNB" ? "BSC" : lists.name)}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {
                                            nativeCurrency?.map((lists, index) => {
                                                return (
                                                    <>
                                                        <div key={index} onClick={() => {
                                                            setChain_xswap(lists.name)
                                                            setChain_xswapImage(lists.imageURL)
                                                          
                                                        }} className='d-flex mt-2' >
                                                            <div>
                                                                <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "25px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                                            </div>
                                                            <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                                                <p>{(lists.name)}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <input className="input-field mt-2" type="text" placeholder="1" onChange={(e) => setAmount(e.target.value)} />
                        </div>

                        <div className="dropdown d-flex align-items-center w-25">
                            <img src={coinImage_cash} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{coinName_cash}</span>
                            <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop_cash">&#xf107;</i>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${coin1DollarBalace}</p>
                        {/* <p className="input-balance p-0 m-0">Balance : {balance ? balance : "0"}</p> */}
                    </div>
                </div>

                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                </div>

                <div className="input-container p-4 d-flex">
                    <div className='w-100 d-flex flex-wrap justify-content-between px-2 px-md-4 px-lg-5'>
                        <button className="cash-button px-4 py-2" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={(amount == null  || amount === "") ? "" : handleShow2}>PayPal</button>
                        <button onClick={(amount == null  || amount === "") ? "" : handleShow1} className="cash-button px-4 py-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">Bank Transfer</button>
                        <NotificationContainer />
                    </div>
                </div>


                <div className='w-100 swap-button-container m-auto mt-2'>
                 {(amount == null  || amount === "") ? 
                 
                 <button className='px-5 py-1 w-100'>
                        Enter Amount
                    </button>

                    :

                    <button className='px-5 py-1 w-100' data-bs-toggle="modal" data-bs-target="#exampleModal_cash">
                        SWAP
                    </button>
                 }
                    

                </div>

            </div>

            {/* <!-- Modal PAYPAL--> */}

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">PayPal</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">

                        <form>
                            <div className='row'>
                                <div className='col-4'><p className='paypal-text'>Email Address &nbsp; :</p></div>
                                <div className='col-6'>
                                    <input type="text" className='input-paypal' onChange={(e) => {

                                        var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                        setEmailValid(numberRegex.test(e.target.value))
                                      
                                        if (numberRegex.test(e.target.value)) {
                                            setPyapalEmail(e.target.value)
                                        }



                                    }} />
                                    <p>{(emailValid == false) ? "Enter Valid Mail Address" : ""}</p>
                                </div>

                            </div>
                            <div className='row mt-2'>
                                <div className='col-4'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                <div className='col-6'>
                                    <input type="text" className='input-paypal' onChange={(e) => {

                                        var numberRegex = /^[0-9]*$/


                                        if (numberRegex.test(e.target.value)) {
                                            setPaypalNumber(e.target.value)
                                        }


                                    }} />
                                </div>

                            </div>
                        </form>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-secondary confirm-swap w-100" variant="primary" onClick={paypalTransfer}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* form Model */}

            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Bank Tranfer</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <form>
                            <div className='row'>
                                <div className='col-5'>
                                    <label htmlFor="cars" className='paypal-text'>Choose a country:</label>
                                </div>
                                <div className='col-5'>
                                    <select name="cars" id="cars" className='input-paypal' onClick={(e) => setCountry(e.target.value)}>
                                        <option value="usa">USA</option>
                                        <option value="Europe">Europe</option>
                                        <option value="others">Others</option>

                                    </select>
                                </div>
                            </div>
                        </form>
                        {
                            (country == "usa" || country == "others") ?
                                <>
                                    <form>
                                        <div className='row mt-3'>
                                            <div className='col-5'><p className='paypal-text'>Account Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setAccountNumber(e.target.value)
                                                    }

                                                }} />
                                            </div>

                                        </div>
                                        <div className='row mt-3'>
                                                <div className='col-5'><p className='paypal-text'>Swift Code &nbsp; :</p></div>
                                                <div className='col-5'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                       
                                                            setSwiftCode(e.target.value)
                                                    

                                                    }} />
                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-5'><p className='paypal-text'>Country &nbsp; :</p></div>
                                                <div className='col-5'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                            setCountry(e.target.value)
                                                        

                                                    }} />
                                                </div>

                                            </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Routing Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) =>




                                                    setRoutingNumber(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Bank Name &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) =>

                                                    setBankName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Postal Code &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) =>




                                                    setPostalCode(e.target.value)

                                                } />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Holder Name &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => setAccountHolderName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Email &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => {
                                                    var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                                    setEmailValid(numberRegex.test(e.target.value))
                                                   
                                                    if (numberRegex.test(e.target.value)) {
                                                        setEmail(e.target.value)
                                                    }


                                                }} />

                                                <p>{(emailValid == false) ? "Enter Valid Mail Address" : ""}</p>
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                            <div className='col-5'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setContactNumber(e.target.value)
                                                    }
                                                }} />


                                            </div>

                                        </div>
                                    </form>
                                </> :
                                <>
                                    <form>
                                        <div className='row mt-3'>
                                            <div className='col-4'><p className='paypal-text'>IBAN &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = / \b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1, 2})?\b/
                                                 

                                                    if (numberRegex.test(e.target.value)) {
                                                        setIban(e.target.value)
                                                    }
                                                }} />
                                            </div>

                                        </div>
                                        <div className='row mt-3'>
                                                <div className='col-4'><p className='paypal-text'>Swift Code &nbsp; :</p></div>
                                                <div className='col-6'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                       
                                                            setSwiftCode(e.target.value)
                                                    

                                                    }} />
                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-4'><p className='paypal-text'>Country &nbsp; :</p></div>
                                                <div className='col-6'>
                                                    <input  type="text" className='input-paypal' onChange={(e) => {

                                                            setCountry(e.target.value)
                                                        

                                                    }} />
                                                </div>

                                            </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Holder Name &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => setAccountHolderName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Email &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                                                    setEmailValidEuro(numberRegex.test(e.target.value))

                                                    if (numberRegex.test(e.target.value)) {
                                                        setEmail(e.target.value)
                                                    }


                                                }} />

                                                <p>{(emailValidEuro == false) ? "Enter Valid Mail Address" : ""}</p>
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Postal Code &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) =>





                                                    setZipCode(e.target.value)




                                                } />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-4'><p className='paypal-text'>Contact Number &nbsp; :</p></div>
                                            <div className='col-6'>
                                                <input type="text" className='input-paypal' onChange={(e) => {

                                                    var numberRegex = /^[0-9]*$/


                                                    if (numberRegex.test(e.target.value)) {
                                                        setContactNumber(e.target.value)
                                                    }

                                                }} />
                                            </div>

                                        </div>

                                    </form>
                                </>
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-secondary  confirm-swap w-100" variant="primary" onClick={bankTransfer}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* confrim swap */}
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Swap</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="swap-tab-container p-3 d-flex flex-column">

                        <div className="input-container p-4 d-flex flex-column gap-3 active">

                            <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                {amount}
                                <div className="dropdown d-flex align-items-center w-25">
                                    <img src={coinImage_cash} style={{ height: "20px", borderRadius: "50%" }} />
                                    {coinName_cash}
                                </div>
                            </div>
                         
                        </div>
                        <div className='d-flex justify-content-between align-items-center swap-button-container m-auto'>

                       
                        </div>
                        <p className='token-value ps-4 py-2 mt-4'>
                                <p> {amount} &nbsp; {coinName_cash} = {coin1DollarBalace} &nbsp; USD</p>
                            </p>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="btn btn-primary confirm-swap w-100" variant="primary" data-bs-dismiss="modal" onClick={() => {
                        setShow3(true); setShow(false); afterPay()
                        { (chain_xswap == "Dogecoin" || chain_xswap == "TRON" || chain_xswap == "Bitcoin") ? setShow5(false) : callTime() }
                    }} >
                        CONFIRM
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* currency Modal  */}

            <div className="modal fade" id="staticBackdrop_cash" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" value={search} className="mb-5 " placeholder='Search Token' onChange={handleChange} />
                            </div>
                            {filteredTokenList?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        setCoinName_cash(lists.symbol);
                                        setCoinImage_cash(lists.imageURL);
                                    }} className='d-flex mt-4' data-bs-dismiss="modal">
                                        <div >
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "40px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Reconfirm currency */}

            <Modal show={show3} onHide={handleShow3} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scan & Pay</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {
                            (chain_xswap == "Dogecoin") ?
                                <>
                                    <div className='col-8'>
                                        <img src={Dogeqr} style={{ height: "250px" }} className='img-fluid' />
                                    </div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <p className="add-wallet">  {walletAddressDoge} </p>
                                        <CopyToClipboard text={walletAddressDoge} onCopy={onCopyText}>
                                            <div className="copy-area pl-2">
                                                <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                (chain_xswap == "Bitcoin") ?
                                    <>
                                        <div className='col-8'>
                                            <img src={BTCqr} style={{ height: "250px" }} className='img-fluid' />
                                        </div>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <p className="add-wallet"> {walletAddressBtc}</p>
                                            <CopyToClipboard text={walletAddressBtc} onCopy={onCopyText}>
                                                <div className="copy-area pl-2">
                                                    <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>
                                                </div>
                                            </CopyToClipboard>
                                        </div>
                                    </>
                                    :
                                    (chain_xswap == "TRON") ?
                                        <>
                                            <div className='col-8'>
                                                <img src={Trxqr} style={{ height: "250px" }} className='img-fluid' />
                                            </div>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <p className="add-wallet">{walletAddressTron} </p>
                                                <CopyToClipboard text={walletAddressTron} onCopy={onCopyText}>
                                                    <div className="copy-area pl-2">
                                                        <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>
                                                    </div>
                                                </CopyToClipboard>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='col-8 '>
                                                <img src={MetmaskQr} style={{ height: "250px" }} className='img-fluid' />
                                            </div>
                                            <div className='d-flex justify-content-center mt-2'>
                                                <p className="add-wallet" style={{ fontSize: "17px", fontWeight: "600" }}> {walletAddressMetamask} </p>
                                                <CopyToClipboard text={walletAddressMetamask} onCopy={onCopyText}>
                                                    <div className="copy-area pl-2">
                                                        <i className="fa fa-copy" style={{ fontSize: "22px" }} title={isCopied ? "copied" : "copy address"}></i>
                                                    </div>
                                                </CopyToClipboard>
                                            </div>
                                        </>

                        }
                    </div>

                    <p className='token-value ps-4 py-2 mt-4'>
                        <p>Please click on confirm after sending {amount} {coinName_cash} </p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100'
                            onClick={() => {

                                { chain_xswap == "Dogecoin" || chain_xswap == "TRON" || chain_xswap == "Bitcoin" ? dogeStatus() : setShow3(true) }
                            }}
                        // onClick={() => {setAfterPaymentModal(true) ; setShow3(false)}}
                        >
                            {(chain_xswap == "Dogecoin" || chain_xswap == "TRON" || chain_xswap == "Bitcoin") ? "Confirm" : loading ? <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ fontSize: 16 }}
                            /> : " CLOSE"}


                        </button>

                    </div>

                </Modal.Footer>
            </Modal>

            {/* After Payment Success */}


            <Modal
                show={show4}
                onHide={() => setShow4(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>

                        <img src={PaymentStatus} />

                    </div>
                    <p className='token-value ps-4 py-2 mt-4'>
                            <p>Transferring {coin1DollarBalace} USD to your Account </p>
                        </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => setShow4(false)}>

                            CLOSE


                        </button>

                    </div>

                </Modal.Footer>
            </Modal>

            {/* For Doge and Trx  */}

            <Modal show={show5} onHide={() => setShow5(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Confirming</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {/* <div className='col-6'> */}
                        <img src={PaymentWaiting} />
                        {/* </div> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => setShow5(false)}>

                            CLOSE


                        </button>

                    </div>

                </Modal.Footer>

            </Modal>

            {/* After QR payment user need to click on confirm payment */}
            <Modal show={afterPaymentModal} onHide={() => setAfterPaymentModal(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <p className='token-value ps-4 py-2 mt-4 text-center'>
                        <p> Click on confirm if you have sent &nbsp; {amount} &nbsp; {coinName_cash}</p>
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => { { (chain_xswap == "Dogecoin" || chain_xswap == "TRON") ? setShow5(true) : setShow4(true) }; setAfterPaymentModal(false) }}>

                            CONFIRM


                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
        </>
    )
}
