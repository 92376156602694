import React from "react";
import { Link } from "react-router-dom";
import pic1 from "../../assets/Read4/pic1.png";
import pic3 from "../../assets/Read4/pic3.png";
import Logo from "../../assets/CAPITRON logoD (13) 1.png";

export default function Read4() {
  return (
    <div className="container-fluid">
      <div className="d-flex gap-3 flex-wrap align-items-center pt-5">
        <img src={Logo} style={{ width: "60px", height: "60px" }} alt="Logo" />
        <h2 className="swap-footer-logo">Capitron</h2>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-xl-10 col-md-10 col-lg-10 col-12 ">
          <div className="row  d-flex justify-content-center mt-5">
            <h2 className="text-center">
              How to use Hybrid swap(HSwap) on Capitron ?
            </h2>
            <h5 className="mt-2">
              1. Head over to capitron.com/hybridswap page, Click on HSwap.
              Select Ethereum/Polygon/BSC/Arbitrium/Fantom/Avalanche/Optimism
              network which you want on the drop-down menu and select the coin
              you want swap.
            </h5>
            <img src={pic1} className="img-fluid mt-2" />
            <h5>
              2. Select the coin and chain to which you want to convert on the
              lower half of the HSwap and input the amount you wish to convert
              and Click on ‘Swap’.
            </h5>
            <h5>
              3. Enter the destination address and click on confirm. Unique QR
              will be generated.
            </h5>
            <img src={pic3} className="img-fluid mt-2" />
            <h5>
              4. Scan the QR code and transfer the amount manually from any
              wallet and confirm the amount.{" "}
            </h5>
            <h5>5. Voila! You have successfully swapped your coin</h5>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-xl-8 col-md-8 col-lg-8 col-12 ">
          <p>
            💬{" "}
            <i>
              Still have questions? Hit us up on{" "}
              <Link to="t.me/capitronsupport ">Telegram</Link> or{" "}
              <Link to="/https://www.instagram.com/capitronsupport/">
                Instagram
              </Link>{" "}
              &nbsp; Capitron is building a decentralized, comprehensive DeFi
              swap ecosystem complete with AMM, cross-chain capabilities. Follow
              our socials to keep up with our product launches and find out more
              on how you can make the most of your cryptocurrency assets with
              Capitrons secure and powerful DeFi swap tools!
            </i>
          </p>
        </div>
      </div>
    </div>
  );
}
