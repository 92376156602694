import React from "react";
import "./Hero.css";
import "../Navbar/Navbar.css";
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <div className="container-fluid hero">
      <div className="row d-flex justify-content-center">
        <div className="col-xl-6 col-lg-6 col-md-7 col-sm-10 col-8 d-flex justify-content-center">
          <div className="row">
            <h2 className="hero-text" style={{maxWidth: '700px'}}>
              EXPERIENCE THE TRUE POWER OF DECENTRALIZED FINANCE WITH SWAP
            </h2>

            {/* <div className='col-xl-12  d-flex justify-content-center'>
                            <span className='hero-text'>EXPERIENCE THE TRUE POWER  </span>
                        </div>
                        <div className='col-xl-12  d-flex justify-content-center'>
                            <span className='hero-text'> OF DECENTRALIZED FINANCE WITH SWAP </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="row  d-flex justify-content-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
          <div className="row   ">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5 d-flex justify-content-center">
              <Link to="/hybridswap">
                <button className="connect-button">Hybrid Swap </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5 d-flex justify-content-center ">
              <Link to="/swap">
                <button className="buy-button">Defi Swap </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-5 d-flex justify-content-center ">
              <Link to="/nativeswap">
                <button className="buy-button">Native Swap </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
