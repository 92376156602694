const BASE_URL = "https://prod-backend.capitron.exchange/v1"

const API_URL = {
    getTokenList: `${BASE_URL}/exchange/getTokenList`,
    getchainList: `${BASE_URL}/exchange/getchainList`,
    getTokenABI: `${BASE_URL}/exchange/tokenABIs`,
    exchange: `${BASE_URL}/exchange/exchnageRate`,
    saveTransaction: `${BASE_URL}/exchange/saveTransaction`,
    savePaypalTransaction: `${BASE_URL}/exchange/savePaypalTransaction`,
    getTokenCount: `${BASE_URL}/exchange/getTokenCount`,
    btcWalletTransaction : `${BASE_URL}/users/btcWalletTransaction`,
    contactUs : `${BASE_URL}/users/contactUs`,
    DogeWalletTransaction : `${BASE_URL}/users/saveDogeCoinTransactions`,
    saveAllChainQRtransaction : `${BASE_URL}/users/saveAllChainQRtransaction`,
    dogetrxbtcChain : `${BASE_URL}/exchange/dogetrxbtcChain`,
    searchTokenByChain : `${BASE_URL}/exchange/searchTokenByChain`
};
export default API_URL;



