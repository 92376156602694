import { createContext, useContext, useState, useEffect } from "react";
import TronWeb from 'tronweb';
import axios from "axios";
import API_URL from "../Api/apiUrl"
import Web3Modal from "web3modal";
import Web3 from "web3";
// import {  toast } from 'react-toastify';
import { providerOptions } from "./providerOptions";
const ethers = require("ethers");

const Web3WalletContext = createContext();

// console.log(providerOptions)
const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true, // optional
  providerOptions, // required

});

export const Web3WalletProvider = ({ children }) => {
  const [provider, setPovider] = useState(null);
  const [library, setLibrary] = useState(null);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  
  const [chainId, setChainId] = useState(null);
  const [iSIcoTimeStarted, setISIcoTimeStarted] = useState(null);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [tronAccount , setTronAccount] = useState()
  const [myMessage, setMyMessage] = useState(<h3> LOADING.. </h3>);
  const [myDetails, setMyDetails] = useState({
    name: 'none',
    address: '',
    balance: 0,
    frozenBalance: 0,
    network: 'none',
    link: 'false',
  });
   
  const [btcWallets  , setBtcWallets] = useState()
  const [dogeWallets , setDogeWallets] = useState()

  useEffect(() => {
    const handleDisconnect = () => {
      
      disconnect();
    };
  
    


    const handleChainChanged = async (_hexChainId) => {
      setChainId(_hexChainId);
     
      await disconnect();
    
    };
    provider?.on("chainChanged", handleChainChanged);
    provider?.on("disconnect", handleDisconnect);
    return () => {
      provider?.removeListener("chainChanged", handleChainChanged);
      provider?.removeListener("disconnect", handleDisconnect);
    };
  }, [provider]);

  const connectWallet = async () => {
    try {

      const provider = await web3Modal.connect();
      await web3Modal?.toggleModal();
     
      const library = new ethers.providers.Web3Provider(provider);
    
      // await switchNetwork();
      const accounts = await library.listAccounts();
    
      const network = await library.getNetwork();
    
      if (accounts) setAccount(accounts[0]);
      setPovider(provider);
      setLibrary(library);
      setChainId(network.chainId);
    
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const refreshState = () => {
    setPovider(null);
    setLibrary(null);
    setAccount(null);
    setChainId(null);
  };

  const disconnect = async () => {
    await web3Modal?.clearCachedProvider();
    refreshState();
  };


 


  const connectTron = () => {
                
    if(window?.tronWeb && window?.tronWeb?.defaultAddress?.base58){
        setTronAccount(window?.tronWeb?.defaultAddress?.base58)
       
        // console.log("Yes, catch it:",window?.tronWeb?.defaultAddress?.base58)
    }else{
        alert("Please login To tron wallet")
        // console.log(window?.tronWeb?.defaultAddress?.base58)
    }
    
  }

  
  const disConnectTron = () => {
    setTronAccount("")
  }
 
  const  xdefiBtc = () => {
    if (window?.xfi && window?.xfi?.bitcoin) {
        window?.xfi?.bitcoin?.request(
          {method: 'request_accounts', params: []},
          (error, accounts) => {
             console.log(`Bitcoin accounts ${accounts}`) ; setBtcWallets(accounts[0])}
          
        );
      } else {
        alert("Please connect xdefi Btc wallet")
      }
}  

const  xdefiDoge = () => {
  if (window?.xfi && window?.xfi?.dogecoin) {
      window?.xfi?.dogecoin?.request(
        {method: 'request_accounts', params: []},
        (error, accounts) => {console.log(`dogecoin accounts ${accounts}`); setDogeWallets(accounts[0])}
      );
    }
    else{
      alert("Please connect xdefi Doge wallet")
    }
}

const disConnectbtc = () => {
  setBtcWallets("")
}
const disConnectdoge = () => {
  setDogeWallets("")
}

  return (
    <Web3WalletContext.Provider
      value={{
        connectWallet,
        connectTron,
        xdefiDoge,
        xdefiBtc,
        btcWallets,
        dogeWallets,
        tronAccount,
        myDetails,
        provider,
        library,
        account,
        chainId,
        error,
        disconnect,
        disConnectTron,
        iSIcoTimeStarted,
        disConnectbtc,
        disConnectdoge,
       
      }}
    >
      {children}
    </Web3WalletContext.Provider>
  );
};

export const useWeb3Wallet = () => {
  return useContext(Web3WalletContext);
};

var toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
