const PartnerData = [
  {
    id: 1,
    link: "https://changelly.com/static/partners/partner-exodus.svg",
    content: `With over 90 blockchain assets and 24/7 customer support, Exodus makes it easy to manage a diversified portfolio. Your wallet encrypts your private keys locally with no account setup required Exodus gives blockchain asset investors a platform to secure, exchange and control their own wealth inside one beautiful application.`,
  },
  {
    id: 2,
    link: "https://changelly.com/static/partners/zengo.svg",
    content: `ZenGo is the crypto wallet app for everyone. Buy, trade, and earn interest on Bitcoin, Ethereum, and 70+ cryptocurrencies with unbelievable simplicity, bulletproof security and 24/7 live agent customer support. Powered by revolutionary MPC technology, ZenGo is the leading choice for beginners and pros.`,
  },
  {
    id: 3,
    link: "https://changelly.com/static/partners/partner-binance.svg",
    content: ` Binance is international cryptocurrency exchange and trading platform. While being fairly new platform, Binance has quickly become the largest cryptocurrency exchange on the market. Such popularity is gained with amazingly low fees, fast new coin listing, user-friendly interface and efficient support
`,
  },
  {
    id: 4,
    link: "https://changelly.com/static/partners/partner-ledger.svg",
    content: ` Ledger is a cryptocurrency hardware wallet that comes in the shape of a USB drive. It’s designed with robust safety features and is excellent for storing digital assets. Also it provides a safe way to execute digital payments
`,
  },
  {
    id: 5,
    link: " https://changelly.com/static/partners/partner-trezor.svg",
    content: ` Trezor is an offline wallet designed to help users keep their cryptocurrency extremely secure. It operates as a cold storage device, meaning it is always offline. However, it provides users with a great flexibility and connectivity when it comes to the exchange purposes
`,
  },
  {
    id: 6,
    link: " https://changelly.com/static/partners/partner-mew.svg",
    content: ` MyEtherWallet is a free, open source Ethereum &amp; ERC20 wallet client that makes it easier for you to manage, store, receive, &amp; transfer Ether and ERC20 tokens. One of the best wallets on the market
`,
  },
  {
    id: 7,
    link: " https://changelly.com/static/partners/partner-swapzone.svg",
    content: ` Swapzone is a non-custodial cryptocurrency exchange aggregator that provides a one-stop solution for finding the best instant exchange deal. With Swapzone, you can compare more than 15 top exchange services, just like Changelly, by rates, transaction time, and user feedback to choose the best offer for over 1200 crypto assets.
`,
  },
  {
    id: 8,
    link: " https://changelly.com/static/partners/abra-logo.svg",
    content: ` Abra is a financial services and technology company that operates an all-in-one, custodial cryptocurrency wallet and exchange. Abra works with Changelly to expand their swap offering for alt coins not available within their app.
`,
  },
  {
    id: 9,
    link: " https://changelly.com/static/partners/partner-edge.svg",
    content: ` Edge is a mobile wallet designed to make it simple to store and trade cryptocurrencies. It allows users to send and receive 12 digital currencies, and also allows users to make a cross-chain transactions without the need to go to the third-party services
`,
  },
  {
    id: 10,
    link: " https://changelly.com/static/partners/partner-coinomi.svg",
    content: ` Coinomi is a popular multi-asset application wallet for Android and iOS founded in 2014. Having millions of users, Coinomi has never had any of its wallets hacked. The wallet supports Bitcoin, Ethereum and many altcoins and tokens, that can be easily exchanged instantly with Changelly inside the wallet application.
`,
  },
  {
    id: 11,
    link: " https://changelly.com/static/partners/partner-bcvault.svg",
    content: ` BC Vault is a hardware cold wallet, featuring Changelly’s swap functionality within native interface allowing users to instantly perform exchange of digital assets without compromising security.
`,
  },
  {
    id: 12,
    link: " https://changelly.com/static/partners/partner-infinito.svg",
    content: ` Infinito Wallet is an industry-leading universal wallet for the widest range of cryptocurrencies and blockchain services - trusted by more than 450,000 global users. It currently supports BTC, ETH, BNB, EOS, NEO, ADA, XLM, ONT, BCH, ETC, LTC, Dash, DOGE, TOMO, as well as all tokens built on leading smart contract blockchains including ERC-20, NEO NEP-5, EOS, and BEP-2 tokens – with more coming on the roadmap.`,
  },
  {
    id: 13,
    link: " https://changelly.com/static/partners/partner-enjin.svg",
    content: ` Enjin Wallet is a high quality and high security based wallet, designed specifically to support ERC20 tokens. It allows users to have more than 600 ERC20 tokens in the pocket. Extremely user friendly and handy wallet.
`,
  },
  {
    id: 14,
    link: " https://changelly.com/static/partners/partner-dcent.svg",
    content: ` D’CENT Wallet is created by IoTrust, a company founded by security experts with over 15 years of security know-how and engineering experience in developing deeply embedded security solutions based on secure-chip technology (SE and TEE). D’CENT Wallet aims to protect users’ digital assets by combining software and hardware security solutions. Users have the choice of using the Biometric Wallet, Card type Wallet, or the Software Wallet.
`,
  },
  {
    id: 15,
    link: " https://changelly.com/static/partners/cryptodaily-partner.svg",
    content: ` CryptoDaily is a trusted online media outlet, that brings an original coverage for cryptocurrency and blockchain news.
`,
  },
  {
    id: 17,
    link: " https://changelly.com/static/partners/partner-alohabrowser.svg",
    content: ` The Aloha brand is well known worldwide as a popular private and secure WEB3 browser - Aloha Browser (featuring an integrated Aloha Crypto Wallet). The intuitive and excellent design, combined with security and trust from users, make the Aloha Browser an indispensable tool in everyday life.
`,
  },
  {
    id: 18,
    link: " https://changelly.com/static/partners/partner-jaxx.svg",
    content: ` Jaxx Liberty is one of the world’s most popular cryptocurrency wallets. In addition to a wallet, you can access your portfolio, in-app exchange, markets, news and rewards all in the same app. It supports 85+ cryptocurrencies, and is available across Android, iOS, macOS, Linux, Windows and the Chrome browser.
`,
  },
  {
    id: 19,
    link: " https://changelly.com/static/partners/curioinvest-partner.svg",
    content: ` CurioInvest enables everyone to invest in and profit from collectable cars. By using a crowdfunded, community-based investment model, CurioInvest will open up the collectable car market to millions of new investors.
`,
  },
  {
    id: 20,
    link: " https://changelly.com/static/partners/partner-ontology.svg",
    content: ` Ontology is a new high-performance public blockchain project &amp; a distributed trust collaboration platform. OWallet offers three wallet types for you to use: individual wallets, shared wallets, and Ledger hardware wallet support. OWallet currently has Windows and macOS versions.
`,
  },
  {
    id: 21,
    link: " https://changelly.com/static/partners/partner-bittrex.svg",
    content: ` Bittrex is a premier blockchain trading platform founded in 2014 by cybersecurity engineers. Bittrex is all about high security, super fast trade executions and trustworthy digital wallets for different currencies.
`,
  },
  {
    id: 22,
    link: " https://changelly.com/static/partners/winningpokernetwork.svg",
    content: ` One of the most trusted online Poker Network in the world. Since 2001, Winning Poker Network supports the top-notch poker rooms with unique player promotions, fast and feature-rich software and one the most secure playing environment in the industry.
`,
  },
  {
    id: 23,
    link: " https://changelly.com/static/partners/coldlar-partner.svg",
    content: ` Founded in Beijing in November 2016, ColdLar is a technology company focused on providing secure storage solutions for cryptoassets. Born to be the “Guardian for Cryptoasset “, ColdLar is also committed to be the “World’s Leading Cryptoasset Security Service Provider.”
`,
  },
  {
    id: 24,
    link: " https://changelly.com/static/partners/partner-coinswitch.svg",
    content: ` CoinSwitch is a cryptocurrency exchange platform which compilates and compare all leading exchange cryptocurrency prices in real time. CoinSwitch supports over 300 cryptocurrencies, altcoins, digital currencies. CoinSwitch is an excellent tool for a quick and easy trade through most popular exchanges.
`,
  },
  {
    id: 25,
    link: " https://changelly.com/static/partners/partner-freewallet.svg",
    content: ` Freewallet is a place to send, store and manage your cryptocurrency. BTC, ETH and 100+ other cryptocurrencies are available in one wallet as an Android, iOS and web app. Freewallet’s built-in exchange is based on the smooth Changelly service, and has become one of Freewallet’s most popular features.
`,
  },
  {
    id: 26,
    link: " https://changelly.com/static/partners/partner-ellipal.svg",
    content: ` ELLIPAL is the first truly connection-free hardware wallet with mobile phone support. It is the only hardware wallet that does not need USB or Bluetooth to work. It uses QR codes to communicate with the ELLIPAL mobile app making the hardware wallet a truly air-gapped, fully isolated device, giving users the highest security level.
`,
  },
  {
    id: 27,
    link: " https://changelly.com/static/partners/partner-ardor.svg",
    content: ` A wallet launched by a Jelurida company to safely stor ARDR and IGNIS. Jelurida is a blockchain software company that enables customers to cooperate, promote unique value propositions, and create new business tackling real market opportunities.
`,
  },
  {
    id: 28,
    link: " https://changelly.com/static/partners/best-change-logo.svg",
    content: ` BestChange is a free aggregator of e-currency exchange offers from Internet exchangers. The service allows to find and compare the best exchange rates for all popular e-currency, fiat and cryptocurrency exchange directions.
`,
  },
  {
    id: 29,
    link: " https://changelly.com/static/partners/okex.svg",
    content: ` OKEx is a world-leading cryptocurrency exchange, providing advanced financial services to traders globally by using blockchain technology.
`,
  },
  {
    id: 30,
    link: " https://changelly.com/static/partners/partner-coolwallet.svg",
    content: ` CoolWallet S is the first Wireless Bitcoin hardware wallet. It is one of the best cold wallets on the market, providing a high-end security, combined with a top-class design for its users. This wallet was designed by a Taiwan company CoolBITX in 2014. There are more cool wallets to appear!
`,
  },
  {
    id: 31,
    link: " https://changelly.com/static/partners/partner-coingate.svg",
    content: ` CoinGate is a payment gateway for blockchain payments. Having started off in 2014, it has made it a mission to grow adoption of cryptocurrencies on a global scale. With this in mind, CoinGate provides a reliable payments infrastructure that caters for the needs of both merchants and their customers: accessible, convenient and customer oriented.
`,
  },
  {
    id: 32,
    link: " https://changelly.com/static/partners/coinspaid-partner.svg",
    content: ` CoinsPaid is a system of wallet management and crypto payment processing for leading online businesses, supporting over 14 blockchains. Simple API allows easy integration in online casino, betting platform or other online business. Internal fixed-rate crypto-to-fiat and altcoin-to-BTC exchange prevents from market fluctuations and ensures funds kept safe.
`,
  },
  {
    id: 33,
    link: " https://changelly.com/static/partners/funfair-partner.svg",
    content: ` FunFair is delivering a world of guaranteed fair, decentralised online gaming, powered by its live scaling technology, Fate Channels. It continues to raise the benchmark of blockchain casino with the best, fastest and fairest games and immersive casino experiences for players, operators and affiliates.
`,
  },
  {
    id: 34,
    link: " https://changelly.com/static/partners/coinspace-partner.svg",
    content: ` Coin works with any ERC20 and ERC223 tokens and supports the most popular Crypto blockchains (Bitcoin, Litecoin, Dogecoin, Bitcoin Cash, EOS, Ripple, Stellar and Ethereum). Currently more than 20,000 tokens that are built on Crypto are accessible through Coin Wallet app.
`,
  },
  {
    id: 35,
    link: " https://changelly.com/static/partners/DAOBet.svg",
    content: ` A decentralized platform for iGaming and DApps. The major features include fast finality, compatibility with EOS smart contracts, decentralized governance (up to 100 validators), sponsorship transactions mechanics, Signidice verification (RSA signature verification).
`,
  },
  {
    id: 36,
    link: " https://changelly.com/static/partners/fintech.svg",
    content: ` Fintech Systems offer Financial Systems as a Service. Establish a Market Data, Analysis and Research Operation, Buy and Sell CryptoCurrency or Sell your Products and Services without payment intermediaries. We allow our site owners to create advanced web platforms through the use of visual builders and plug-able service modules.
`,
  },
  {
    id: 37,
    link: " https://changelly.com/static/partners/partner-tradesanta.svg",
    content: ` TradeSanta is a cloud trading software that lets anyone automate their trades on major exchanges like Binance, Bitfinex, Bittrex and HitBTC. It is the simplest way to trade 24/7 without actually sitting in front of the screen. You just choose a pair to trade, set the target profits, and TradeSanta bots will do the rest. Take advantage of market volatility with trading bots, sell or buy large sums of crypto using smart order placement bots, and more.
`,
  },
  {
    id: 38,
    link: " https://changelly.com/static/partners/partner-mycelium.svg",
    content: ` Mycelium is a highly integrated bitcoin company specializing in hardware, software and security. The Mycelium Wallet is a mobile wallet that allows you to store, send and trade bitcoin.
`,
  },
  {
    id: 39,
    link: " https://changelly.com/static/partners/partner-nexo.svg",
    content: ` Nexo provides the most advanced crypto backed lending platform, helping investors enjoy the immediate spending power of their assets instead of selling them, thus protecting the upside potential. Having launched a live product on April 30, 2018, Nexo has serviced thousands of clients with tens of millions of dollars in loans across 200+ jurisdictions and in over 40 fiat currencies and stablecoins.
`,
  },
  {
    id: 40,
    link: " https://changelly.com/static/partners/partner-atoken.svg",
    content: ` AToken wallet is the world’s first wallet to achieve decentralized transaction acceleration function with non-mineral pool accelerating. Supports BTC, ETH and all ERC20 tokens.It is extremeley secure, as the multi-encrypted private key stored in APP without any access to the server
`,
  },
  {
    id: 41,
    link: " https://changelly.com/static/partners/partner-unocoin.svg",
    content: ` Unocoin is a popular bitcoin payment platform used throughout India. The Bangalore-based company makes it easy for the average Indian user to pay with bitcoin – including sending payments to friends or paying merchants with bitcoin. It is a true leader on the Indian cryptomarket.
`,
  },
  {
    id: 42,
    link: " https://changelly.com/static/partners/partner-nem.svg",
    content: ` The NEM Foundation unites international network, IT experience, entrepreneurship, business processes, trading, property and asset development, international management and academia. NEM is focused on providing NEM’s blockchain technology to improve industries, organizations and education.
`,
  },
  {
    id: 43,
    link: " https://changelly.com/static/partners/partner-zelcash.svg",
    content: ` ZelCash is a cryptocurrency that holds a central role in the Zel Ecosystem. It gives the incentive for ZelNode ownership to enable the creation of ZelEx and the ZelDev Platform, enabling us to create a truly decentralized development platform.ZelCash is minable making distribution fair. ZelNodes are soon to be added, and will allow holders of ZelCash to operate a ZelNode, receiving a portion of blockreward for operating it.
`,
  },
  {
    id: 44,
    link: " https://changelly.com/static/partners/partner-ginco.svg",
    content: ` Ginco is a decentralized crypto wallet. Each of Ginco users has full control over their private keys which are securely stored inside their smartphones. Its simple and clean design makes it easy for anyone to use the app, including those looking to get into crypto for the first time.
`,
  },
  {
    id: 45,
    link: " https://changelly.com/static/partners/partner-buntoy.svg",
    content: ` BUNTOY is a mobile crypto wallet designed for blockchain games. It supports hundreds of blockchain games and over 500 cryptocurrencies worldwide. Committed to bringing the best wallet experience to users.
`,
  },
  {
    id: 46,
    link: " https://changelly.com/static/partners/partner-indacoin.svg",
    content: ` Indacoin is UK based company working in cryptocurrency field since 2013. Indacoin offers its users to purchase over 100 different cryptocurrencies with credit or debit card without registration and store it in Indacoin Wallet.
`,
  },
  {
    id: 47,
    link: " https://changelly.com/static/partners/partner-shiftex.svg",
    content: ` The idea behind the Shiftex is democratizing the instant cryptocurrency exchange services by providing possible alternatives to exchangers. Shiftex connects its users to directly access to partner exchanges' services. Shiftex is always free for exchangers and never charges exchangers commission.
`,
  },
  {
    id: 48,
    link: " https://changelly.com/static/partners/fuzew-partner.svg",
    content: ` FuzeW is a hardware cold wallet with the highest security level CC EAL 5+ certification. FuzeW supports various coins and tokens including all ERC20. With its card type feature, it makes easy for the users to carry in their pocket. By connecting to its mobile application ("wallet manager"), FuzeW provides safe protection for the user's crypto assets, wherever they go.FuzeW is the most advanced cold wallet, in terms of safety, convenience, and usability.
`,
  },
  {
    id: 49,
    link: " https://changelly.com/static/partners/partner-bankorus.svg",
    content: ` Bankorus is the world's first crypto wealth management platform provides HNWIs with a full suite of solutions for accessing crypto with traditional assets. It allows to borrow in crypto against any of your existing assets—from art to zero-coupon bonds, tokenize your assets and do many more fascinating things to make more profit
`,
  },
  {
    id: 50,
    link: " https://changelly.com/static/partners/partner-emberfund.svg",
    content: ` Ember uses cutting edge wallet technology powered by Airbitz/Edge - a paradigm shifting data security company. Edge, founded in 2014, is the most established non-custodial wallet system in the industry with an impeccable track record of securing cryptocurrency assets.
`,
  },
  {
    id: 51,
    link: " https://changelly.com/static/partners/flarewallet.svg",
    content: ` Flare is a highly-secure privacy focused cryptocurrency wallet for Bitcoin, Ethereum, Litecoin and many more. Manage your cryptocurrency assets in the most simplest and user-friendly way.
`,
  },
  {
    id: 52,
    link: " https://changelly.com/static/partners/partner-emoon.svg",
    content: ` Emoon provides a peer-to-peer marketplace for buyers and sellers to exchange assets for ether (ETH). It is powered by the 0x protocol. Emoon blockchain transactions use their audited, best-in-class smart contracts.
`,
  },
  {
    id: 53,
    link: " https://changelly.com/static/partners/partner-pint.svg",
    content: ` PINT - a highly intuitive wallet that gives you a simple start to virtual currency use. A single wallet application lets you receive, store, spend and swap virtual currency in a fiercely secure way.
`,
  },
  {
    id: 54,
    link: " https://changelly.com/static/partners/trustee-partner.svg",
    content: ` Trustee Wallet - a perfect wallet for buying and selling crypto from any Visa or MasterCard. Our pros - anonymity, the lowest commissions from 1,5% and the best rate on the market. Discover the process - swipe down!
`,
  },
  {
    id: 55,
    link: " https://changelly.com/static/partners/partner-bankex.svg",
    content: ` Bankex is a leading global financial technology company developing a tokenization ecosystem, providing technology and services at every stage. Bankex operates a Proof-of-Asset Protocol to bring Bank-as-a-Service (BaaS) and blockchain together and facilitate the digitization, tokenization &amp; exchange of traditional assets, ushering in a new era for finance and banking: Securitization 2.0.
`,
  },
  {
    id: 56,
    link: " https://changelly.com/static/partners/partner-citowise.svg",
    content: ` Citowise provides free and secure cryptocurrency wallet and payment solutions for businesses and private deals. Their flexible, well-integrated and secure mobile wallet is designed to be used in everyday life, providing convenient QR Code, NFC or URL payment options, powered by the most popular and trusted exchange services as well as preserving maximum security and keeps assets in the ​safe and reliable way.
`,
  },
  {
    id: 57,
    link: " https://changelly.com/static/partners/partner-simplex.png",
    content: ` Simplex is a project that helps its partners to protect online payments from any kind of fraud. Simplex processes transactions and check it via real-time machine-learning system. The technology aims to provide high level of security for merchants, marketplaces and payment platforms and increase their revenue in a fraud-free environment.
`,
  },
];

export default PartnerData;
