import React from 'react'
import "./Product.css"
import { AiOutlineArrowRight } from "react-icons/ai";
import Product1 from "../../assets/product/product1.png"
import Product2 from "../../assets/product/product2.png"
import Product3 from "../../assets/product/product3.png"
import Hybrid from "../../assets/product/Hybrid.png"
import Native from "../../assets/product/native.png"
import TrustWalletIcon from "../../assets/trust wallet3.png"
import MetaMaskIcon from "../../assets/metamask2.png"
import TronLinkIcon from "../../assets/tronlink2.png"
import { Link } from 'react-router-dom';

export default function Product() {
    return (
        <>
            <div className='container-fluid product sec2-web'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12'>
                        <p className='capitron-text'>Capitron Products</p>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12 '>
                        <p className='p-text'><span className=' product-heading text-white'>Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron offers users the option to swap their cryptocurrencies on the
                            same blockchain, which ensures fast and efficient transactions.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                    <div className='col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12 '>
                        <img style={{ height: "370px" }} src={Product1} className="img-fluid" />
                    </div>
                </div>
                <div className='row  d-flex justify-content-center '>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12 '>
                        <img style={{ height: "370px" }} src={Product2} className="img-fluid" />
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6'>
                        <p className='p-text'><span className=' product-heading text-white'>Cross chain Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's cross-chain swap feature, XSwap, allows users to swap cryptocurrencies across different blockchain networks, providing greater flexibility in trading.</span><br /><br />
                            <Link to="/docs/capitronProducts" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                </div>
                <div className='row   d-flex justify-content-center'>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 '>
                        <p className='p-text'><span className=' product-heading text-white'>Native Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's NSwap feature supports the swapping of native cryptocurrencies such as Bitcoin, Tron.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12'>
                        <img style={{ height: "370px" }} src={Native} className="img-fluid" />
                    </div>
                </div>
                <div className='row  d-flex justify-content-center '>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12 '>
                        <img style={{ height: "370px" }} src={Hybrid} className="img-fluid" />
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6'>
                        <p className='p-text'><span className=' product-heading text-white'>Hybrid Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's Hybrid Swap feature enables users to swap their cryptocurrencies using any crypto wallet, thereby providing greater convenience and ease of use.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12 '>
                        <p className='p-text'><span className=' product-heading text-white'>Cash</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's Cash Swap feature enables users to convert their cryptocurrencies into fiat cash, thereby
                            providing an easy exit for traders.</span><br /><br />
                            <Link to="/docs/capitronProducts" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                    <div className='col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12 '>
                        <img style={{ height: "370px" }} src={Product3} className="img-fluid" />
                    </div>
                </div>

                <div className='d-flex flex-column justify-content-center py-4'>
                    <div className='d-flex justify-content-center  col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 '>
                        <p className='p-text'><span className=' product-heading text-white sec3-_text3'>Partners and Stake Holders</span>

                        </p>
                    </div>
                    <div className='d-flex flex-column align-items-center justify-content-center flex-md-row py-4 px-4 gap-4'>
                       
                            <img  src={TrustWalletIcon} alt="trustWallet" />
                       
                       
                            <img   src={MetaMaskIcon} alt="metamask" />
                       
                       
                            <img src={TronLinkIcon} alt="tronlink" />
                      
                        {/* <img style={{ height: "370px" }} src={Product3} className="img-fluid" /> */}
                    </div>
                </div>

            </div>
            <div className='container-fluid product mobile-sec'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12'>
                        <p className='capitron-text'>Capitron Product</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                        <img style={{ height: "370px" }} src={Product1} className="img-fluid" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center '>
                        <p className='p-text'><span className=' product-heading text-white'>Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron offers users the option to swap their cryptocurrencies on the
                            same blockchain, which ensures fast and efficient transactions.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center'>
                        <img style={{ height: "370px" }} src={Product2} className="img-fluid" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center '>
                        <p className='p-text'><span className=' product-heading text-white'>Cross chain Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's cross-chain swap feature, XSwap, allows users to swap cryptocurrencies across different blockchain networks, providing greater flexibility in trading.</span><br /><br />
                            <Link to="/docs/capitronProducts" className='mt-3 learn-button'>Learn More  <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center'>
                        <img style={{ height: "370px" }} src={Native} className="img-fluid" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center '>
                        <p className='p-text'><span className=' product-heading text-white'>Native Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's NSwap feature supports the swapping of native cryptocurrencies such as Bitcoin, Tron.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center'>
                        <img style={{ height: "370px" }} src={Hybrid} className="img-fluid" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center '>
                        <p className='p-text'><span className=' product-heading text-white'>Hybrid Swap</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's Hybrid Swap feature enables users to swap their cryptocurrencies using any crypto wallet, thereby providing greater convenience and ease of use.</span><br /><br />
                            <Link to="https://capitron-exchange.gitbook.io/capitron/products/capitron-products" className='mt-3 learn-button'>Learn More  <AiOutlineArrowRight /></Link>
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                        <img style={{ height: "370px" }} src={Product3} className="img-fluid" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center '>
                        <p className='p-text'><span className=' product-heading text-white'>Cash</span><br /><span className='product-text text-white-50  mt-5'>
                            Capitron's Cash Swap feature enables users to convert their cryptocurrencies into fiat cash without any registration, thereby providing an easy exit for traders.</span><br /><br />
                            <Link to="/docs/capitronProducts" className='mt-3 learn-button'>Learn More <AiOutlineArrowRight /></Link>
                        </p>
                    </div>

                </div>
                <div className='d-flex flex-column justify-content-center py-4'>
                    <div className='d-flex justify-content-center col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 '>
                        <p className='p-text'><span className=' product-heading text-white sec3-_text3'>Partners and Stake Holders</span>

                        </p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center flex-column flex-md-row py-4 px-4 gap-4'>
                       
                            <img src={TrustWalletIcon} alt="trustWallet" />
                       
                       
                            <img src={MetaMaskIcon} alt="metamask" />
                       
                       
                            <img src={TronLinkIcon} alt="tronlink" />
                      
                        {/* <img style={{ height: "370px" }} src={Product3} className="img-fluid" /> */}
                    </div>
                </div>




            </div>
        </>
    )
}
