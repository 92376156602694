import React, { useState , useEffect, useRef } from 'react'
import { useWeb3Wallet } from '../utils/web3wallet.context'
import { useLocation, useParams } from 'react-router-dom';
import Web3 from 'web3';
import "../swap/swap.css"
import CashTron from './cash_tron';
import XSwapTron from './xswap_tron';
import BtcTransfer from './BtcTransfer';

export default function Tron({  Networks }) {
    const {  account , provider} = useWeb3Wallet();
    const web3 = new Web3(provider);
    const [componentRender, setComponentRender] = useState(1);
   

    const nswapref = useRef()
    const ncashref = useRef()

    const location = useLocation()
  

    useEffect(()=>{
        if(location.state?.tab == 'nswap'){
            nswapref.current.click()
        } else if(location.state?.tab == 'ncash'){
            ncashref.current.click()
        }
    }, [])
   

    return (
        <>
            <div className='container-fluid pt-4 d-flex justify-content-center' style={{minHeight :"100vh"}}>

                {/* <div className='col-sm-12 col-md-8 col-lg-5 swap-form-container'>
                    <ul style={{ border: 'none' }} className="nav nav-tabs" id="myTab" role="tablist">
                    <li style={{ zIndex: 3 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(0)} className="nav-link px-4 active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">XSwap</button>
                        </li>
                        <li style={{ zIndex: 2 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(1)} className="nav-link px-4" id="swap-tab" data-bs-toggle="tab" data-bs-target="#swap" type="button" role="tab" aria-controls="swap" aria-selected="true">Cash</button>
                        </li>
                        <li style={{ zIndex: 1 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(2)} className="nav-link px-4" id="swap-tab" data-bs-toggle="tab" data-bs-target="#swap" type="button" role="tab" aria-controls="swap" aria-selected="true">BTC</button>
                        </li>
                      
                     
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {
                                componentRender === 0 && <XSwapTron  />
                            }
                        </div>
                        <div className="tab-pane fade " id="swap" role="tabpanel" aria-labelledby="swap-tab">
                            {
                                componentRender === 1 && <CashTron />
                            }
                        </div>
                        <div className="tab-pane fade " id="swap" role="tabpanel" aria-labelledby="swap-tab">
                            {
                                componentRender === 2 && <BtcTransfer />
                            }
                        </div>
                       
                    </div>
                </div> */}
                <div className='col-sm-12 col-md-8 col-lg-5 swap-form-container'>
                    <ul style={{ border: 'none' }} className="nav nav-tabs" id="myTab" role="tablist">
                        {/* <li style={{ zIndex: 3 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(0)} className="nav-link px-4 active" id="swap-tab" data-bs-toggle="tab" data-bs-target="#swap" type="button" role="tab" aria-controls="swap" aria-selected="true">BTC</button>
                        </li> */}
                        <li style={{ zIndex: 2 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(1)} ref={nswapref} className="nav-link px-4 active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">NCash</button>
                        </li>
                        <li style={{ zIndex: 1 }} className="nav-item" role="presentation">
                            <button onClick={() => setComponentRender(2)} ref={ncashref} className="nav-link px-4" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">NXSwap</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        {/* <div className="tab-pane fade show active" id="swap" role="tabpanel" aria-labelledby="swap-tab">
                            {
                                componentRender === 0 &&   <BtcTransfer  />
                            }
                        </div> */}
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {
                                componentRender === 1 && <CashTron Networks = {Networks} />
                            }
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            {
                                componentRender === 2 &&  <XSwapTron Networks = {Networks} />
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
