import React, { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { CopyToClipboard } from "react-copy-to-clipboard";
import MetmaskQr from "../assets/Qr code/Metmask.jpeg"
import { walletAddressMetamask } from "../utils/commonConstant"
import web3 from "web3"
import axios from 'axios'
import { ethers } from 'ethers'
import API_URL from '../Api/apiUrl'
import { useWeb3Wallet } from '../utils/web3wallet.context'
import "../Tron Integration/Tron.css"
import SwapArrow from '../assets/Swap-arrow.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PaymentStatus from "../assets/payment-processing.gif"


export default function XSwapForm({ chainName, chainLists, coinBalance }) {


    const [amount, setAmount] = useState();
    const [tokenAddr, setTokenAddr] = useState("0x8ab7ef0eb25aad36dff0661f81fb81b144af5b87");
    const [abi, setAbi] = useState()
    const [amount1, setAmount1] = useState()
    const [lists, setLists] = useState([])
    const [lowerLists, setLowerLists] = useState([]);
    const [sentWalletAddress, setSentWalletAddress] = useState()
    const [coinName_xswap, setCoinName_xswap] = useState()
    const [coinImage_xswap, setCoinImage_xswap] = useState()
    const [coinName1_xswap, setCoinName1_xswap] = useState()
    const [coinImage1_xswap, setCoinImage1_xswap] = useState()
    const [amountInDollar, setAmountInDollar] = useState()
    const [amountInDollar2, setAmountInDollar2] = useState()
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState([]);
    const [search, setSearch] = useState();
    const [chain_xswap, setChain_xswap] = useState("BNB")
    const [chain_xswapImage, setChain_xswapImage] = useState("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")
    const [chain_xswaplower, setChain_xswaplower] = useState("Fantom")
    const [chain_xswapImagelower, setChain_xswapImagelower] = useState("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")
    const [filteredTokenList, setFilteredTokenList] = useState();
    const [setFilteredLowerList, setSetFilteredLowerList] = useState([]);
    const [chainLists_cash, setChainLists_cash] = useState([]);
    const [count_xswap, setCount_xswap] = useState()

    const [isCopied, setIsCopied] = useState(false);

    // time
    const [time, setTime] = useState("")
    const [time1, setTime1] = useState("")

    // Modal

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show4, setShow4] = useState(false);
    const [afterPaymentModal, setAfterPaymentModal] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const chainShow = () => {
        if (chain_xswap == "Ethereum") {
            setCoinName_xswap("ETH")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png")

        } else if (chain_xswap == "BNB") {
            setCoinName_xswap("BNB")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png")


        } else if (chain_xswap == "Polygon") {
            setCoinName_xswap("MATIC")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png")

        } else if (chain_xswap == "Arbitrum") {
            setCoinName_xswap("ARB")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png")

        } else if (chain_xswap == "Fantom") {


            setCoinName_xswap("FTM")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png")

        } else if (chain_xswap == "Avalanche") {


            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png")
            setCoinName_xswap("AVAX")


        } else if (chain_xswap == "Optimism") {
            setCoinName_xswap("OP")
            setCoinImage_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png")

        }
    }

    const chainShowlower = () => {
        if (chain_xswaplower == "Ethereum") {
            setCoinName1_xswap("GLDX")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/11318.png")
        } else if (chain_xswaplower == "BNB") {
            setCoinName1_xswap("SHENGWEITU")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/23847.png")
        } else if (chain_xswaplower == "Polygon") {
            setCoinName1_xswap("CROWD")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/22117.png")
        } else if (chain_xswaplower == "Arbitrum") {
            setCoinName1_xswap("CKING")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/23944.png")
        } else if (chain_xswaplower == "Optimism") {
            setCoinName1_xswap("OPXVEVELO")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/22801.png")
        } else if (chain_xswaplower == "Avalanche") {
            setCoinName1_xswap("YYAVAX")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/21775.png")
        } else if (chain_xswaplower == "Fantom") {
            setCoinName1_xswap("MONEYRAIN")
            setCoinImage1_xswap("https://s2.coinmarketcap.com/static/img/coins/64x64/17612.png")
        }
    }


    // copied message

    const onCopyText = () => {
        debugger
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 10000);
    };

   


    // getTimestamp 
    const callTime = async () => {

        const Web3 = require('web3');

        const url = process.env.REACT_APP_QUICK_NODE;
        const provider = new Web3.providers.HttpProvider(url);
        const web3 = new Web3(provider);
        web3.eth.getBlock('latest', (err, block) => {
            if (err) {
                console.error(err);
            } else {
                // console.log(`Current epoch timestamp: ${block.timestamp}`);
                sendTransaction(block.timestamp);
            }
        });


    }

    // After Payment Modal



    const afterPay = () => {
        setTimeout(() => {
            setShow(false)
            setAfterPaymentModal(true)
        }, 200000)
    }


    // sendTransaction details 


    const WAIT_TIME = 5000
    const sendTransaction = (timeParameter) => {
        setLoading(true)
        const id = setInterval(() => {
            axios.post(API_URL.saveAllChainQRtransaction, {

                walletAddress: sentWalletAddress,
                time: timeParameter,
                amount: amount,
                chain: chain_xswap
            })
                .then((res) => {


                    if (res.data.data === "transactionSuccess") {
                        clearInterval(id);
                        NotificationManager.success("Payment Successful !")
                        setShow(false)
                        setShow4(true)
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    clearInterval(id);
                    setLoading(false)
                })
        }, WAIT_TIME)
    }

    const tokenNumber = () => {
        axios.get(`${API_URL.getTokenCount}?chainName=${chain_xswap}`)
            .then((res) => {

                allTokens(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const tokenNumberLower = () => {
        axios.get(`${API_URL.getTokenCount}?chainName=${chain_xswaplower}`)
            .then((res) => {

                allTokensLower(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const allTokens = (count) => {
        axios.get(`${API_URL.getTokenList}?chainName=${chain_xswap}&no_of_docs_each_page=${100}&current_page_number=${0}`)
            .then((res) => {

                setLists(res.data.data)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    const allTokensLower = (count) => {
        axios.get(`${API_URL.getTokenList}?chainName=${chain_xswaplower}&no_of_docs_each_page=${100}&current_page_number=${0}`)
            .then((res) => {

                setLowerLists(res.data.data)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    const allchainList_cash = () => {
        axios.get(API_URL.getchainList)
            .then((res) => {

                setChainLists_cash(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        allchainList_cash()
    }, [])
    useEffect(() => {
        onCopyText()
    }, [])
   
    const exchangeRate = () => {

        axios.get(`${API_URL.exchange}?amount=${amount}&convert=${coinName1_xswap}&symbol=${coinName_xswap}`)
            .then((res) => {

                setAmount1(res.data.data?.convert?.amount)
                setAmountInDollar(res.data.data?.symbol?.amountInDollar)
                setAmountInDollar2(res.data.data?.convert?.amountInDollar);
            })
            .catch((err) => {
                console.log(err)
                setAmount1(0)
                setAmountInDollar(0)
                setAmountInDollar2(0);
            })
    }

    useEffect(() => {
        exchangeRate()
    }, [coinName_xswap, coinName1_xswap, amount])





    useEffect(() => {
        tokenNumber()
    }, [chain_xswap])

    useEffect(() => {
        tokenNumberLower()
    }, [chain_xswaplower])


    useEffect(() => {
        chainShow()

    }, [chain_xswap])


    useEffect(() => {

        chainShowlower()
    }, [chain_xswaplower])



    const handleChange = (e) => {
        setSearch(e.target.value)



        axios.get(`${API_URL.searchTokenByChain}?chainName=${chain_xswap}&token=${e.target.value}`)
            .then((res) => {

                setFilteredTokenList(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const handleChangeLower = (e) => {
        setSearch(e.target.value)



        axios.get(`${API_URL.searchTokenByChain}?chainName=${chain_xswaplower}&token=${e.target.value}`)
            .then((res) => {

                setSetFilteredLowerList(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        setFilteredTokenList(lists)
    }, [lists])

    useEffect(() => {
        setSetFilteredLowerList(lowerLists)
    }, [lowerLists])




    return (
        <>
            <div className="swap-tab-container p-3 d-flex flex-column">

                <div className="input-container p-4 d-flex flex-column gap-3 active">



                    <div className="d-flex justify-content-between flex-wrap ">

                        <div className='d-flex flex-column w-25'>
                            <div className="dropdown d-flex align-items-center ">
                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={chain_xswapImage} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {(chain_xswap == "BNB") ? "BSC" : chain_xswap}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%" }}>
                                        {chainLists_cash?.map((lists, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    setChain_xswap(lists.name)
                                                    setChain_xswapImage(lists.imageURL)

                                                }} className='d-flex mt-2' >
                                                    <div>
                                                        <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "25px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                                    </div>
                                                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                                        <p>{(lists.name == "BNB") ? "BSC" : lists.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </div>

                            <input className="input-field mt-2 " type="text" placeholder="00"
                                onChange={(e) => setAmount(e.target.value)} />
                        </div>

                        <div className="dropdown d-flex align-items-center">
                            <img src={coinImage_xswap} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{coinName_xswap}</span>
                            <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop_xswap">&#xf107;</i>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${amountInDollar}</p>
                        {/* <p className="input-balance p-0 m-0">Balance: {balance ? balance : "0"}</p> */}
                    </div>
                </div>

                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                </div>

                <div className="input-container p-4 d-flex flex-column gap-3">

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <div className='d-flex flex-column w-25'>
                            <div className="dropdown d-flex align-items-center">
                                <div className="dropdown">
                                    <button className="network-button_cash  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: "50px" }}>
                                        <img src={chain_xswapImagelower} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                                        {(chain_xswaplower == "BNB") ? "BSC" : chain_xswaplower}
                                    </button>
                                    <ul className="dropdown-menu" style={{ background: "#E0F1FF", borderRadius: "10px", width: "100%" }}>
                                        {chainLists_cash?.map((lists, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    setChain_xswaplower(lists.name)
                                                    setChain_xswapImagelower(lists.imageURL)

                                                }} className='d-flex mt-2' >
                                                    <div>
                                                        <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "25px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                                    </div>
                                                    <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                                        <p>{(lists.name == "BNB") ? "BSC" : lists.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </div>
                            <input onChange={() => {
                                setAmount1(amount1);
                            }} value={amount1} disabled={true} className="input-field" type="text" placeholder="00" />
                            {/* <input className="input-field mt-2" type="text" placeholder="1527.768099" /> */}
                        </div>

                        <div className="dropdown d-flex align-items-center">
                            <img src={coinImage1_xswap} alt="ETH-Logo" style={{ height: "20px", borderRadius: "50%" }} />
                            <span className="input-select" name="currency-one" id="currency-one" style={{ marginLeft: "5px" }}>{coinName1_xswap}</span>
                            <i style={{ fontSize: "24px", cursor: "pointer" }} className="fa" data-bs-toggle="modal" data-bs-target="#staticBackdrop1_xswap">&#xf107;</i>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <p className="input-para p-0 m-0">~${amountInDollar2}</p>
                        {/* <p className="input-balance p-0 m-0">Balance: 0</p> */}
                    </div>
                </div>

                <p className='token-value ps-4 py-2 mt-4'>
                    {amount}&nbsp;{coinName_xswap} = {amount1}&nbsp;{coinName1_xswap}
                    <span>

                    </span>
                </p>

                <div className='w-100 swap-button-container m-auto'>
                    {(amount == null || amount === "") ?

                        <button className='px-5 py-1 w-100' >
                            Enter Amount

                        </button>

                        :

                        <button className='px-5 py-1 w-100' data-bs-toggle="modal" data-bs-target="#exampleModal_xswap">
                            SWAP

                        </button>
                    }


                </div>
            </div>

            {/* <!-- Modal --> */}

            <div className="modal fade" id="staticBackdrop_xswap" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black", cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" className="mb-5 " placeholder='Search Token' onChange={handleChange} />
                            </div>
                            {filteredTokenList?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        setCoinName_xswap(lists.symbol);
                                        setCoinImage_xswap(lists.imageURL);
                                    }} className='d-flex mt-4' data-bs-dismiss="modal">
                                        <div >
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "40px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal currency 2 */}
            <div className="modal fade" id="staticBackdrop1_xswap" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content tokens">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Select Token</h5>
                            <i className="fa fa-close " data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px", color: "black", cursor: "pointer" }}></i>
                        </div>
                        <div className="modal-body">
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input type="text" className="mb-5 " placeholder='Search Token'
                                    onChange={handleChangeLower} />
                            </div>
                            {setFilteredLowerList?.map((lists, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        setCoinName1_xswap(lists.symbol);
                                        setCoinImage1_xswap(lists.imageURL)
                                    }} className='d-flex mt-4' data-bs-dismiss="modal" >
                                        <div>
                                            <span><img src={lists.imageURL} alt="tokenImg" style={{ height: "30px", borderRadius: "50%", cursor: "pointer" }} /></span>
                                        </div>
                                        <div style={{ marginLeft: "5px", cursor: "pointer" }}>
                                            {lists.symbol}<br />{lists.name}
                                        </div>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>



            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal_xswap" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Swap</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="swap-tab-container p-3 d-flex flex-column">

                                <div className="input-container p-4 d-flex flex-column gap-3 active">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {amount}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={coinImage_xswap} style={{ height: "20px", borderRadius: "50%" }} />
                                            {coinName_xswap}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                                    <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                                </div>

                                <div className="input-container p-4 d-flex flex-column gap-3">

                                    <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                        {amount1}
                                        <div className="dropdown d-flex align-items-center w-25">
                                            <img src={coinImage1_xswap} style={{ height: "20px", borderRadius: "50%" }} />
                                            {coinName1_xswap}
                                        </div>
                                    </div>
                                </div>

                                <p className='token-value ps-4 py-2 mt-4'>
                                    {amount}&nbsp;{coinName_xswap} = {amount1}&nbsp;{coinName1_xswap}
                                    <span>
                                        {/* (~$1 418.77 ) */}
                                    </span>
                                </p>
                                <p className='token-value ps-1 py-1 mt-4'>
                                    <p>Enter Your {coinName1_xswap} wallet Address</p>
                                </p>

                                <div className='d-flex justify-content-between align-items-center swap-button-container m-auto w-100 '>

                                    <input type="text" className='wallet-address' value={sentWalletAddress} onChange={(e) => setSentWalletAddress(e.target.value)} placeholder='Enter Your Wallet Address' />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {sentWalletAddress ?
                                <button type="button" className="btn btn-primary confirm-swap w-100" data-bs-dismiss="modal" onClick={() => { setShow1(true) }}  >CONFIRM</button>
                                :
                                <button type="button" className="btn btn-primary confirm-swap w-100">Enter Address</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirm Modal */}

            <Modal show={show1} onHide={handleClose1} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scan & Pay</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="swap-tab-container p-3 d-flex flex-column">

                        <div className="input-container p-4 d-flex flex-column gap-3 active">

                            <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                {amount}
                                <div className="dropdown d-flex align-items-center w-25">
                                    <img src={coinImage_xswap} style={{ height: "20px", borderRadius: "50%" }} />
                                    {coinName_xswap}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mx-auto align-items-center my-2">
                            <img role='button' className='position-absolute' src={SwapArrow} alt="SwapArrow" />
                        </div>

                        <div className="input-container p-4 d-flex flex-column gap-3">

                            <div className="d-flex justify-content-between flex-wrap align-items-center w-100">
                                {amount1}
                                <div className="dropdown d-flex align-items-center w-25">
                                    <img src={coinImage1_xswap} style={{ height: "20px", borderRadius: "50%" }} />
                                    {coinName1_xswap}
                                </div>
                            </div>
                        </div>

                        <p className='token-value ps-4 py-2 mt-4'>
                            {amount}&nbsp;{coinName_xswap} = {amount1}&nbsp;{coinName1_xswap}
                            <span>
                                {/* (~$1 418.77 ) */}
                            </span>
                        </p>
                        <p className='token-value ps-4 py-2 mt-4'>
                            <p>Transferring {amount1}&nbsp; {coinName1_xswap} to wallet address  {sentWalletAddress} </p>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => { setShow(true); setShow1(false); callTime(); afterPay() }}>
                            CONFIRM

                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
            {/* Qr code modal */}


            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scan & Pay</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-8 '>
                            <img src={MetmaskQr} style={{ height: "250px" }} className='img-fluid' />
                        </div>
                        <div className='d-flex justify-content-center mt-2'>
                            <p className="add-wallet">{walletAddressMetamask} </p>
                            {/* {
                                isCopied ? <i className="fa fa-copy" style={{ fontSize: "22px", cursor: "pointer" }} title={isCopied ? "copied" : "copy address"}></i> :
                                    <CopyToClipboard text={walletAddressMetamask} onCopy={() => setIsCopied(true)}>
                                        <div className="copy-area pl-2">
                                            <i className="fa fa-copy" style={{ fontSize: "22px" ,cursor:"pointer"}} title={isCopied ? "copied" : "copy address"}></i>
                                        </div>
                                    </CopyToClipboard>
                            } */}


                            <CopyToClipboard text={walletAddressMetamask} onCopy={onCopyText}>
                                <div className="copy-area pl-2">
                                    <i className="fa fa-copy" style={{ fontSize: "22px", cursor: "pointer" }} title={isCopied == true ? "copied" : "copy address"}></i>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100'>
                            {loading ? <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ fontSize: 16 }}
                            /> : " CLOSE"}

                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
            {/* After Payment Success */}


            <Modal
                show={show4}
                onHide={() => setShow4(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>

                        <img src={PaymentStatus} />

                    </div>
                    <p className='token-value ps-4 py-2 mt-4'>
                        <p>Transferring {amount1}&nbsp; {coinName1_xswap} to wallet address  {sentWalletAddress} </p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => { setShow4(false); setSentWalletAddress("") }}>

                            CLOSE


                        </button>

                    </div>

                </Modal.Footer>
            </Modal>


            {/* After QR payment user need to click on confirm payment */}
            <Modal show={afterPaymentModal} onHide={() => setAfterPaymentModal(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <p className='token-value ps-4 py-2 mt-4 text-center'>
                        <p> Click on confirm if you have sent &nbsp; {amount} &nbsp; {coinName_xswap}</p>
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 swap-button-container m-auto'>
                        <button className='px-5 py-1 w-100' onClick={() => { setShow4(true); setAfterPaymentModal(false) }}>

                            CONFIRM


                        </button>

                    </div>

                </Modal.Footer>
            </Modal>
            <NotificationContainer />
        </>
    )
}
